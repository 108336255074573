import React from "react";
import { withStyles, Slider } from "@material-ui/core";
import { colors } from "config/uiKit";

const SliderStyled = withStyles({
  root: {
    color: colors.greenScale.light,
    height: 2,
    padding: '13px 0',
  },
  thumb: {
    fontSize: 11,
    color: '#000',
    fontWeight: 600,
    height: 30,
    width: 30,
    backgroundColor: '#fff',
    border: `1px solid ${colors.blueScale.slider}`,
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#00000CC 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#0000033 0 2px 3px 1px',
    },
    '& .bar': {
      height: 2,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 2,
  },
  rail: {
    color: colors.blueScale.slider,
    opacity: 1,
    height: 2,
  },
})(Slider);

export default ({minValue, maxValue, prefix = '', postFix = '', ...extraProps}) => {

  const getThumb = (props) => {
    let num = props['aria-valuenow'];
    if (num >= 1000 ){
      num = Math.floor(num/1000);
    }
    return (
      <span {...props}>
        { `${prefix}${num}${postFix}` }
      </span>
    )
  }

  return(
    <SliderStyled
      ThumbComponent={getThumb}
      min={minValue}
      max={maxValue}
      defaultValue={[minValue, maxValue]}
      {...extraProps}
    />
  );
}