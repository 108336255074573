import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles, withWidth, Grid, FormControlLabel } from '@material-ui/core';

import { getDispatchAction } from 'store';

import { IAppState, EContainerNames, ELoaderTypes } from 'interfaces';
import { colors } from 'config/uiKit';
import { Page, DeleteUser, SmallHeader, EditUser } from 'components';
import { accountSettingsStyle, UserStyled, RadioCustomStyle, PageContent } from './styles';

import { DeleteButton, Loader, Modal } from 'shared/components';
import { EUserRole } from 'shared/auth';
import { EBasicActionTypes } from 'shared/interfaces';

class ManageTeam extends Component<any, any> {

  changeInfo = id => (e: any, v = undefined) => {
    this.props.updateUser({ user_role: v, id })
  }

  render() {
    const { loading, uiConfig, user } = this.props;
    return (
      <Modal
        render={({ handleClose, handleOpen }) =>
          <Page headerProps={uiConfig(this.props, handleOpen, handleClose).headerProps} height='100%'>
            {!!loading && <Loader contained />}
            <PageContent>
              <SmallHeader color={colors.blueScale.dark}>
                <div className="header">
                  <div className="title">Manage Team</div>

                </div>
              </SmallHeader>
              <div className='mainContainer'>
                {this.props.users.map(u => <UserCard
                  key={u.id}
                  changeInfo={this.changeInfo}
                  handleOpen={handleOpen}
                  handleClose={handleClose}
                  disableDelete={user.id === u.id}
                  openDeleteModal={handleOpen(<DeleteUser user={u} closeAction={handleClose} />)}
                  openEditModal={handleOpen(<EditUser user={u} closeAction={handleClose}/>)}
                  {...u}
                />)}
              </div>
            </PageContent>
          </Page>
        }
      />
    )
  }
}

const UserCard = ({ id, username, department, profile_picture_url,
  user_role, changeInfo, openDeleteModal, disableDelete, openEditModal
}) => {

  const changEUserRole = value => () => changeInfo(id)(null, value);
  const radios: any = [
    { value: EUserRole.ADMIN, label: "Full Admin User", xs: 2 },
    { value: EUserRole.EDIT_ONLY, label: "Edit Only", xs: true },
    { value: EUserRole.VIEW_ONLY, label: "View Only", xs: true },
  ];
  return (
    <UserStyled image={profile_picture_url}>
      <Grid container>
        <Grid item className="item clickableField" xs={3} onClick={openEditModal}>
          <div className="image" />
          <div className="name">{username}</div>
        </Grid>
        <Grid item className="item" xs={3}>
          <div className="vertical" />
          <div className="wide">{department || 'Department'}</div>
        </Grid>
        {radios.map(r => (
          <Grid key={r.value} item className="item" xs={r.xs}>
            <div className="vertical" />
            <FormControlLabel
              className="wide"
              labelPlacement="start"
              value={r.value}
              control={<RadioCustom checked={user_role} />}
              label={r.label}
              style={{ opacity: user_role === r.value ? 1: .4} }
              onClick={changEUserRole(r.value)}
            />
          </Grid>
        ))}
        <Grid item className='item' xs="auto">
          <div className="vertical" />
          <div className="wide">
            <DeleteButton
              disabled={disableDelete}
              onClick={disableDelete ? () => { } : openDeleteModal}
              style={{ margin: '0 28px' }}
            />
          </div>
        </Grid>
      </Grid>
    </UserStyled>
  )
}

const RadioCustom = ({ value = '', checked }) => {
  const selected = checked === value;
  return (
    <RadioCustomStyle selected={selected}>
      <div className="innerCircle" />
    </RadioCustomStyle>
  )
}

const cmpWidthProp = withWidth()(ManageTeam);
const styledComponent = withStyles(accountSettingsStyle)(cmpWidthProp);

const mstp = (state: IAppState) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_PERSONA],
  users: state[EContainerNames.USERS].list
})

const mdtp = (dispatch: Function) => ({
  updateUser: getDispatchAction({ dispatch, container: EContainerNames.USERS, actionType: EBasicActionTypes.UPDATE })
})

export default connect(mstp, mdtp)(styledComponent);

