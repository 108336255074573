import React, { Component, ReactElement } from 'react';
import { withWidth, withStyles, GridList as MaterialGridList, GridListTile } from '@material-ui/core';

import { gridStyles } from './style';

class GridList extends Component<any> {

  getCols = (size: string): number => {
    const cols: any = { xs: 1, sm: 1, md: 2, lg: 4, xl: 5 };
    return cols[size] || 1;
  }

  render(): ReactElement {
    const { gridItemList, classes, width/*, scrollable*/ } = this.props;
    const cols = this.getCols(width);
    return (
      <div className={classes.root} style={{
        overflowY: 'hidden', //scrollable ? 'scroll' : 'hidden',
        height: cols >= gridItemList.length ? '50%' : ''
      }}>
        <MaterialGridList
          cellHeight={'auto'}
          className={classes.gridList}
          cols={cols}
        >
          {gridItemList.map((Item: any, idx: number) => (
            <GridListTile
              classes={{ root: classes.gridTileRoot, tile: classes.gridTile }} key={idx}>
              <Item />
            </GridListTile>
          ))}
        </MaterialGridList>
      </div>
    );
  }
};

const cmpWidthProp = withWidth()(GridList);
const styledComponent = withStyles(gridStyles)(cmpWidthProp);

export default styledComponent;