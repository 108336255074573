
import { Action } from 'redux'
import { ofType, ActionsObservable, StateObservable, Epic } from 'redux-observable';
import { of } from 'rxjs'
import { mergeMap, catchError } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax';
import { push } from 'connected-react-router'

import { url as baseUrl } from 'config/env';
import { IEpicBuilder, IAction, IRequest, IEpicConfig } from './interfaces';
import { stopLoading } from 'shared/loader';
import { EErrorActionTypes } from 'shared/error';
import { createRequestConfig, actionCreator, createEpicActionsConfig } from './'




export const createEpicConfig = ({
  type,
  containerName,
  getterName,
  setterName,
  api,
  options,
  loader
}): IEpicConfig => {

  let url = options.alternateAPI ? baseUrl + options.alternateAPI : api[containerName]
  return ({
    epic: {
      requestCreator: createRequestConfig({ type, url, ...options }),
      redirect: options.redirect,
    },
    actions: createEpicActionsConfig({
      getterName,
      setterName,
      startActions: options.startActions,
      loader
    })
  })
}


export const createEpic = ({
  actions,
  redirect = () => null,
  requestCreator,
  errorHandle = EErrorActionTypes.SHOW_ERROR,
}: IEpicBuilder): Epic => {
  return (action$: any, state$: StateObservable<any>): ActionsObservable<Action> =>
    action$.pipe(
      ofType<IAction>(...actions.start),
      mergeMap((act: IAction) =>
        ajax(requestCreator(act) as IRequest)
          .pipe(
            mergeMap((result: any) => {
              let newUrl = redirect(result.response);
              if (newUrl) {
                return [actionCreator(actions.recieve, actions.loader)(result.response), push(newUrl)]
              } else {
                return of(actionCreator(actions.recieve, actions.loader)(result.response))
              }

            }),
            catchError((err) => [actionCreator(errorHandle)(err), stopLoading(actions.loader)])
          )

      )
    )
}

export const createMultipleEpics =
  (epicConfig: any) =>
    epicConfig.map((config: any) => {
      return createEpic({
        redirect: config.epic.redirect,
        actions: config.actions,
        requestCreator: config.epic.requestCreator,
        errorHandle: config.epic.errorHandle
      })
    })

