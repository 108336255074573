import React from 'react';
import { Switch } from 'react-router-dom';
import SubRoutes from './SubRoutes';
import { IProps, IRoute } from 'interfaces';

const Router: React.FC<IProps> = ({ routes }) => (
  <Switch>
    {routes && routes.map((route: IRoute) => <SubRoutes key={route.path} {...route} />)}
  </Switch>
);

export default Router;