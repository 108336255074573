import React, { useState } from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { Logo } from 'components'
import { ERoutesName } from 'interfaces';
import { MenuContainer, SideMenuStyled, OpenMenuStyled } from './styles';
import MenuFilters from './filters';
import BrandsMenu from './brandsMenu';
import AccountMenu from './accountMenu';

const SideMenu = ({ filters, fullMenu, isDash, color,
  setAddingToFolder, selectedFolder, history,
  setActiveFilters, clearAllFilters, clearFolders, selectedFilters, logout, user
  
}) => {
  const [selectedItem, selectItem] = useState(null);

  const submitFilters = (values={}) => {
    let filters = {
      'brand_id': selectedItem.id,
      ...values
    }

    if (selectedFolder) {
      setAddingToFolder({ active: true });
    }
    selectItem(null)
    setActiveFilters({ filters })
  }

  const resetBoards = () => {
    clearAllFilters();
    clearFolders()
    history.push(ERoutesName.HOME)
  }

  const selectBrandName = (selectedBrand) => {
    const filters = {
      'brand_id': selectedBrand.id,
    }
    setActiveFilters({ filters })
  }

  return (
    <MenuContainer fullMenu={fullMenu}>
      <SideMenuStyled fullMenu={fullMenu} color={color || null} >
        <Logo width='170px' onClick={resetBoards} />
        {fullMenu && !isDash && <BrandsMenu
          filters={filters}
          clearAllFilters={clearAllFilters}
          selectedFilters={selectedFilters}
          selectedItem={selectedItem}
          selectItem={selectItem}
          selectBrandOnly={selectBrandName}
        />}
        {fullMenu && isDash && 
          <AccountMenu 
            user={user}
            logout={logout}
            history={history}
        />}
      </SideMenuStyled>
      {fullMenu && !isDash && <OpenMenuStyled>
        <div style={{ overflowY: 'scroll' }} id="menu-transition" className={selectedItem ? "show" : "hide"}>
          <div className="closeButton" onClick={() => selectItem(null)}>
            <CloseIcon />
          </div>
          {selectedItem &&
            <MenuFilters
              submitFilters={submitFilters}
              selectedOptions={selectedFilters[selectedItem.id] || {}}
              {...selectedItem}
            />}
        </div>
      </OpenMenuStyled>}
    </MenuContainer>
  )
}

export default SideMenu;