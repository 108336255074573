


import { EUserActionTypes, IUser, IAuthUser } from "./interfaces";

export const checkAuth = () => ({
  type: EUserActionTypes.CHECK_AUTH
})

export const login = (authUser: IAuthUser) => ({
  type: EUserActionTypes.LOGIN,
  payload: authUser
})

export const checkedAuth = () => ({
  type: EUserActionTypes.CHECKED_AUTH
})

export const logout = () => ({
  type: EUserActionTypes.LOGOUT
})

export const createUser = (user: IUser) => ({
  type: EUserActionTypes.CREATE_USER,
  payload: {
    user
  }
})


export const getBrand = (id: any) => ({
  type: EUserActionTypes.GET_BRAND,
  payload: {
    id
  }
})

