import React from 'react';
import { Divider } from '@material-ui/core';
import { MButton, MSlider, MSwitch } from 'shared/components';
import { EButtonTypes } from 'shared/interfaces';
import * as Styled from './styles';
import {contractFilterValues,ageFilterValues,incomeFilterValues} from 'config'
class MenuFilters extends React.Component<any, any>{

  constructor(props) {
    super(props);
    const { initialFilters, selectedOptions } = props;
    this.state = {
      personalityType: selectedOptions.personalityType || [],
      ageGroup: selectedOptions.ageGroup || [],
      incomeLevel: selectedOptions.incomeLevel || [],
      contractLength: selectedOptions.contractLength || [],
      genderFemale: selectedOptions.hasOwnProperty('genderFemale') ? selectedOptions.genderFemale : true,
      genderMale: selectedOptions.hasOwnProperty('genderMale') ? selectedOptions.genderMale : true,
      latestPersonasFirst: selectedOptions.hasOwnProperty('latestPersonasFirst') ? selectedOptions.latestPersonasFirst : true,
      personasWithAudio: selectedOptions.hasOwnProperty('personasWithAudio') ? selectedOptions.personasWithAudio : false,
      migration: selectedOptions.hasOwnProperty('migration') ? selectedOptions.migration : true,
      autoRefill: selectedOptions.hasOwnProperty('autoRefill') ? selectedOptions.autoRefill : true,
    }
  }

  assignValue = (id) => (e, val) => {
    this.setState({ [id]: val });
  }

  selectFilterByOnclick = (event,isSelected) => {
    const { target: { name, value } } = event;
    const { initialFilters } = this.props; 
    let stateValue = this.state[name]

    let valueToBeSaved = JSON.parse(value)
    if(!valueToBeSaved[1] && valueToBeSaved[0]< initialFilters[name]?.[1]) valueToBeSaved[1] = initialFilters[name]?.[1]

    if(isSelected){
      stateValue = stateValue.filter((minMaxPair)=>minMaxPair[0]!==valueToBeSaved[0])
    } else {
      stateValue = [...stateValue,valueToBeSaved]
    }

    this.setState({ [name]: stateValue});
  }

  isPersonalitySelected = (id) => this.state.personalityType.includes(id);

  selectPersonality = (id) => () => {
    if (this.state.personalityType.includes(id))
      this.setState({ personalityType: this.state.personalityType.filter(pt => pt !== id) });
    else
      this.setState({ personalityType: this.state.personalityType.concat(id) });
  }

  checkValues = () => this.props.submitFilters(this.state);

  renderButtonGroups = (items:any[],category:string) => {
    const { initialFilters } = this.props;
    const [ApiMinVal,ApiMaxVal] = initialFilters[category]
    let lowerBoundCheckCompleted = false
    let upperBoundCheckCompleted = false
    
    const buttons = items.map((item,index)=>{
      const itemValue = JSON.parse(item?.value)
      const [groupMin,groupMax = ApiMaxVal ] = itemValue
      let disabled = false

      if(!lowerBoundCheckCompleted){
        lowerBoundCheckCompleted = index===0 && ApiMinVal < groupMin || ApiMinVal >= groupMin &&  ApiMinVal<= groupMax
        disabled = !lowerBoundCheckCompleted
      }
      else if (!upperBoundCheckCompleted) {
        upperBoundCheckCompleted = ApiMaxVal >= groupMin && ApiMaxVal<= groupMax
      }
      else if (lowerBoundCheckCompleted && upperBoundCheckCompleted) {
        disabled = true
      }
      const isSelected = this.state[category].findIndex((item)=>item[0]===groupMin) !== -1
      const className = `buttonStyle ${isSelected ? "activeFilter" : "inactive"} ${disabled?'disabled':''}`
      return (
        <button
          disabled={disabled}
          name={category}
          key={item.id}
          value={item.value}
          className={className}
          onClick={(e) => this.selectFilterByOnclick(e,isSelected)}
        >
          {item.name}
        </button>
      );
    })
    return (
    <Styled.FilterButtonGroup>{buttons}</Styled.FilterButtonGroup>
    )
  }

  render() {
    const { label, initialFilters } = this.props;
    return (
      <>
        <div className="title">{label} Filter </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle>
            Select Age Group
        </Styled.FilterSubTitle>
          {this.renderButtonGroups(ageFilterValues,'ageGroup')}
        </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle>
            Select Gender
        </Styled.FilterSubTitle>
          <div>
            <MSwitch
              label="Female"
              checked={this.state.genderFemale}
              onChange={this.assignValue("genderFemale")}
            />
            <MSwitch
              consecutive={26}
              label="Male"
              checked={this.state.genderMale}
              onChange={this.assignValue("genderMale")}
            />
          </div>
        </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle>
            Select Income Level
        </Styled.FilterSubTitle>
          {this.renderButtonGroups(incomeFilterValues,'incomeLevel')}
        </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle>
            Select Contract Length
        </Styled.FilterSubTitle>
          {this.renderButtonGroups(contractFilterValues,'contractLength')}
        </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle className='icon-included'>
            Select Personality Type
            <Styled.HelpIcon onClick={()=>window.open('https://www.verywellmind.com/the-myers-briggs-type-indicator-2795583#the-mbti-types','_blank')}/>
        </Styled.FilterSubTitle>
          <Styled.PersonalityGridContainer>
            {initialFilters.personalityType.map((e, i) =>
              <Styled.PersonalityGridItem
                key={`${e}${i}`}
                selected={this.isPersonalitySelected(e)}
                onClick={this.selectPersonality(e)}
              >
                {e}
              </Styled.PersonalityGridItem>
            )}
          </Styled.PersonalityGridContainer>
        </div>
        <Divider />
        <div>
          <Styled.FilterSubTitle>
            Other Persona Filters
        </Styled.FilterSubTitle>
        <Styled.SwitchContainer>
          <Styled.SwitchLabel>
            Latest Personas First
          </Styled.SwitchLabel>
          <MSwitch
            onChange={this.assignValue("latestPersonasFirst")}
            checked={this.state.latestPersonasFirst}
          />
        </Styled.SwitchContainer>
        <Styled.SwitchContainer>
          <Styled.SwitchLabel>Personas With Audio</Styled.SwitchLabel>
          <MSwitch
            checked={this.state.personasWithAudio}
            onChange={this.assignValue("personasWithAudio")}
          />
        </Styled.SwitchContainer>
        <Styled.SwitchContainer>
          <Styled.SwitchLabel>Migration</Styled.SwitchLabel>
          <MSwitch
            checked={this.state.migration}
            onChange={this.assignValue("migration")}
          />
        </Styled.SwitchContainer>
        <Styled.SwitchContainer>
          <Styled.SwitchLabel>AutoRefill</Styled.SwitchLabel>
          <MSwitch
            checked={this.state.autoRefill}
            onChange={this.assignValue("autoRefill")}
          />
          </Styled.SwitchContainer>
        </div>
        <MButton
          type={EButtonTypes.BASIC}
          onClick={this.checkValues}
          className="button"
          width="100%"
        >
          Apply Filters
      </MButton>
      </>
    );
  }
}


export default MenuFilters;