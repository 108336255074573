import React from "react";
import { withStyles, Fade, Menu } from "@material-ui/core";

import { EUserRole } from 'shared/interfaces';
import { MenuContainerStyle, MenuItems } from "./styles";

const MenuContainer = withStyles(MenuContainerStyle)(Menu);

export default ({ anchorEl, handleClose, onClick, type, data, user_role }) => {
  if (user_role !== EUserRole.VIEW_ONLY) {
    data = [{ name: `Create New ${type || ''}`, id: 'create' }].concat(data);
  
  }
  
  return (
    <MenuContainer
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      MenuListProps={{ style: { padding: 0, margin: '-2px 0 0 0' } }}
      TransitionComponent={Fade}
    >
      <MenuItems anchorEl={anchorEl}>
        {data.map(b => (
          <div
            key={b.id}
            onClick={() => onClick(b.id)}
            className="menuItem"
          >
            {b.name}
          </div>
        ))}
      </MenuItems>
    </MenuContainer>
  )
}