import React from "react"
import { Avatar } from "@material-ui/core"
import { NavigateBeforeRounded, NavigateNextRounded, EditRounded } from "@material-ui/icons"
import { useHistory } from "react-router"
import styled from "styled-components"

import { EUserRole } from 'shared/interfaces';
import { containers } from "config/uiKit"

const SmallHeaderStyled = styled.div<any>`
  position: relative;
  border-top-left-radius: ${containers.borderRadius}px;
  border-top-right-radius: ${containers.borderRadius}px;
  min-height: ${containers.smallHeaderHeight}px;
  font-size: 42px;
  color: white;
  padding-top: 8px;
  text-align: center;
  letter-spacing: 2px;
  background-color: ${props => props.color};
  font-weight: 200;
  .back {
    position: absolute;
    display: flex;
    top: 25px;
    left: 2%;
    cursor: pointer;
  }
  .backText {
    font-size: 16px;
    font-weight: 400;
    margin: 2px 10px;
  }
  .backAvatar {
    width: 24px;
    height: 24px;
    border: 1px solid white;
    background-color: transparent;
    margin: 2px 5px;
  }
  .back:hover {
    .backAvatar{
      background-color: #ffffff66;
    }
  }
  .rightSide {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 17px;
    right: 24px;
    min-height: 45px;
    .backAvatar:hover {
      background-color: #ffffff66;
      cursor: pointer;
    }
  }
  .edit {
    cursor: pointer;
    margin-left: 5px;
  }
  .editAvatar {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    background-color: transparent;
  }
  .edit:hover {
    .editAvatar {
      background-color: #ffffff66;
    }
  }
`

export default ({ user_role = 'ANY', color, children, back = false, edit = false, editAction = null, backText = 'Back to Personas', backAction = null, nav = null }) => {
  const history = useHistory();
  return (
    <SmallHeaderStyled color={color}>
      {children}
      {back &&
        <>
          <div className="back" onClick={backAction || history.goBack}>
            <Avatar className="backAvatar"><NavigateBeforeRounded /></Avatar>
            <div className="backText">{backText}</div>
          </div>
        </>}
      <div className='rightSide'>
        {nav &&
          <>
            <Avatar onClick={nav(-1)} className="backAvatar"><NavigateBeforeRounded /></Avatar>
            <Avatar onClick={nav(1)} className="backAvatar"><NavigateNextRounded /></Avatar>
          </>
        }
        {user_role !== EUserRole.VIEW_ONLY && edit && <div className="edit" onClick={editAction}>
          <Avatar className="editAvatar"><EditRounded /></Avatar>
        </div>}
      </div>
    </SmallHeaderStyled>
  )
}