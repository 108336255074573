import { MsgPanelStyled } from "./styles"
import React from "react"
import { Close } from "@material-ui/icons"
import { Avatar } from "@material-ui/core"

export default ({ closeAction, message }) => {
  return (
    <MsgPanelStyled>
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <div className="form-content">
        <h2>{message}</h2>
      </div>
    </MsgPanelStyled>
  )
}