import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { withStyles, Slider, GridList, GridListTile, Avatar } from "@material-ui/core";
import { Close, DoubleArrow } from "@material-ui/icons";

import { EButtonTypes } from "shared/interfaces";
import { MButton } from "shared/components";
import { SliderStyledProps, PersonalityStyled, AppAvatarStyled, AppsSelectorStyled, ScrollIconStyled } from "./styles";

export default ({ editing, changeInfo, color, persona, most_used_apps, modal: { handleClose, handleOpen }, apps, resetPersona, savePersona }) => {

  const { introvert, thinking, sensing, judging } = persona;
  const [personality, setPersonality]: any = useState({ introvert, thinking, sensing, judging });
  const [avatarSize, setAvatarSize] = useState(0);
  const [showScIcon, setShowScIcon] = useState(false);
  const SliderStyled = withStyles(SliderStyledProps({ color }))(Slider);
  const personalities: any = [
    { label1: 'Introvert', label2: 'Extrovert' },
    { label1: 'Thinking', label2: 'Feeling' },
    { label1: 'Sensing', label2: 'Intuition' },
    { label1: 'Judging', label2: 'Perceiving' },
  ].map(v => {
    const id = v.label1.toLowerCase();
    const p = personality[id];
    let value = p !== '' && !isNaN(p) ? p : 50;
    return ({ ...v, id, value });
  });

  useEffect(() => {
    const { introvert, thinking, sensing, judging } = persona;
    setPersonality({ introvert, thinking, sensing, judging });
  }, [persona]);

  useEffect(() => getAvatarSize());

  const changePersonality = id => (e, v) => {
    personality[id] = v;
    changeInfo(id)(null, v);
  }

  const mapIdToApp = (id) => apps.find(a => a.id === id);

  const changeApps = (apps) => {
    changeInfo('most_used_apps')(null, apps);
    handleClose();
    openApps(most_used_apps)
  }

  const getAvatarSize = () => {
    const scDiv = $(`#appsList`);
    const h = scDiv.height() - 10;
    const size = h > 120 ? 120 : h < 80 ? 80 : h;
    if (avatarSize !== size) setAvatarSize(size);
    if (scDiv?.[0]) {
      const show = scDiv[0].clientHeight < scDiv[0].scrollHeight;
      if (showScIcon !== show) setShowScIcon(show);
    }
  }
  const openApps = (most_used) => handleOpen(
    <AppsSelector apps={apps} userApps={most_used} updateApps={changeApps} open={openApps} close={handleClose} />
  )();

  const selApps = [];
  if (persona?.most_used_apps && apps.length) selApps.push(...most_used_apps.map(mapIdToApp).filter(a => !!a));
  if (editing && selApps.length < 4) selApps.unshift({ id: -1, name: 'select' });

  return (
    <PersonalityStyled height={301 + editing * 66} color={color}>
      <div className="apps">
        <div className="appsHeader">
          <h2>MOST USED APPS</h2>
          {showScIcon && <ScrollIconStyled color={color}><DoubleArrow /></ScrollIconStyled>}
        </div>
        <hr style={{ marginBottom: 0 }} />
        <div id="appsList" className="appsList">
          <div className="innerList">
            <GridList cellHeight={avatarSize + 20} cols={4} className="gridList">
              {avatarSize && selApps.map(a =>
                <GridListTile key={a.id} cols={1}>
                  <AppAvatarStyled
                    onClick={editing ? () => openApps(most_used_apps) : () => { }}
                    size={avatarSize}
                    editing={editing}
                    title={a.name}
                    image={a.image_url}
                    noTransparency
                  >
                    {a.name === 'select' && <div className="appText">Select App</div>}
                  </AppAvatarStyled>
                </GridListTile>
              )}
            </GridList>
          </div>
        </div>
      </div>
      <div className="personality">
        <h2>PERSONALITY</h2>
        <hr />
        {personalities.map(p =>
          <div className="slider" key={p.label1}>
            <div className="labels">
              <div className="label1">{p.label1}</div>
              <div className="label2">{p.label2}</div>
            </div>
            <SliderStyled
              track={false}
              defaultValue={p.value}
              onChangeCommitted={changePersonality(p.id)}
              disabled={!editing}
            />
          </div>
        )}
      </div>
      <div className="buttons">
        {editing &&
        <>
          <div className="buttonContainer">
            <MButton
              type={EButtonTypes.BASIC}
              onClick={resetPersona}
              width="100%"
              className="button buttonGray"
            >
              Reset Persona Details
            </MButton>
          </div>
          <div className="buttonsSpace" />
          <div className="buttonContainer">
            <MButton
              type={EButtonTypes.BASIC}
              onClick={savePersona}
              width="100%"
              className="buttonGreen"
            >
              Save Persona
              </MButton>
          </div>
        </>
        }
      </div>
    </PersonalityStyled>
  )
}

const AppsSelector = ({ apps, userApps, updateApps, open, close }) => {
  const appSelected = (id) => !!userApps.find(app_id => app_id === id)
  const appsNames = apps.map(app => ({ selected: appSelected(app.id), ...app }));

  const selectApp = app => e => {
    let newApps = (appSelected(app.id)) ?
      userApps.filter(id => app.id !== id) :
      userApps.concat(app.id);
    if (newApps.length > 4) return;
    updateApps(newApps);
    open(newApps);
  }

  const cols = Math.floor(($(window).width() * .6 - 40) / 140);
  return (
    <AppsSelectorStyled>
      <div className="title">
        Selected Apps ( {userApps.length} / {appsNames.length} )
      </div>
      <Avatar className="closeButton" onClick={close}>
        <Close />
      </Avatar>
      <GridList cellHeight={140} cols={cols} className="gridList">
        {appsNames.map(a => (
          <GridListTile key={a.id} cols={1}>
            <AppAvatarStyled
              onClick={selectApp(a)}
              size={120}
              editing={true}
              title={a.name}
              image={a.image_url}
              selected={a.selected}
            />
          </GridListTile>
        ))}
      </GridList>
    </AppsSelectorStyled>
  )
}