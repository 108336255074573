import { EUserRole } from "shared/auth";

export enum ERoutesName {
  HOME = "/",
  LOGIN = "/login",
  PERSONA = "/persona/:idPersona",
  NEW_PERSONA = "/persona/new",
  BRAND_VOICE = '/brand_voice/:folderId',
  DASHBOARD_PREFIX = '/dashboard/',
  DASHBOARD = "/dashboard",
  DASHBOARD_BOARDS = '/dashboard/boards',
  DASHBOARD_BRAND_VOICES = '/dashboard/brand_voices',
  DASHBOARD_BRAND_VOICE ='/dashboard/brand_voice/:brand_id',
  DASHBOARD_TEAM = '/dashboard/team',
  DASHBOARD_ACCOUNT_SETTINGS = '/dashboard/account_settings',
  ACCOUNT_SETTINGS = '/account_settings'
}

export enum ELoaderTypes {
  GLOBAL = "GLOBAL",
  CREATE_PERSONA = 'CREATE_PERSONA',
  CREATE_GROUP = 'CREATE_GROUP',
  DETAIL_PERSONA = 'DETAIL_PERSONA',
  TOGGLE_PERSONA_TO_FOLDER = 'TOGGLE_PERSONA_TO_FOLDER'
}

export enum ESelectorType {
  GROUP = "Group",
  FOLDER = "Folder",
  PERSONA = "Persona"
}

export enum EContainerNames {
  PERSONAS = "PERSONAS",
  USERS = "USERS",
  BRANDS = "BRANDS",
  GROUPS = "GROUPS",
  FILTERS = "FILTERS",
  AUTH = "AUTH",
  LOADER = "LOADER",
  SELECTORS = "SELECTORS",
  ERROR = "ERROR",
  APPS = "APPS",
  ROUTER = "router"
}

export enum EEmitterEvents {
  BRAND_CHANGED = "BrandChanged",
  DISPLAY_ERROR = "DisplayError"
}

export const RoleString = {
  [EUserRole.ADMIN]: 'Full Admin User',
  [EUserRole.VIEW_ONLY]: 'View Only User',
  [EUserRole.EDIT_ONLY]: 'Edit Only User',
}

export enum EBrandIDs { 
  TRACFONE = 1,
  STRAIGHT_TALK = 2,
  TOTAL_WIRELESS = 3,
  NET10 = 4,
  SIMPLE_MOBILE = 5,
  PAGE_PLUS = 6,
  SAFELINK = 7,
  GOSMART = 8,
  FAMILY_MOBILE = 9
}