import React from 'react';

import { BrandVoiceContent, InfoContainer } from './styles';
import { SmallHeader } from 'components';
import { colors } from 'config/uiKit'

const Brand = ({style = {}, navButtonsFunc = null, ...brand}) =>
  <BrandVoiceContent id={`brand_${brand.brand_id}`} style={style}>
    <SmallHeader 
      color={colors.brands[brand.brand || brand.name]}
      nav={navButtonsFunc}
    >
      <strong>{brand.brand || brand.name} |</strong> Brand Voice
    </SmallHeader>
    <div className="mainContainer">
      <div className="flexContainer">
        <InfoContainer width="34%">
          <h2>Personality</h2>
          <ul style={{fontSize: 20}}>{brand.personality.map(v => <li key={v}>{v}</li>)}</ul>
        </InfoContainer>
        <InfoContainer width="66%">
          <h2>General Guidelines</h2>
          <ul>{brand.general_guidelines.map(v => <li key={v}>{v}</li>)}</ul>
        </InfoContainer>
      </div>
      <div className="flexContainer">
        <InfoContainer width="34%">
          <h2>Tone</h2>
          <ul style={{fontSize: 20}}>{brand.tone.map(v => <li key={v}>{v}</li>)}</ul>
        </InfoContainer>
        <InfoContainer width="66%">
          <h2>Writing Tips</h2>
          <ul>{brand.writing_tips.map(v => <li key={v}>{v}</li>)}</ul>
        </InfoContainer>
      </div>
    </div>
  </BrandVoiceContent>

export default Brand;