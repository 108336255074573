import styled from "styled-components";
import { containers, colors } from "config/uiKit";


export const BrandVoiceContent = styled.div<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  min-height: calc(100% - 120px);
  margin: 20px 0;
  .mainContainer {
    padding: 10px;
    height: calc(100% - 70px);
  }
  .flexContainer {
    box-sizing: border-box;
    display: flex;
    height: 50%;
    padding: 10px 0;
  }
`

export const BrandMainContainer = styled.div`
  height: 100%;
  overflow: auto;
`

export const InfoContainer = styled.div<any>`
  width: ${props => props.width || '50%'};
  box-sizing: border-box;
  margin: 0 10px;
  padding: 15px 30px;
  background-color: ${colors.grayScale.light};
  border-radius: ${containers.borderRadius}px;
  height: 100%;
  font-size: 18px;
  overflow-y: auto;
  h2 {
    font-size: 26px;
    letter-spacing: 0.04em;
    margin-bottom: 30px;
  }
  li {
    margin: 0 0 10px 10px;
    padding-left: 10px;
  }
`