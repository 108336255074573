import React, { useState } from "react"
import { connect } from 'react-redux'
import { Avatar } from "@material-ui/core"
import { Close, CameraAltOutlined } from "@material-ui/icons"

import { Form, MButton } from 'shared/components';
import { EBasicActionTypes } from 'shared/containers'
import { EButtonTypes } from 'shared/interfaces';

import { getDispatchAction } from 'store';
import { toogleMessage } from "store/selectionContainer";
import { EContainerNames, ELoaderTypes, } from 'interfaces';
import { userForm } from 'config/forms';

import { Row, Column } from 'components'
import { CreateUserContainer, Header } from "./styles"


const CreateUser = ({ closeAction, createUser }: any) => {

  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  //const [errorMessage, setErrorMessage] = useState('');
  let imageSelector;

  const submitHandler = (formData) => {
    //const isValid = isDataValid(formData, CommonMsg.fillFields);
    //if (!isValid.value) return setErrorMessage(isValid.msg);
    let modFormData = {...formData};
    Object.keys(formData).forEach(formKey => {
      modFormData[formKey] = formData[formKey].trim('');
    });
    if (imageBlob) {
      closeAction();
      createUser({
        ...modFormData,
        profile_picture: imageBlob,
      })
      closeAction();
    }
  }

  const openSelector = () => imageSelector.click();

  const selectImage = async event => {
    if (event.target?.files?.length) {
      let image_file = event.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setImage(image_url);
      setImageBlob(image_file);
    }
  }


  return (
    <CreateUserContainer image={image}>
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <Header>New Team Member</Header>
      <Row height='380px' width='100%'>
        <Column height='100%' width='260px' margin='0 73px 2% 0'>
          <input
            ref={ref => imageSelector = ref}
            type="file"
            onChange={selectImage}
            style={{ display: 'none' }}
            accept="image/*"
          />
          {image ?
            <div className="image-container" onClick={openSelector} /> :
            <div className="no-image" onClick={openSelector}>
              <Avatar className="cam-avatar">
                <CameraAltOutlined />
              </Avatar>
            </div>}
          <MButton
            onClick={openSelector}
            type={EButtonTypes.BASIC}
            className="upload-button"
            width='200px'
          >
            {image ? 'Change Photo' : 'Upload Photo'}
          </MButton>
        </Column>
        <Row height='100%' width='970px'>
          <Form
            formData={userForm({}, 'CREATE')}
            submit={submitHandler}
          />
        </Row>
      </Row>
    </CreateUserContainer>
  )
}

const mstp = (state) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_GROUP]
})

const mdtp = (dispatch) => ({
  createUser: getDispatchAction({ dispatch, container: EContainerNames.USERS, actionType: EBasicActionTypes.CREATE }),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default connect(mstp, mdtp)(CreateUser);