export enum ELoaderActionTypes {
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',

}
export interface ILoaderActionTypes {
  type: ELoaderActionTypes;
  payload: any;
}

