
import { Dispatch } from 'redux'
import { IActionConfig } from './interfaces';
import { EBasicActionTypes, ERestMethods } from './'
import { findAndUpdateById } from 'shared/utils';


export const createEpicActionsConfig = ({ getterName, setterName, startActions = [], loader = 'GLOBAL' }) => ({
  start: [`${getterName}`, ...startActions],
  recieve: `${setterName}`,
  loader: loader 
})

export const actionCreator = (actionType: string, loader = 'GLOBAL'): Function => {
  return (payload: any) => ({
    type: actionType,
    payload,
    loader
  })
}

export const dispatchActionCreator = (dispatch: Dispatch, actionType: string, loader: string) => {
  return (payload => dispatch(actionCreator(actionType, loader)(payload)))
}

export const createNamedActions = ({
  containerName,
}): Record<EBasicActionTypes, IActionConfig> => ({
  [EBasicActionTypes.LIST]: {
    ...createGetAndSet(`${containerName}_${EBasicActionTypes.LIST}`),
    setterMethod: (state, payload) => ({ ...state, list: payload })
  },
  [EBasicActionTypes.DETAIL]: {
    ...createGetAndSet(`${containerName}_${EBasicActionTypes.DETAIL}`),
    setterMethod: (state, payload) => ({ ...state, detail: payload })
  },
  [EBasicActionTypes.CREATE]: {
    ...createGetAndSet(`${containerName}_${EBasicActionTypes.CREATE}`),
    setterMethod: (state, payload) => ({ ...state, list: state.list.concat(payload), detail: payload })
  },
  [EBasicActionTypes.UPDATE]: {
    ...createGetAndSet(`${containerName}_${EBasicActionTypes.UPDATE}`),
    setterMethod: (state, payload) => ({ ...state, list: findAndUpdateById(state.list, payload), detail: payload })
  },
  [EBasicActionTypes.DELETE]: {
    ...createGetAndSet(`${containerName}_${EBasicActionTypes.DELETE}`),
    setterMethod: (state, payload) => ({ ...state, list: findAndUpdateById(state.list, payload), detail: payload })
  },
})


const createGetAndSet = (actionType) => ({
  getterName: 'GET_' + actionType,
  setterName: 'SET_' + actionType,
})



export const createRequestConfig = ({ 
    type, 
    url, 
    urlModifier = defaultURLModifier,
    requestBodyMapper = (body) => body
   }) => {

  const requests = {
    [EBasicActionTypes.DETAIL]: (action) => ({
      url: urlModifier({ url, action }),
      method: ERestMethods.GET,
      withCredentials: true,
    }),
    [EBasicActionTypes.LIST]: (action) => {
      return ({
        url: urlModifier({ url, action }),
        method: ERestMethods.GET,
        withCredentials: true,
      })
    },
    [EBasicActionTypes.CREATE]: (action) => {
      return ({
        url: urlModifier({ url, action }),
        method: ERestMethods.POST,
        body: requestBodyMapper(action.payload),
        withCredentials: true,
      })
    },
    [EBasicActionTypes.UPDATE]: (action) => {
      return ({
        url: urlModifier({ url, action }),
        method: ERestMethods.PATCH,
        body: requestBodyMapper(action.payload),
        withCredentials: true,
      })
    },
    [EBasicActionTypes.DELETE]: (action) => {
      return ({
        url: urlModifier({ url, action }),
        method: ERestMethods.DELETE,
        body: action.payload,
        withCredentials: true,
      })
    },

  }

  return requests[type]
}

const defaultURLModifier = ({ action = { payload: { url: null } }, url }) => {
  return action.payload.url ? url + `${action.payload.url}` : url
}



