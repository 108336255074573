import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withWidth } from '@material-ui/core';
import { IAppState, EContainerNames } from 'interfaces';
import { Page } from 'components';

import { getBrand } from 'shared/auth';
import Brand from './brand';

class BrandVoice extends Component<any, any> {

  state = { prevPage: null };

  componentDidMount() {
    const { history } = this.props;
    this.setState({ prevPage: history.location.state?.from });
  }

  goToNextBrand = (inc) => () => {
    const { history, brandsList, match } = this.props;
    const newIdx = brandsList.map(p => p.id).indexOf(parseInt(match.params.brand_id)) + inc;
    const newBrand = brandsList[newIdx >= 0 ? newIdx % brandsList.length : brandsList.length - 1]
    history.push(`${newBrand.id}`)
  }

  render() {
    const { history, uiConfig, match, brandsList } = this.props;
    const brandId = parseInt(match.params.brand_id);
    let brand = brandsList.find(b => b.id === brandId);
    
    const hp = { ...uiConfig(this.props).headerProps };
    hp.left[0].onClick = this.state.prevPage ? () => history.push(this.state.prevPage) : null;

    if (!brand) return <div></div>;
    return (
      <Page headerProps={hp} height='100%'>
        <Brand 
          navButtonsFunc={this.goToNextBrand}
          {...brand} 
          style={{marginTop: 10}}
         />
      </Page>
    )
  }
}

const cmpWidthProp = withWidth()(BrandVoice);
//const styledComponent = withStyles(brandVoiceStyle)(cmpWidthProp);

const mstp = (state: IAppState) => ({
  loading: state['LOADER'].global,
  brandsList: state[EContainerNames.BRANDS].list
})

const mdtp = (dispatch: Function) => ({
  getBrand: (id: any) => dispatch(getBrand(id)),
})

export default connect(mstp, mdtp)(cmpWidthProp);