import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withWidth, Avatar } from '@material-ui/core';
import { CameraAltOutlined, FolderOpenOutlined } from "@material-ui/icons";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined';
import { getDispatchAction } from 'store';

import { EBasicActionTypes } from 'shared/interfaces';
import { Form } from 'shared/components';

import { colors } from 'config/uiKit';
import { userForm } from 'config/forms'
import { IAppState, EContainerNames, RoleString } from 'interfaces';
import { Page, SmallHeader, Row, Column } from 'components';
import { Container, StyledStatBox, ImageContainer, NoImage } from './styles';


class AccountSettings extends Component<any, any> {
  private imageSelector: any;
  constructor(props) {
    super(props)

    this.imageSelector = {};
    this.state = {
      image: null,
      imageBlob: null
    }
  }

  submit = (formData) => {
    const { user, updateUser } = this.props;
    // const isValid = isDataValid(formData, CommonMsg.fillFields);
    // if (!isValid.value) return setErrorMessage(isValid.msg);
    let modFormData = {...formData};
    Object.keys(formData).forEach(formKey => {
      modFormData[formKey] = formData[formKey].trim('');
    });
    let updatedUser = {
      ...modFormData,
      id: user.id,
      profile_picture_url: user.profile_picture_url
    }
    if (this.state.imageBlob) {
      updatedUser.profile_picture = this.state.imageBlob
    }
    updateUser(updatedUser)
  }

  openSelector = () => this.imageSelector.click();

  selectImage = async event => {
    if (event.target?.files?.length) {
      let image_file = event.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      this.setState({ image: image_url, imageBlob: image_file });
    }
  }

  render() {
    const { uiConfig, user, groups, users } = this.props;
    const selectedUser = users && users.length ? users.find(selUser => selUser.id === user.id) : user;
    let activeImage = this.state.image || selectedUser.profile_picture_url;
    const ui = uiConfig(this.props);
    return (
      <Page
        scrollable
        headerProps={ui.headerProps}
        height='100%'
      >
        <Container  justify='space-between'>
          <SmallHeader color={colors.blueScale.dark}>
            <div className="header">
              <div className="title">Account Settings</div>
              <div className="user">
                {RoleString[selectedUser.user_role]}
                <div className="avatarContainer">
                  <div className="greenCircle" />
                </div>
              </div>
            </div>
          </SmallHeader>
          <Row width='100%' padding='82px 100px 0 100px'>
            <Column height='100%' align='center' width="260px">
              <input
                ref={ref => this.imageSelector = ref}
                type="file"
                onChange={this.selectImage}
                style={{ display: 'none' }}
                accept="image/*"
              />
              {activeImage ?
                <ImageContainer image={activeImage} onClick={this.openSelector} /> :
                <NoImage className="no-image" onClick={this.openSelector}>
                  <Avatar className="cam-avatar">
                    <CameraAltOutlined />
                  </Avatar>
                </NoImage>}
              {/* <ChangePhotoButton
                onClick={this.openSelector}
                bold
                dk
              >
                {activeImage ? 'Change Photo' : 'Upload Photo'}
              </ChangePhotoButton> */}
            </Column>
            <Column width='calc(100% - 320px);'>
              <Form
                formData={userForm(selectedUser, 'EDIT', { width: '45%', margin: '10px 0' })}
                submit={this.submit}
              />
            </Column>
          </Row>
          <Row width='calc(100% - 200px)' margin='40px 100px'>
            <div style={{ width: '100%', borderBottom: '2px solid #E2E2E2' }}></div>
          </Row>
          <Row padding='0 100px 20px 100px' align='center' justify='space-between'>
            <StatBox bg='#003388' num={groups.length} label='Persona Groups' icon={<FolderSharedOutlinedIcon style={{ width: '60px', height: '60px' }} />} />
            <StatBox bg='#53C2DF' num={calculateFoldCount(groups)} label='Persona Folders' icon={<FolderOpenOutlined style={{ width: '60px', height: '60px' }} />} />
            <StatBox bg='#5091FF' num={users.length} label='Team Members' icon={<GroupOutlinedIcon style={{ width: '60px', height: '60px' }} />} />
          </Row>
        </Container>
      </Page>
    )
  }
}


const StatBox = ({ num, label, icon, bg }) => {
  return <StyledStatBox bg={bg}>
    <Column justify='center' width='70%' align='flex-start'>
      <div className="bigNum">{num}</div>
      <div className="smallText">{label}</div>
    </Column>
    <Column justify='center' align='flex-end' width='30%'>
      {icon}
    </Column>
  </StyledStatBox>
}

const calculateFoldCount = (groups: any) => {
  return groups.reduce((acc, g) => acc + g.folders.length, 0)
}



const cmpWidthProp = withWidth()(AccountSettings);

const mstp = (state: IAppState) => ({
  loading: state['LOADER'].global,
  user: state[EContainerNames.AUTH].userInfo,
  users: state[EContainerNames.USERS].list,
  groups: state[EContainerNames.GROUPS].list,
})

const mdtp = (dispatch: Function) => ({
  updateUser: getDispatchAction({ dispatch, container: EContainerNames.USERS, actionType: EBasicActionTypes.UPDATE }),
})

export default connect(mstp, mdtp)(cmpWidthProp);