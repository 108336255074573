import { IRoute, ERoutesName } from 'interfaces';
import { Navigation, Login, MainBoard, PersonaPanel, BrandVoice, MyDashboard, AccountSettings, BrandVoicesList, PersonaBrandVoice, ManageTeam } from 'views';

export const routes: IRoute[] = [
  {
    path: ERoutesName.LOGIN,
    component: Login,
    exact: false,
    private: false
  },
  {
    path: ERoutesName.HOME,
    component: Navigation,
    exact: false,
    private: true,
    routes: [
      {
        path: ERoutesName.HOME,
        component: MainBoard,
        exact: true,
        private: true
      }, 
      {
        path: ERoutesName.PERSONA,
        component: PersonaPanel,
        exact: false,
        private: true,
      },
      {
        path: ERoutesName.BRAND_VOICE,
        component: BrandVoice,
        exact: false,
        private: false,
      },
      {
        path: ERoutesName.DASHBOARD_ACCOUNT_SETTINGS,
        component: AccountSettings,
        exact: false,
        private: false,
      },
      {
        path: ERoutesName.DASHBOARD_BOARDS,
        component: MyDashboard,
        exact: true,
        private: true
      }, 
      {
        path: ERoutesName.DASHBOARD_TEAM,
        component: ManageTeam,
        exact: false,
        private: false,
      },
      {
        path: ERoutesName.DASHBOARD_TEAM,
        component: AccountSettings,
        exact: false,
        private: false,
      },
      {
        path: ERoutesName.DASHBOARD_BRAND_VOICES,
        component: BrandVoicesList,
        exact: false,
        private: false,
      },
      {
        path: ERoutesName.DASHBOARD_BRAND_VOICE,
        component: PersonaBrandVoice,
        exact: false,
        private: false,
      }
    ]
  }
];

