import styled from "styled-components";

import { Typography } from 'components'
import { containers, colors } from "config/uiKit";


export const Container = styled.div<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  margin-top: 10px;
  .mainContainer {
    padding: 10px;
    height: 100%;
  }
  .flexContainer {
    box-sizing: border-box;
    display: flex;
    height: 48%;
    padding: 10px 0;
  }
  .header {
    display: flex;
    font-size: 26px;
    font-weight: 400;
    margin: 12px 30px 16px 30px;
  }
  .title {
    flex-grow: 10;
    text-align: left;
  }
  .user {
    display: flex;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0;
  }
  .avatarContainer {
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 16px;
    margin-left: 14px;
  }
  .greenCircle {
    width: 20px;
    height: 20px;
    margin: 6px;
    border-radius: 10px;
    background-color: ${colors.greenScale.light};
  }
`

export const StyledStatBox = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${({ bg }) => bg};
  border-radius: ${containers.borderRadius}px;
  width: 340px;
  padding: 17px 30px;
  height: 140px;
  color: white;
  white-space: nowrap;
  .bigNum {
    font-size: 64px;
    font-weight: 600;
    margin: -12px 0;
  }
  .icon {
    width: 50px;
    height: 50px;
    color: white;
  }
  .smallText {
    font-size: 18px;
  }
`

export const ImageContainer = styled.div<any>`
  width: 260px;
  height: 260px;
  border-radius: 130px;
  background-position: center;
  background-size: cover;
  background-image: url('${props => props.image}');
  cursor: pointer;
`

export const NoImage = styled.div`
  width: 260px;
  height: 260px;
  cursor: pointer;
  .cam-avatar {
    width: 260px;
    height: 260px;
    margin: auto;
  }
  .MuiSvgIcon-root {
    font-size: 60px;
  }
`

export const ChangePhotoButton = styled(Typography)`
  padding: 4%;
  margin-top: 50px;
  border: 1px solid ${colors.grayScale.medium};
  border-radius: ${containers.borderRadius}px;
  &:hover {
    cursor: pointer;
  }
`



