import React, { useState } from "react";
import { Text } from '@react-pdf/renderer';

import * as Styled from './styles';
import { CORS_PROXY } from 'utils'

export default ({ color, persona, apps }) => {
  const { introvert, thinking, sensing, judging } = persona;
  const [personality]: any = useState({ introvert, thinking, sensing, judging });

  const personalities: any = [
    { label1: 'Introvert', label2: 'Extrovert' },
    { label1: 'Thinking', label2: 'Feeling' },
    { label1: 'Sensing', label2: 'Intuition' },
    { label1: 'Judging', label2: 'Perceiving' },
  ].map(v => {
    const id = v.label1.toLowerCase();
    const p = personality[id];
    let value = p !== '' && !isNaN(p) ? p : 50;
    return ({ ...v, id, value });
  });

  const mapIdToApp = (id) => apps.find(a => a.id === id);

  return (
    <Styled.PersonalityStyled color={color}>
      <Styled.PersonalityApps>
        <Styled.Header>
          {/* <Styled.AboutIcon>{icon}</Styled.AboutIcon> */}
          <Text>MOST USED APPS</Text>
        </Styled.Header>
        <Styled.Divider />
        <Styled.AppRow>
          {persona.most_used_apps
            .map(mapIdToApp)
            .filter(a => !!a)
            .map(a =>
              <Styled.AppImage
                src={CORS_PROXY + a.image_url}
              />)
          }
        </Styled.AppRow>

      </Styled.PersonalityApps>
      <Styled.Personality>
        <Styled.Header>
          <Text>Personality</Text>
        </Styled.Header>
        <Styled.Divider />
        {personalities.map(p =>
          <Styled.SliderContainer key={p.label1}>
            <Styled.Labels>
              <Styled.Label1>{p.label1}</Styled.Label1>
              <Styled.Label2>{p.label2}</Styled.Label2>
            </Styled.Labels>
            <Styled.Slider>
              <Styled.Thumb value={p.value} color={color} />
            </Styled.Slider>

          </Styled.SliderContainer >
        )}
      </Styled.Personality>
    </Styled.PersonalityStyled>
  )
}
