import React from 'react';
import { connect } from 'react-redux';
import { RadioGroup, Radio, Checkbox } from '@material-ui/core';


import { EInputTypes, IFormData, IFormProps, IFormInput } from './interfaces';
import { Row } from 'components';

import { MTextField, MButton } from 'shared/components'
import { MRadioWrapper, FormContainer, OptionsContainer } from './styles';


const getStateFromForms = (formData: IFormData | IFormInput) => {
  return formData.inputFields.reduce((tempState, inputInfo) => {
    if (inputInfo.type === EInputTypes.ROW) {
      let inputs: any = getStateFromForms(inputInfo);
      return {
        ...tempState,
        ...inputs,
      }
    } else {
      return {
        ...tempState,
        [inputInfo.id]: inputInfo.value || undefined
      }
    }
  }, {})
}

const getRequiredFields = (formData) => {
  return formData.inputFields.reduce((finalObj, inputInfo) => {
    if (inputInfo.type === EInputTypes.ROW) {
      let inputs: any = getRequiredFields(inputInfo);
      return [...inputs, ...finalObj]
    } else if (inputInfo.required) {
      return [...finalObj, inputInfo.id]
    } else {
      return finalObj
    }
  }, [])
}

class Form extends React.Component<IFormProps, any> {
  private requiredFields: Array<any>

  constructor(props) {
    super(props);

    this.state = getStateFromForms(props.formData);
    this.requiredFields = getRequiredFields(props.formData);
  }

  onChange = key => e => {
    this.setState({ [key]: e.target.value })
  }

  onCheckboxChange = key => e => {
    let { value } = e.target;
    let currentState = this.state[key]
    if (currentState && currentState.includes(value)) {
      let newState = currentState.filter(keys => keys !== value)
      this.setState({ [key]: newState })
    } else {
      let newState = currentState ? currentState.concat(value) : [value];
      this.setState({ [key]: newState })
    }
  }

  submit = (e) => {
    if (e) e.preventDefault();
    let formData = this.state;

    let formCheck = this.allRequiredFilled();
    if (formCheck.requiredFilled) {
      if (this.props.formData.submitFormModifier) {
        formData = this.props.formData.submitFormModifier(formData)
      }
      // console.log("Submitting form", formData)
      this.props.submit(formData)
    } else {

      // this.setState()
    }
  }

  allRequiredFilled = () => {
    let emptyFields = this.requiredFields.filter(field => !this.state[field]);
    if (emptyFields.length) {
      return { requiredFilled: false, emptyFields };
    } else {
      return { requiredFilled: true };
    }
  }

  renderForm = (formData) => {
    return formData.map((inputInfo: any, idx) => {
      switch (inputInfo.type) {
        case EInputTypes.ROW:
          return <Row key={idx}>
            {this.renderForm(inputInfo.inputFields)}
          </Row>
        case EInputTypes.RADIO_GROUP:
          return (
            <RadioGroup
              key={inputInfo.id}
              onChange={this.onChange(inputInfo.id)}
              row
            >
              <OptionsContainer>
                {inputInfo.options.map(option => (
                  <MRadioWrapper
                    key={option.label}
                    control={<Radio />}
                    value={option.value}
                    label={option.label}
                    labelPlacement='start'
                  />
                )
                )}
              </OptionsContainer>

            </RadioGroup>
          )
        case EInputTypes.TEXT_FIELD:
          return (
            <MTextField
              key={inputInfo.id}
              style={inputInfo.style}
              onChange={this.onChange(inputInfo.id)}
              value={this.state[inputInfo.id]}
              id={inputInfo.id}
              label={inputInfo.label}
              placeholder={inputInfo.label}
            />
          )
        case EInputTypes.SECURE_TEXT_FIELD:
          return (
            <MTextField
              key={inputInfo.id}
              type='password'
              style={inputInfo.style}
              onChange={this.onChange(inputInfo.id)}
              value={this.state[inputInfo.id]}
              id={inputInfo.id}
              label={inputInfo.label}
              placeholder={inputInfo.label}
            />
          )
        case EInputTypes.CHECKBOX_GROUP:
          return (
            <OptionsContainer key={inputInfo.id}>
              {inputInfo.options.map(option => {
                return <MRadioWrapper
                  key={option.label}
                  control={<Checkbox onChange={this.onCheckboxChange(inputInfo.id)} />}
                  value={option.value}
                  label={option.label}
                  labelPlacement='start'
                />
              })}
            </OptionsContainer>
          )
        default: return null;
      }
    })
  }

  render() {
    return (
        <FormContainer onSubmit={this.submit}>
          {this.renderForm(this.props.formData.inputFields)}
          <Row margin='2% 0' justify='flex-end'>
            <MButton buttonType='submit' onClick={this.submit}>{this.props.formData.submitButtonText}</MButton>
          </Row>
        </FormContainer>
    )
  }
}

const mstp = (state: any, ownProps: any) => ({
  loading: state['LOADER'][ownProps.formData.loader || 'GLOBAL']
})

const mdtp = (dispatch: any, ownProps: any) => ({
})

export default connect(mstp, mdtp)(Form);
