export const gridStyles = (theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  gridTile: {
    width: 260,
    overflow: 'hidden',
    height: '100%',
    margin: '0 auto 30px auto'
  },
  gridList: {
    width: 'calc(100% + 15px)',
    'scrollbar-width': 'none',
    'overflow-x': 'hidden'
  },
  gridTileRoot: {
    minHeight: 400,
    maxHeight: 450
  }

});
