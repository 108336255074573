import { EContainerNames } from 'interfaces';
import { url } from './env';

const endpoints = {
  auth: 'auth',
  login: 'auth/login',
  logout: 'auth/logout',
  [EContainerNames.PERSONAS]: 'personas',
  [EContainerNames.BRANDS]: 'brands',
  [EContainerNames.USERS]: 'users',
  [EContainerNames.GROUPS]: 'groups',
  [EContainerNames.FILTERS]: 'filters',
  [EContainerNames.APPS]: 'apps',
  folders: 'groups/folders'

}

const url_endpoints: any = Object.keys(endpoints)
  .reduce((acc: object, key: string) => ({
    [key]: url + endpoints[key as keyof typeof endpoints],
    ...acc
  }), {}) 

export default url_endpoints;