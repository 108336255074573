import styled from "@react-pdf/styled-components";
import { containers, colors } from "config/uiKit";


export const Page = styled.View`
  display: flex;
  overflow: ${({ scrollable }) => scrollable ? 'scroll' : 'hidden'};
  overflow-x: hidden;
  flex-direction: column;
  height: ${({ height }) => height};
  max-height: 100%;
  width: 100%;
  padding: 0;
  justify-content:  ${({ justify = 'space-between' }) => justify};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-size: 14px;
`

export const SmallHeader = styled.View`
  border-top-left-radius: ${containers.borderRadius}px;
  border-top-right-radius: ${containers.borderRadius}px;
  height: ${containers.smallHeaderHeight}px;
  font-size: 42px;
  color: white;
  padding-top: 8px;
  text-align: center;
  letter-spacing: 2px;
  background-color: ${props => colors.brands[props.brand]};
`

export const Content = styled.View<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  min-height: calc(100% - 120px);
`

