
import { Action } from 'redux'
import {  ActionsObservable, StateObservable, Epic } from 'redux-observable';
import { map, filter } from 'rxjs/operators'

import { IEpicConfig, IAction } from '../containers'
import { EUserActionTypes } from '../auth';

export const startLoading = (payload = 'GLOBAL') => ({
  type: `START_${payload}_LOADING`,
  payload
})

export const stopLoading = (payload = 'GLOBAL') => ({
  type:`STOP_${payload}_LOADING`,
  payload
})


export const extractActionsFromConfig = (actionKey, epicConfig: Record<string, IEpicConfig>) => {
  return Object.values(epicConfig)
    .map(c => c.actions)
    .reduce((actions: any, action): any => {
      return actions.concat(action[actionKey])
    }, [])
}

export const getStartActions = (epicConfig: Record<string, IEpicConfig>) => extractActionsFromConfig('start', epicConfig)

export const getRecieveActions = (epicConfig: Record<string, IEpicConfig>) => [...extractActionsFromConfig('recieve', epicConfig), EUserActionTypes.CHECKED_AUTH];

export const createStartLoadingEpic = (epicConfig): Epic => (action$: any, state$: StateObservable<any>): ActionsObservable<Action> => 
  action$.pipe(
    filter((action: IAction) => getStartActions(epicConfig).includes(action.type)),
    map((act: any) => startLoading(act.loader)
    )
  )

export const createStopLoadingEpic = (epicConfig): Epic => (action$: any, state$: StateObservable<any>): ActionsObservable<Action> => 
  action$.pipe(
    filter((action: IAction) => getRecieveActions(epicConfig).includes(action.type) ),
    map((act: any) => stopLoading(act.loader)
    )
)

export const createLoadingEpics = (epicConfig) => {
  return [createStartLoadingEpic(epicConfig), createStopLoadingEpic(epicConfig)]
}