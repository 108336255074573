import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import {  withStyles } from '@material-ui/core';

import { IAppState, EContainerNames } from 'interfaces';
import { EButtonTypes } from 'shared/interfaces'

import { Logo, MsgPanel } from 'components';
import { MButton, MTextField, Loader, Modal } from 'shared/components';
import { login, checkAuth } from 'shared/auth/actions'
import { IAuthUser } from 'shared/auth/interfaces';

import { UserIcon, PasswordIcon } from 'assets';
import { loginStyle } from './styles';
import { CommonMsg } from 'config/uiKit';
import { isDataValid } from 'utils';
import { hideError } from 'shared/error';

class Login extends Component<any> {

  state: any = {
    login: {
      username: '',
      password: '',
    },
    errorDisplayed: false,
    formModal: {}
  }

  componentDidUpdate = () => {
    // console.log(this.state.errorDisplayed, this.props.error)
    if (!this.state.errorDisplayed && this.props.error) this.showError(this.props.error.status);
    if (this.props.authenticated) this.props.history.push('/');
  }

  onLogin = (closeFormModal, openFormModal) => {
      if (!this.state.formModal.openFormModal) this.setState({ formModal: { closeFormModal, openFormModal } });
      if (!isDataValid(this.state.login).value) return openFormModal(CommonMsg.fillFields);
      this.setState({ errorDisplayed: false });
      this.props.login(this.state.login);
    }

  onChange = (id: string) => (e: Event) => {
    const target = e.target as HTMLInputElement;
    this.setState({ login: { ...this.state.login, [id]: target.value } });
  }

  showError = status => {
    this.setState({ errorDisplayed: true });
    this.showMessage(status === 401 ? CommonMsg.status401 : CommonMsg.serverError);
  }

  showMessage = msg => {
    this.state.formModal.openFormModal(<MsgPanel message={msg} closeAction={this.closeMessage} />)();
    return null;
  }

  closeMessage = () => {
    this.props.clearError();
    this.state.formModal.closeFormModal();
  }

  render(): ReactElement | null {
    const { classes, checkedAuth, loading } = this.props;
    if (!checkedAuth) return null
    return (
      <div className={classes.loginBg}>
        {!!loading && <Loader />}
        <Modal render={({ handleClose, handleOpen }) => (
          <form onSubmit={(e) => {e.preventDefault(); this.onLogin(handleClose, handleOpen)}} className={classes.container}>
            <Logo width="340px"/>
            <MTextField
              required
              id="username"
              label="Username"
              onChange={this.onChange("username")}
              value={this.state.username}
              icon={<UserIcon />}
              className={classes.textField}
              fullWidth
            />
            <MTextField
              required
              password="true"
              id="password"
              label="Password"
              onChange={this.onChange("password")}
              type='password'
              value={this.state.password}
              icon={<PasswordIcon />}
              className={classes.textField}
              fullWidth
            />
            <MButton 
              type={EButtonTypes.BASIC} 
              buttonType='submit'
                            onClick={() => this.onLogin(handleClose, handleOpen)}
              className={classes.loginButton}
              width="340px"
            > 
              Login 
            </MButton>
            {/* <Grid container spacing={0} className={classes.extraButtons}>
              <Grid item xs={6}>
                <Link to={ ERoutesName.LOGIN }> Forgot </Link>
              </Grid>
              <Grid item xs={6}>
                <Link to={ ERoutesName.LOGIN }> Help </Link>
              </Grid>
            </Grid> */}
          </form>
        )} />
      </div>
    );
  }
};

const mstp = (state: IAppState) => ({
  loading: state[EContainerNames.LOADER].global,
  authenticated: state[EContainerNames.AUTH].authenticated,
  checkedAuth: state[EContainerNames.AUTH].checkedAuth,
  error: state[EContainerNames.ERROR].error,
})

const mdtp = (dispatch: any) => ({
  login: (payload: IAuthUser) => dispatch(login(payload)),
  checkAuth: () => dispatch(checkAuth()),
  clearError: () => dispatch(hideError())
})

const styledComponent = withStyles(loginStyle)(Login);
export default connect(mstp, mdtp)(styledComponent);
