import React, { useState } from "react"
import { connect } from 'react-redux'
import { Avatar } from "@material-ui/core"
import { Close } from "@material-ui/icons"

import { MButton } from "shared/components"
import { EButtonTypes } from "shared/interfaces"
import { EContainerNames, ELoaderTypes } from 'interfaces';
import { EBasicActionTypes } from 'shared/containers'

import { getDispatchAction } from 'store';
import { NewFolderStyle, ErrorStyled, DeleteUserImage, DeleteUserForm } from "./styles"

import { toogleMessage } from "store/selectionContainer"

const DeleteUser = (props) => {
  const { closeAction, user, deleteUser } = props;
  const [errorMessage] = useState('');

  const submitHandler = () => {
    deleteUser({ id: user.id })
    closeAction();
  }

  return (
    <NewFolderStyle>
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <DeleteUserForm>
        <h1>Delete Team Member?</h1>
        <DeleteUserImage src={user.profile_picture_url} />
        <h2>{user.name}</h2>
        <h3>{user.job_title}</h3>
        <h4>{user.department}</h4>
        <MButton
          type={EButtonTypes.BASIC}
          onClick={submitHandler}
          className="delete-button"
        >
          Delete Team Member
          </MButton>
        <MButton
          type={EButtonTypes.BASIC}
          onClick={closeAction}
          className="cancel-button"
        >
          Cancel
          </MButton>
        {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>}
      </DeleteUserForm>
    </NewFolderStyle>
  )
}

const mstp = (state) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_GROUP]
})

const mdtp = (dispatch) => ({
  deleteUser: getDispatchAction({ dispatch, container: EContainerNames.USERS, actionType: EBasicActionTypes.DELETE }),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default connect(mstp, mdtp)(DeleteUser);