import React from 'react';
import { withStyles } from '@material-ui/core';
import { Text, View, Page } from "@react-pdf/renderer";

import { Page as StyledPage, SmallHeader, Content } from '../styles';
import { personaPanelStyle } from './styles';
import { colors } from 'config/uiKit';


import Profile from './profile';
import About from './about';
import Personality from './personality';

const PersonaPDF = ({ persona, apps }: any) => {

  return (
    <Page wrap={false} orientation='landscape'>
      <StyledPage height='100%' width='100%'>
        <PersonaStyledView
          apps={apps}
          persona={persona}
        />
      </StyledPage>
    </Page>
  )
}


const PersonaView = ({
  apps,
  persona,
  classes,
}: any) => {
  return (

    <Content>
      <SmallHeader
        brand={persona.brand}
      >
        <Text>{persona.name || 'Persona'} | {persona.brand} </Text>
      </SmallHeader>
      <View style={{
        backgroundColor: 'white', display: 'flex',
        flexDirection: 'row',
        height: `calc(100% - 74px)`,
      }}>
        <Profile
          color={colors.brands[persona.brand]}
          persona={persona}
        />
        <About
          color={colors.brands[persona.brand]}
          persona={persona}
        />
        <Personality
          color={colors.brands[persona.brand]}
          persona={persona}
          apps={apps}
        />
      </View>
    </Content>

  )
}

export const PersonaStyledView = withStyles(personaPanelStyle)(PersonaView);



export default PersonaPDF;

