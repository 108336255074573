
import { EContainerNames, ERoutesName, ELoaderTypes } from 'interfaces';

import { ESelectorActionTypes } from 'store/selectionContainer';
import { EBasicActionTypes, IContainerConfig } from 'shared/containers';
import { EUserActionTypes } from 'shared/auth';
import { findAndRemoveById, formMapper, addFolderToGroup, findAndUpdateById } from 'shared/utils';
import { covertObjToQueryString } from 'utils'

/*
  options: 
    startActions: Use this if you want to add additional actions to trigger the start of the epic
    alternateAPI: Changing the endpoint in case it doesn't match the container's endpoint
    actionSuffix: Necessary identifier for multiple of the same Basic Action
    urlModifier: Modifies the url, params are { url, action } 
    redirect: when you want to redirect after a succcesful call
    requestBodyMapper: Maps what the outgoing request body looks like. 
      - formMapper is used for when the body has arrays or a file, it creates Form Data with the payload
    loader: What loader type, use this to specify loader for specific component
    getterMethod: Allows for reducer method for when you send the action. MUST RETURN STATE { ...state }
    setterMethod: Allows for a different reducer setter method then the default. MUST RETURN STATE { ...state }
*/

const config: Array<IContainerConfig> = [
  {
    name: EContainerNames.PERSONAS,
    cyclesConfig: [
      {
        type: EBasicActionTypes.DETAIL,
        loader: ELoaderTypes.DETAIL_PERSONA,
        options: {
          alternateAPI: 'personas/:id',
          urlModifier: ({ url, action }) => url.replace(':id', action.payload.id),
        }
      },
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS, ESelectorActionTypes.SET_ACTIVE_FILTERS, ESelectorActionTypes.CLEAR_ALL_FILTERS],
          urlModifier: ({ action, url }) => action.payload && action.payload.filters ?
            `${url}/?${covertObjToQueryString(action.payload.filters)}`
            : url
          ,
          setterMethod: (state, payload) => ({
            ...state,
            list: addPersonasToState(state, payload),
            activeBrands: !!payload.brand_id
          }),
        },
      },
      {
        type: EBasicActionTypes.CREATE,
        loader: ELoaderTypes.CREATE_PERSONA,
        options: {
          requestBodyMapper: formMapper
        }
      },
      {
        type: EBasicActionTypes.UPDATE,
        loader: ELoaderTypes.CREATE_PERSONA,
        options: {
          alternateAPI: 'personas/:id',
          urlModifier: ({ url, action }) => url.replace(':id', action.payload.id),
          requestBodyMapper: formMapper,
          setterMethod: (state, payload) => ({
            ...state,
            detail: payload,
            list: findAndUpdateById(state.list, payload)
          }),
        }
      },
    ]
  },
  {
    name: EContainerNames.BRANDS,
    cyclesConfig: [
      { type: EBasicActionTypes.DETAIL },
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS]
        }
      },
    ]
  },
  {
    name: EContainerNames.APPS,
    cyclesConfig: [
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS]
        }
      },
    ]
  },
  {
    name: EContainerNames.GROUPS,
    cyclesConfig: [
      {
        type: EBasicActionTypes.DETAIL,
        options: {
          alternateAPI: 'groups/folders/:folder_id',
          urlModifier: ({ url, action }) => url.replace(':folder_id', action.payload.folderId),
          startActions: [ESelectorActionTypes.SET_BOTH, ESelectorActionTypes.SET_FOLDER],

        }
      },
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS]
        }
      },
      {
        type: EBasicActionTypes.DELETE,
        options: {
          getterMethod: (state, payload) => ({
            ...state,
            list: findAndRemoveById({ list: state.list, id: payload.id })
          }),
        }
      },
      {
        type: EBasicActionTypes.CREATE,
        // loader: ELoaderTypes.CREATE_GROUP,
        options: {
          redirect: (response) => ERoutesName.HOME + `?groupId=${response.id}&folderId=${response.folders[0].id}`,
          requestBodyMapper: formMapper,
          setterMethod: (state, payload) => ({ ...state, list: state.list.concat(payload) }),

        }
      },
      {
        type: EBasicActionTypes.CREATE,
        actionSuffix: 'FOLDER',
        // loader: ELoaderTypes.CREATE_GROUP,
        options: {
          redirect: (response) => ERoutesName.HOME + `?groupId=${response.group_id}&folderId=${response.id}`,
          alternateAPI: 'groups/folders',
          setterMethod: (state, payload) => ({
            ...state,
            detail: payload, list: addFolderToGroup({ groups: state.list, id: payload.group_id, folder: payload })
          }),

        }
      },
      {
        type: EBasicActionTypes.DELETE,
        actionSuffix: 'FOLDER',
        options: {
          alternateAPI: 'groups/folders',
          requestBodyMapper: (payload) => payload,
          getterMethod: (state, payload) => ({
            ...state,
            list: state.list.map(group => ({
              ...group,
              folders: findAndRemoveById({ list: group.folders, id: payload.folder_id })
            }))
          }),
          setterMethod: (state, payload) => ({ ...state }),
        }
      },
      {
        type: EBasicActionTypes.CREATE,
        actionSuffix: 'FOLDER_PERSONA',
        loader: ELoaderTypes.TOGGLE_PERSONA_TO_FOLDER,
        options: {
          alternateAPI: 'groups/folders/add_persona',
          requestBodyMapper: (payload) => payload,
          getterMethod: (state, payload) => ({
            ...state,
            detail: {
              ...state.detail,
              personas: state.detail.personas.concat(payload.persona)
            }
          }),
          setterMethod: (state, payload) => ({ ...state }),
        }
      },
      {
        type: EBasicActionTypes.DELETE,
        actionSuffix: 'FOLDER_PERSONA',
        loader: ELoaderTypes.TOGGLE_PERSONA_TO_FOLDER,
        options: {
          alternateAPI: 'groups/folders/remove_persona',
          getterMethod: (state, payload) => ({
            ...state,
            detail: {
              ...state.detail,
              personas: findAndRemoveById({ list: state.detail.personas, id: payload.persona_id })
            }
          }),
          setterMethod: (state, payload) => ({ ...state }),
        }
      }
    ]
  },
  {
    name: EContainerNames.FILTERS,
    cyclesConfig: [
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS]
        }
      },
    ]
  },
  {
    name: EContainerNames.USERS,
    cyclesConfig: [
      {
        type: EBasicActionTypes.LIST,
        options: {
          startActions: [EUserActionTypes.LOGIN_SUCCESS]
        }
      },
      {
        type: EBasicActionTypes.CREATE,
        options: {
          requestBodyMapper: formMapper
        }
      },
      {
        type: EBasicActionTypes.DELETE,
        options: {
          alternateAPI: 'users/:id',
          urlModifier: ({ url, action }) => url.replace(':id', action.payload.id),
          getterMethod: (state, payload) => ({
            ...state,
            list: findAndRemoveById({ list: state.list, id: payload.id })
          }),
        }
      },
      {
        type: EBasicActionTypes.UPDATE,
        options: {
          requestBodyMapper: formMapper,
          setterMethod: (state, payload) => ({
            ...state,
            list: findAndUpdateById(state.list, payload)
          }),
        }
      },
    ]
  },

];

const addPersonasToState = (state, payload) => {
  if (!state.activeBrands) {
    return payload.personas;
  } else {
    let newList = []
    if (payload.brand_id) {
      // remove personas from previous state that belonged to brand that was searched
      newList = state.list.filter(p => p.brand_id !== parseInt(payload.brand_id))
    }
    console.log(payload, newList)
    return [...payload.personas, ...newList]
  }

}


export default config;