import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './uiKit';

// A custom theme for this app
export default createMuiTheme({
  palette: {
    primary: {
      main: colors.greenScale.light,
      contrastText: '#fff',
    },
    secondary: {
      main: colors.greenScale.light,
      contrastText: '#fff',
    },
    error: {
      main: colors.greenScale.light,
    },
    background: {
      default: colors.grayScale.light,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif'
    ].join(','),
  }
});
