import {IActionConfig, ICompleteContainer, ICycleConfig } from './interfaces';
import { createEpicConfig, createNamedActions, createReducer } from './'
import { initialStateVariables } from './reducers';


const ApplicationGenerator = ({ containerConfig, api }) => {
  let reducers = {}
  let epicConfigs = [];
  let exportedActions = {};

  containerConfig.forEach(container => {
    const config = containerConfigGenerator({ container, api });

    reducers[container.name] = createReducer(config.reducer);

    exportedActions[container.name] = config.exportedActions;

    epicConfigs = epicConfigs.concat(config.epic);
  })

  return { reducers, exportedActions, epicConfigs };


}


const containerConfigGenerator:
  ({ container: IContainerConfig, api }) => ICompleteContainer =
  ({
    container,
    api,
  }) => {

    let storeConfig: ICompleteContainer = {
      reducer: {
        storeActions: {},
        initialState: {}
      },
      exportedActions: {},
      epic: []
    }

    const { name } = container;

    let allNamedActions = createNamedActions({ containerName: name })

    container.cyclesConfig.forEach(({ type, loader, actionSuffix = null, options = {} }: ICycleConfig) => {

      let action: IActionConfig = allNamedActions[type];

      action = { ...action } //so that it doesn't mutate;

      if (actionSuffix) {
        action.getterName = action.getterName + '_' + actionSuffix;
        action.setterName = action.setterName + '_' + actionSuffix;
      }

      storeConfig.reducer.storeActions[action.getterName] = options.getterMethod || action.getterMethod;
      storeConfig.reducer.storeActions[action.setterName] = options.setterMethod || action.setterMethod;

      let actionKey = actionSuffix ? `${type}_${actionSuffix}` : type
      storeConfig.exportedActions[actionKey] = { name: action.getterName, loader } ;

      const actionInitialState = initialStateVariables[type];
      if (actionInitialState) {
        storeConfig.reducer.initialState[actionInitialState.name] = actionInitialState.initialVar;
      }

      storeConfig.epic.push(
        createEpicConfig({ containerName: name, ...action, type, api, options, loader })
      )
    })

    return storeConfig;
  }

export default ApplicationGenerator;

