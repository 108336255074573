import styled from "styled-components";
import { EBrandIDs } from 'interfaces';
export const colors = {
  grayScale: {
    light: '#F6F7FB',
    medium: '#B3BCCB',
    dark: '#797990',
    content: '#EEF2FA',
    profile: '#E8E8E8',
    text: '#333333',
    ultraDark: '#565658',
    headerButtons: '#525252',
  },
  redScale: {
    medium: '#DE001A'
  },
  blueScale: {
    light: '#0093EE',
    medium: '#005AEF',
    dark: '#003388',
    buttomMenu: '#0053D0',
    divider: '#184B9F',
    slider: '#6E78B1',
    dashboardButtons: '#1643AA',
    loginTextField: '#0b65d0',
    disabled: '#2F589E'
  },
  greenScale: {
    light: '#00DE9E',
    buttonMenuText: '#00FFB5'
  },
  font: {
    dark: '#525252',
    light: '#FAFBFC'
  },
  brands: {
    'Tracfone': '#010F9E',
    [EBrandIDs.TRACFONE]: '#010F9E',
    'Straight Talk': '#CCDD00',
    [EBrandIDs.STRAIGHT_TALK]: '#CCDD00',
    'Total Wireless': '#00AE9B',
    [EBrandIDs.TOTAL_WIRELESS]: '#00AE9B',
    'Net10': '#03AEEF',
    'NET10': '#03AEEF',
    [EBrandIDs.NET10]: '#03AEEF',
    'SIMPLE Mobile': '#8CC73F',
    [EBrandIDs.SIMPLE_MOBILE]: '#8CC73F',
    'Page Plus': '#E7002E',
    [EBrandIDs.PAGE_PLUS]: '#E7002E',
    'SafeLink': '#F2B334',
    [EBrandIDs.SAFELINK]: '#F2B334',
    'GoSmart': '#C3D941',
    [EBrandIDs.GOSMART]: '#C3D941',
    'Family Mobile': '#027DC2',
    [EBrandIDs.FAMILY_MOBILE]: '#027DC2'
  }
}


export const assetSources = {
  brands: {
    'Tracfone': "/assets/Tracfone.png",
    [EBrandIDs.TRACFONE]: "/assets/Tracfone.png",
    'Straight Talk': "/assets/StraightTalk.png",
    [EBrandIDs.STRAIGHT_TALK]: "/assets/StraightTalk.png",
    'Total Wireless': "/assets/TotalWireless.png",
    [EBrandIDs.TOTAL_WIRELESS]: "/assets/TotalWireless.png",
    'Net10': "/assets/Net10.png",
    [EBrandIDs.NET10]: "/assets/Net10.png",
    'SIMPLE Mobile': "/assets/SimpleMobile.png",
    [EBrandIDs.SIMPLE_MOBILE]: "/assets/SimpleMobile.png",
    'Page Plus': "/assets/PagePlus.png",
    [EBrandIDs.PAGE_PLUS]: "/assets/PagePlus.png",
    'SafeLink': "/assets/SafeLink.png",
    [EBrandIDs.SAFELINK]: "/assets/SafeLink.png",
    'GoSmart': "/assets/GoSmart.png",
    [EBrandIDs.GOSMART]: "/assets/GoSmart.png",
    'Family Mobile': "/assets/WFM.png",
    [EBrandIDs.FAMILY_MOBILE]: "/assets/WFM.png"
  }
}
export const containers = {
  headerHeight: 88,
  containerPadding: 24,
  borderRadius: 27,
  smallHeaderHeight: 80,
}

const pd = containers.containerPadding;
const hh = containers.headerHeight;

export const WideContent = styled.div<any>`
  position: absolute;
  margin ${pd * 2 + hh + 10}px ${pd + 20}px ${pd + 10}px ${pd + 20}px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow: hidden;
`

export const CommonMsg = {
  fillFields: 'Please fill all fields',
  status401: 'User or Password not valid',
  serverError: 'Something happened, please try again later'
}