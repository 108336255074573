import React from 'react';
import { View, Page, Text} from '@react-pdf/renderer';
import * as Styled from './styles';

import { Page as StyledPage, Content, SmallHeader } from '../styles'; 


const Brand = ({ brand }) => {
  return (
    <Page wrap={false} orientation='landscape'>
      <StyledPage height='100%' width='100%'>
        <Content id={`brand_${brand.brand_id}`}>
          <SmallHeader brand={brand.brand}>
            <Text>{brand.brand} | Brand Voice</Text> 
          </SmallHeader>
          <Styled.MainContainer>
            <Styled.FlexContainer>
              <Styled.InfoContainer width="34%">
                <Styled.Heading>Personality</Styled.Heading>
                <View>{brand.personality.map(v => <Styled.ListItem key={v}>• {v}</Styled.ListItem>)}</View>
              </Styled.InfoContainer>
              <Styled.InfoContainer width="66%">
                <Styled.Heading>General Guidelines</Styled.Heading>
                <View>{brand.general_guidelines.map(v => <Styled.ListItem key={v}>• {v}</Styled.ListItem>)}</View>
              </Styled.InfoContainer>
            </Styled.FlexContainer>
            <Styled.FlexContainer>
              <Styled.InfoContainer width="34%">
                <Styled.Heading>Tone</Styled.Heading>
                <View>{brand.tone.map(v => <Styled.ListItem key={v}>• {v}</Styled.ListItem>)}</View>
              </Styled.InfoContainer>
              <Styled.InfoContainer width="66%">
                <Styled.Heading>Writing Tips</Styled.Heading>
                <View>{brand.writing_tips.map(v => <Styled.ListItem key={v}>• {v}</Styled.ListItem>)}</View>
              </Styled.InfoContainer>
            </Styled.FlexContainer>
          </Styled.MainContainer>
        </Content>
      </StyledPage>
    </Page>

  )
}




export default Brand;