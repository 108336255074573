import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Router } from 'router';
import { routes } from 'config';
import { Loader } from 'shared/components';
import { IAppState, EContainerNames } from 'interfaces';
import { login, checkAuth } from 'shared/auth'
import { IAuthUser } from 'shared/auth/interfaces';

class App extends Component<any> {
  componentDidMount() {
    if (!this.props.checkedAuth) this.props.checkAuth();
  }

  render() {
    const {  checkedAuth } = this.props;
    if (!checkedAuth) return <Loader />;

    return <Router routes={ routes } />
  }
}

const mstp = (state: IAppState) => ({
  loading: state[EContainerNames.LOADER].global,
  authenticated: state[EContainerNames.AUTH].authenticated,
  checkedAuth: state[EContainerNames.AUTH].checkedAuth
})

const mdtp = (dispatch: any) => ({
  login: (payload: IAuthUser) => dispatch(login(payload)),
  checkAuth: () => dispatch(checkAuth())
})

// const styledComponent = withStyles(loginStyle)(App);
export default connect(mstp, mdtp)(App);
