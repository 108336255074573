import styled from 'styled-components';
import { colors, containers } from 'config/uiKit'
import { createRadius } from 'utils';
import { HelpOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/core';

export const SideMenuStyled = styled.div<any>`
  position: relative;
  width: 20%;
  min-width: 250px;
  max-width: 300px;
  height: ${props => props.fullMenu ? '100%' : `${containers.headerHeight}px`};
  padding: ${props => props.fullMenu ? '34px 0 30px 0' : '24px 0'};
  ${props => props.color ? 
    `background-color: ${props.color};` :
    `background-image: linear-gradient(180deg, ${colors.blueScale.medium}, ${colors.blueScale.light});`
  }
  border-radius: ${containers.borderRadius}px;
  z-index: 5;
  overflow: auto;
`
//TO DO: Make Dynamic
const openMenuWidth = 340;

export const OpenMenuStyled = styled.div`
  position: absolute;
  right: ${Math.ceil(openMenuWidth * -.9)}px;
  top: 0;
  z-index: 3;
  height: 100%;
  font-weight: 200;
  color: white;
  font-size: 16px;
  pointer-events: none;
  #menu-transition { 
    pointer-events: all;
    transition: transform .4s cubic-bezier(0, .5, 0, 1);
    height: 100%;
    width: ${openMenuWidth}px;
    padding: 13px 45px 0 75px;
    border-radius: ${containers.borderRadius}px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: ${colors.blueScale.dark};
  }
  .show {
    transform: scale(1, 1) translate(0px, 0);
    scrollbar-width: none;
  }
  .hide {
    height: 100%;
    transform: scale(.7, .95) translate(-370px, 0);
  }
  .title {
    font-size: 20px;
    text-align: center;
    margin: 0 0 -14px 0;
    font-weight: 400;
  }
  
  .MuiDivider-root {
    width: 100%;
    background-color: ${colors.blueScale.divider};
    margin: 22px 0;
  }
  .button {
    margin-top: 32px;
    background-color: ${colors.greenScale.light};
    border: none;
    color: ${colors.blueScale.dark};
    margin-bottom: 30px;
  }
  .button:hover {
    background-color: white;
  }
  .closeButton {
    position: fixed;
    right: 14px;
    top: 14px;
    color: ${colors.blueScale.light};
    width: 26px;
    height: 26px;
    border: 1px solid ${colors.blueScale.light};
    border-radius: 14px;
    padding: 2px 4px;
    cursor: pointer;
    svg {
      width: 16px;
    }
  }
  .closeButton:hover {
    color: ${colors.blueScale.medium};
    border-color: ${colors.blueScale.medium};
  }
`

export const PersonalityGridContainer = styled.div`
display: grid;
grid-template-columns: auto auto auto auto;
margin: -3px;
font-size: 13px;
.grid-item {
  border: 1px solid ${colors.grayScale.dark};
  padding: 3px 7px;
  margin: 4px 2px;
  text-align: center;
  border-radius: 6px;
}
.selected {
  background-color: rgba(255, 255, 255, 0.8);
}
`

export const PersonalityGridItem = styled.div<any>`
  border: 1px solid ${colors.grayScale.dark};
  padding: 3px 7px;
  margin: 4px 2px;
  text-align: center;
  border-radius: 6px;
  font-weight: 400;
  cursor: pointer;
  ${({ selected }) => selected && `
    border-color: ${colors.blueScale.dark};
    background-color: ${colors.greenScale.light};
    color: ${colors.blueScale.dark};
  `}
`

export const FilterSubTitle = styled.div`
  margin-bottom: 6px;
  &.icon-included {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const MenuContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: relative;
  height: ${props => props.fullMenu ? '100%' : `${containers.headerHeight}px`};
`

export const MenuItemStyled = styled.div<any>`
  // width: 80%;
  // text-align: center;
  color: white;
  border-radius: ${containers.borderRadius}px;
  padding: 10px 0;
  height: 46px;
  font-size: 18px;
  font-weight: 200;
  // cursor: pointer;
  margin: 10px 20px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  background-color: ${({ active }) => active && colors.blueScale.buttomMenu};
  color: ${({ active }) => active && colors.greenScale.buttonMenuText};
  ${({ selected }) => selected && `
    margin: -34px 0;
    z-index: 0;
    height: 114px;
    padding: 44px 20px;
    width: 100%;
    border-radius: 0;
    background-color: ${colors.blueScale.dark};
    clip-path: polygon(${createRadius(openMenuWidth, 114, 32)});
  `}
  // ${({ selected }) => !selected && `
  //   &:hover {
  //     background-color: ${colors.blueScale.buttomMenu};
  //     color: ${colors.greenScale.buttonMenuText};
  //   }
  // `}
`

export const AccountMenuItemStyled = styled.div<any>`
  width: 80%;
  text-align: center;
  color: white;
  border-radius: ${containers.borderRadius}px;
  padding: 10px 0;
  height: 46px;
  font-size: 18px;
  font-weight: 200;
  cursor: pointer;
  margin: 24px auto;
  ${({ selected }) => selected && `
    background-color: ${colors.blueScale.dashboardButtons};
  `}
  ${({ selected }) => !selected && `
    &:hover {
      background-color: ${colors.blueScale.dashboardButtons};
    }
  `}
`

export const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ButtonStyle = {
  display: 'flex' as 'flex',
  flexDirection: 'row' as 'row', 
  justifyContent: 'space-between',
  backgroundColor: 'transparent',
  color: 'white',
  borderColor: 'white',
  width: '75%'
}

export const IconStyle = {
  width: 40, 
  height: 40, 
  marginLeft: 10, 
  position: 'absolute' as 'absolute',
  top: 1,
  right: 2
}

export const LogoutIconStyle = {
  position: 'absolute' as 'absolute',
  border: '1px solid white',
  backgroundColor: colors.blueScale.dark,
  padding: 6,
  width: 30,
  height: 30,
  top: 6,
  right: 7
}

export const SwitchContainer = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`
export const SwitchLabel = styled.div`
  font-size:14px;
`

export const HelpIcon = withStyles({
  root:{
    fill:'#77829A',
    fontSize:'1.3rem',
    cursor:'pointer'
  }
})(HelpOutline)

export const MenuItemText = styled.div<any>`
  width: 75%;
  height:100%;
  border-radius: ${containers.borderRadius}px;
  text-align: center;
  cursor: pointer;
  ${({ active,selected }) => active && !selected && `
    background-color: ${colors.blueScale.buttomMenu};
    color: ${colors.greenScale.buttonMenuText};
  `}
  ${({ selected }) => !selected && `
    &:hover {
      background-color: ${colors.blueScale.buttomMenu};
      color: ${colors.greenScale.buttonMenuText};
    }
  `}
`

export const FilterButtonGroup = styled.div<any>`
  display:grid;
  grid-template-columns: auto auto auto auto;
  .buttonStyle {
    border: 1px solid ${colors.grayScale.dark};
    padding: 3px 7px;
    margin: 4px 2px;
    text-align: center;
    border-radius: 6px;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 12px;
    &.activeFilter {
      border-color: ${colors.blueScale.dark};
      background-color: ${colors.greenScale.light};
      color: ${colors.blueScale.dark};
    }
    &.disabled {
      border-color: ${colors.blueScale.disabled};
      color: ${colors.blueScale.disabled};
      cursor: default;
    }
  }
`