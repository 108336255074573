import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Avatar, Paper, InputBase, Menu, MenuItem, CircularProgress } from '@material-ui/core';
import { ProfileStyled, ProfileAditionalIcon, TextFieldStyled, ProfileMediaStyled } from './styles';
import { VolumeUp, AccountBoxOutlined, CheckCircleOutline, ExpandMoreOutlined, PlayArrow, Pause, MenuOutlined, Add, Check } from '@material-ui/icons';
import { menuItems } from 'config';
import { EGender, EPersonalityTypes, ERoutesName,ECustomerType } from 'interfaces';
import { formatTime, round } from 'utils';

export default ({ changeInfo, editing, color, persona, addPersona, isPersonaInFolder, brand, hideFunctionButs = false, ...props }) => {
  const [audioSel, setAudioSel] = useState(false);
  const changeSelection = () => setAudioSel(!audioSel);
  const history = useHistory();

  const goBrand = () => history.push({
    pathname: ERoutesName.DASHBOARD_BRAND_VOICE.replace(':brand_id', brand.id),
    state: { from: history.location.pathname }
  });

  return (
    <ProfileStyled color={color}>
      <div className={editing ? 'profileEditing' : 'profileShow'}>
        {editing && <ProfileList placeholder={brand.name || "Brand:"} onChange={changeInfo('brand_id')} list={menuItems} />}
        {!audioSel ?
          <ProfileImage
            image={persona.profile_picture_url}
            changeInfo={changeInfo('profile_picture_url')}
            editing={editing}
          /> :
          <ProfileAudio
            audio={persona.audio_sample_url}
            changeInfo={changeInfo('audio_sample_url')}
            editing={editing}
            color={color}
          />
        }
        <div className="profileAditional">
          {(editing || persona.audio_sample_url) && 
            <ProfileIcon icon={<VolumeUp />} onClick={changeSelection} selected={audioSel} />}
          {!hideFunctionButs && !editing && <>
            <ProfileIcon icon={isPersonaInFolder ? <Check /> : <Add />} onClick={addPersona} />
            <ProfileIcon icon={<MenuOutlined />} onClick={goBrand} />
          </>}
        </div>
        {editing ? <>
          <ProfileInputField placeholder="First Name:" value={persona.name || ''} onChange={changeInfo('name')} />
          <ProfileInputField placeholder="Age:" value={persona.age || ''} onChange={changeInfo('age')} /> 
          <ProfileList placeholder={persona.gender || "Gender:"} onChange={changeInfo('gender')} list={Object.keys(EGender)} />
          <ProfileInputField placeholder="Occupation:" value={persona.occupation || ''} onChange={changeInfo('occupation')} />
          <ProfileInputField placeholder="Income:" value={persona.income || ''} onChange={changeInfo('income')} />
          <ProfileList placeholder={persona.personality_type || "Personality Type:"} onChange={changeInfo('personality_type')} list={Object.keys(EPersonalityTypes)} />
          <ProfileInputField placeholder="Contract Length:" value={persona.contract_length || ''} onChange={changeInfo('contract_length')} />
          <ProfileList placeholder={persona.customer_type || "Personality Type:"} onChange={changeInfo('customer_type')} list={Object.keys(ECustomerType)} />
        </> : <>
          <h1>{persona.name}</h1>
          <h2>Age: {persona.age}</h2>
          <h2>Gender: {persona.gender}</h2>
          <h2>Occupation: {persona.occupation}</h2>
          <h2>Income: {Math.floor(persona.income)}k</h2>
          <h2>Personality Type: {persona.personality_type}</h2>
          <h2>Brand: {brand.name}</h2>
          <h2>Contract Length: {persona.contract_length}y</h2>
        </>}
      </div> 
    </ProfileStyled>
  )
}

const ProfileImage = ({ image, changeInfo, editing }) => {
  let imageSelector;
  const openSelector = () => imageSelector.click();
  const selectImage = event => {
    if (event.target?.files?.length) changeInfo(null, URL.createObjectURL(event.target.files[0]));
  }
  return (
    <ProfileMediaStyled
      image={image}
      onClick={editing ? openSelector : () => {}}
      editing={editing}
    >
      <input
        ref={ref => imageSelector = ref}
        type="file"
        onChange={selectImage}
        style={{ display: 'none' }}
        accept="image/*"
      />
      {!image && <>
        <div className="profileImageIcon">
          <AccountBoxOutlined />
        </div>
        <div className="uploadText">
          Upload Persona Image
        </div>
      </>}
    </ProfileMediaStyled>
  )
}

const ProfileAudio = ({ audio, changeInfo, editing, color }) => {
  const [playing, setPlaying] = useState(false);
  const playingRef = useRef(playing);
  const [timePos, setTimePos] = useState({time: 0, pos: 0});
  let audioSelector;
  const delay = 200;
  const openSelector = () => audioSelector.click();
  const selectAudio = event => {
    if (event.target?.files?.length) changeInfo(null, URL.createObjectURL(event.target.files[0]));
  }
  const playAudio = () => {
    const player: any = document.getElementById('audio');
    if (!playing) player.play();
    else player.pause();
    playingRef.current = !playing;
    if (!playing) setTimeout(audioProgress, delay);
    setPlaying(!playing);
  }
  const audioProgress = () => {
    const player: any = document.getElementById('audio');
    if (!player) return;
    setTimePos({ time: Math.round(player.currentTime), pos: round(player.currentTime / player.duration * 100, 1) });
    if (playingRef.current) setTimeout(audioProgress, delay);
  }
  return (
    <ProfileMediaStyled
      onClick={editing ? openSelector : playAudio}
      editing={editing}
    >
      {editing ? <>
        <input
          ref={ref => audioSelector = ref}
          type="file"
          onChange={selectAudio}
          style={{ display: 'none' }}
          accept="audio/*"
        />
        {audio && <div className="audioUploaded">
          <CheckCircleOutline />
        </div>}
        <div className="profileImageIcon">
          <VolumeUp />
        </div>
        <div className="uploadText">
          {audio ? 'Audio Uploaded' : 'Upload Persona Audio'}
        </div>
      </> : <>
        <audio id="audio">
          <source src={audio} type="audio/mpeg" />
        </audio>
        <Avatar className="avatarPlay">
          <div className="profileImageIcon">
            {!playing ? <PlayArrow /> : <Pause />}
          </div>
        </Avatar>
        <CircularProgress 
          variant="static" 
          value={100} 
          className="progress" 
          style={{width: 160, height: 160, color: 'black'}} 
        />
        <CircularProgress 
          variant="static" 
          value={timePos.pos} 
          className="progress" 
          style={{width: 160, height: 160, color}} 
        />
        <div className="time">{formatTime(timePos.time)}</div>
      </>}
    </ProfileMediaStyled>
  )
}

const ProfileIcon = ({ icon, ...props }) => {
  return (
    <ProfileAditionalIcon {...props}>
      <Avatar className="avatarContainer">
        <Avatar className="avatarOutlined">
          {icon}
        </Avatar>
      </Avatar>
    </ProfileAditionalIcon>
  )
}

const ProfileList = ({ list, placeholder, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = TextFieldStyled();
  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const selItem = id => () => {
    onChange(null, id);
    closeMenu();
  }
  return (
    <>
      <Paper className={`${classes.root} ${classes.list}`} elevation={0} onClick={openMenu}>
        <div className={classes.input}>{placeholder}</div>
        <div className={classes.icon}><ExpandMoreOutlined /></div>
      </Paper>
      <Menu
        id={placeholder}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
      >
        {list.map(e => 
          <MenuItem key={e.id || e} onClick={selItem(e.id || e)}>
            {e.label || e}
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const ProfileInputField = (props) => {
  const classes = TextFieldStyled();
  return (
    <Paper className={classes.root} elevation={0}>
      <InputBase
        className={classes.input}
        {...props}
      />
    </Paper>
  )
}