import React, { useState } from "react"
import { connect } from 'react-redux'
import { Avatar } from "@material-ui/core"
import { Close } from "@material-ui/icons"

import { MButton } from "shared/components"
import { EButtonTypes } from "shared/interfaces"
import { EContainerNames, ELoaderTypes } from 'interfaces';
import { EBasicActionTypes } from 'shared/containers'

import { getDispatchAction } from 'store';
import { DeleteGroupStyled, Header, ErrorStyled } from "./styles"

import { toogleMessage } from "store/selectionContainer"

const DeleteFolder = (props) => {
  const { closeAction, folderId, deleteFolder } = props;
  const [errorMessage] = useState('');

  const submitHandler = () => {
    deleteFolder({ folder_id: folderId })
    closeAction();
  }

  return (
    <DeleteGroupStyled>
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <div className="form-content">
        <Header>Are you sure you want to delete this folder?</Header>
        <div className="buttons">
          <MButton
            type={EButtonTypes.BASIC}
            onClick={closeAction}
            className="cancel-button"
            width="200px"
          >
            Cancel
          </MButton>
          <MButton
            type={EButtonTypes.BASIC}
            onClick={submitHandler}
            className="delete-button"
            width="200px"
          >
            Delete Folder
          </MButton>
        </div>
        {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>} 
      </div>
    </DeleteGroupStyled>
  )
}

const mstp = (state) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_GROUP]
})

const mdtp = (dispatch) => ({
  deleteFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.DELETE, actionSuffix: 'FOLDER' }),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default connect(mstp, mdtp)(DeleteFolder);