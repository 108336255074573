import { EErrorActionTypes } from './interfaces';

const intialErrorState = {
  error: null
}

const errorReducer: any = (
  state = intialErrorState,
  { type, payload }
) => {
  switch(type) {
    case EErrorActionTypes.SHOW_ERROR: {
      return {
        ...state,
        error: payload
      }
    }
    case EErrorActionTypes.HIDE_ERROR: {
      return {
        ...state,
        error: null
      }
    }
    default: {
      return state;
    }
  }
};

export default errorReducer;