import styled from "styled-components";
import { containers, colors } from "config/uiKit";

export const gridStyles = (theme: any) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  gridTile: {
    overflow: 'hidden',
    height: '100%',
    margin: '10px auto'
  },
  gridList: {
    width: '100%',
    'scrollbar-width': 'none',
    overflow: 'unset'
  },
  gridTileRoot: {
    minHeight: 260
  }
});


export const BrandVoicesContent = styled.div<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  margin-top: 10px;
  cursor: pointer;
  .mainContainer {
    padding: 10px;
    height: 100%;
    overflow: auto;
  }
  .header {
    display: flex;
    font-size: 26px;
    font-weight: 400;
    margin: 12px 30px 16px 30px;
  }
  .title {
    flex-grow: 10;
    text-align: left;
  }
  .user {
    display: flex;
    font-size: 18px;
    align-items: center;
    letter-spacing: 0;
  }
  .avatarContainer {
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 16px;
    margin-left: 14px;
  }
  .greenCircle {
    width: 20px;
    height: 20px;
    margin: 6px;
    border-radius: 10px;
    background-color: ${colors.greenScale.light};
  }
`

export const BrandCardStyled = styled.div<any>`
  position: relative;
  overflow: auto;
  border-radius: 13px;
  width: 100%;
  max-width: 260px;
  height: 235px;
  background-color: ${colors.grayScale.light};
  background-image: url(${props => props.image});
  margin: 10px auto;
  background-size: cover;
  background-position-x: center;
  .brandName {
    position: absolute;
    bottom: 0;
    text-align: center;
    font-size: 20px;
    color: white;
    width: 100%;
    height: 45px;
    padding: 8px;
    background-color: ${props => props.color};
  }
`