
import React from 'react';
import { Menu, KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons';

import { EButtonTypes, EButtonIconSide, EUserRole } from 'shared/interfaces';

import { colors } from 'config/uiKit';
import { PersonaFolder, UserIcon, } from 'assets';
import { ESelectorType, ERoutesName } from 'interfaces';
import { CreateUser } from 'components/forms';

const UI_CONFIG = {
  [ERoutesName.HOME]: (props) => ({
    headerProps: {
      left: [
        ...folderButtons(props?.user?.user_role),
        ...showBrandVoice(props)
      ],
      right: getRightIcons(props),
    }
  }),
  [ERoutesName.DASHBOARD_BOARDS]: (props) => ({
    headerProps: {
      left: [
        ...folderButtons(props?.user?.user_role),
        ...personaButton(props?.user?.user_role, props),
        ...showBrandVoice(props)

      ],
      right: getRightIcons(props),
    }
  }),
  [ERoutesName.PERSONA]: props => ({
    headerProps: {
      left: props.editing ? [
        {
          ...backButtonConfig(props)
        },
      ] : [
          ...folderButtons(props?.user?.user_role),
          ...showBrandVoice(props)
        ],
      right: getRightIcons(props),
    }
  }),
  [ERoutesName.DASHBOARD_TEAM]: (props, handleOpen, handleClose) => ({
    headerProps: {
      left: [
        {
          ...backButtonConfig(props),
        },
        ...adminButton(props?.user?.user_role, handleOpen, handleClose)
      ],
      right: getRightIcons(props),
    }
  }),
  [ERoutesName.DASHBOARD_BRAND_VOICE]: (props) => ({
    headerProps: {
      left: [
        {
          ...backButtonConfig(props),
        },
      ],
      right: getRightIcons(props),
    }
  }),
  [ERoutesName.BRAND_VOICE]: (props, PDFIcon, iconProps) => ({
    headerProps: {
      left: [
        {
          ...backButtonConfig(props),
        },
      ],
      right: [
        { icon: PDFIcon, iconProps },
        ...getRightIcons(props),
      ]

    }
  }),
  [ERoutesName.DASHBOARD_BRAND_VOICES]: (props) => ({
    headerProps: {
      left: [
        /*{
          text: "Create New Brand Voice",
          icon: Menu,
          side: EButtonIconSide.LEFT
        },*/
      ],
      right: getRightIcons(props),
    }
  }),
}
UI_CONFIG[ERoutesName.DASHBOARD_ACCOUNT_SETTINGS] = UI_CONFIG[ERoutesName.DASHBOARD_BRAND_VOICE];

const getRightIcons = props => {
  const currentPage = props.history.location.pathname;
  //const isAcctSet = !!matchPath(currentPage, { path: ERoutesName.ACCOUNT_SETTINGS });
  const isDash = !!(currentPage.indexOf(ERoutesName.DASHBOARD_PREFIX) + 1);//!!matchPath(currentPage, { path: ERoutesName.DASHBOARD });
  const greenBut = { backgroundColor: colors.greenScale.light, color: 'white', borderColor: 'white' };
  return ([
    /*{
      text: "Account Settings",
      icon: SettingsOutlined,
      onClick: () => props.history.push(ERoutesName.ACCOUNT_SETTINGS),
      extraStyles: isAcctSet ? greenBut : {},
    },*/
    {
      text: "My Dashboard",
      icon: UserIcon,
      onClick: () => props.history.push(ERoutesName.DASHBOARD_BOARDS),
      extraStyles: isDash ? greenBut : {color: colors.grayScale.medium},
    },
  ])
}

const showBrandVoice = (props) => {
  if (props.selectedFolderId) {
    return [{
      text: "Brand Voice",
      icon: Menu,
      onClick: () => props.history.push(ERoutesName.BRAND_VOICE.replace(':folderId', props.selectedFolderId))
    }]
  } else {
    return []
  }
}

const folderButtons = (user_role: string) => [{
  id: ESelectorType.GROUP,
  text: user_role === EUserRole.VIEW_ONLY ? "Select Group" : "Select or Create Group",
  icon: KeyboardArrowDown,
  type: EButtonTypes.SELECTOR
},
{
  id: ESelectorType.FOLDER,
  text: user_role === EUserRole.VIEW_ONLY ? "Select Folder" : "Select or Create Folder",
  icon: KeyboardArrowDown,
  type: EButtonTypes.SELECTOR
}]


const personaButton = (user_role, props) => {
  if (user_role === EUserRole.ADMIN) {
    return [{
      id: ESelectorType.PERSONA,
      text: "Create New Persona",
      icon: PersonaFolder,
      type: EButtonTypes.BASIC,
      onClick: () => props.history.push(ERoutesName.NEW_PERSONA)
    }]
  } else {
    return []
  }
}

const adminButton = (user_role, handleOpen, handleClose) => {
  if (user_role === EUserRole.ADMIN) {
    return [{
      text: "Add New Team Member",
      icon: UserIcon,
      onClick: () => handleOpen(<CreateUser closeAction={handleClose} />)(),
    }]
  } else {
    return []
  }
}

const backButtonConfig = (props) =>({
  text: "Back",
  icon: KeyboardArrowLeft,
  side: EButtonIconSide.LEFT,
  onClick: props.history?.goBack
})


const getConfig = (routePath) => {
  let config = UI_CONFIG[routePath]
  if (!config) return {}
  return config;
}

export default getConfig;