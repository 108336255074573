import { Row } from "components";
import React, { memo } from "react";
import { ESelectorType } from "interfaces";
import { EButtonIconSide, EButtonTypes } from "shared/interfaces";
import { MButton, Form, IconWhite } from "shared/components";
import { colors } from "config/uiKit";
import { withRouter } from "react-router";

const PageHeader = ({ headerProps = { left: [], right: [] }, openFormModal, anchorEl, setAnchorEl, groupSelected, folderSelected }: any) => {
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  return (
    <Row justify='space-between' className="generalButtons">
      <Row justify='flex-start'>
        {headerProps.left.map(button => {
          const disabled = button.id === ESelectorType.FOLDER && !groupSelected;
          const text = (button.id === ESelectorType.GROUP && groupSelected) ||
            (button.id === ESelectorType.FOLDER && folderSelected) ||
            button.text;
          const style: any = { marginRight: '2%', borderRadius: anchorEl && anchorEl?.id === button.id ? '22px 22px 0 0' : 22 };
          if (text !== button.text) style.color = colors.grayScale.dark;
          return (
            <HeaderButtonWithNav
              {...button}
              id={button.id || button.text}
              key={button.text}
              openFormModal={openFormModal}
              disabled={disabled}
              side={button.side || EButtonIconSide.RIGHT}
              style={style}
              onClick={button.type === EButtonTypes.SELECTOR ? openMenu : button.onClick || null}
              minwidth={+(button.type === EButtonTypes.SELECTOR) * 165}
              text={text}
            />)
        }
        )}
      </Row>
      <Row justify='flex-end'>
        {headerProps.right.map(button =>
          button.text ?
            <MButton
              key={button.text}
              side={button.side || EButtonIconSide.RIGHT}
              type={EButtonTypes.ICON_WHITE}
              icon={<button.icon {...button.iconProps} />}
              style={button.extraStyles ? { marginLeft: '2%', ...button.extraStyles } : { marginLeft: '2%' }}
              onClick={button.onClick}
            
            >
              {button.text}
            </MButton> :
            <IconWhite
              key={Math.random()}
              className="roundButton"
              onClick={button.onClick}
            >
              <button.icon {...button.iconProps} />
            </IconWhite>
        )}
      </Row>
    </Row>
  );
};

const HeaderButton = ({ text, side, icon: Icon, iconProps, formData, style, openFormModal, history, onClick, id, disabled, minwidth, hidden = false }: any) => {
  if (hidden) return null;
  let onClickOver = onClick || (() => { });
  if (formData) onClickOver = openFormModal(<Form formData={formData} />);
  else if (text === 'Back' && !onClickOver) onClickOver = () => history.goBack();
  return (
    <MButton
      id={id}
      side={side || EButtonIconSide.LEFT}
      type={EButtonTypes.ICON_WHITE}
      icon={Icon && <Icon {...iconProps} />}
      style={style}
      disabled={disabled}
      onClick={onClickOver}
      minwidth={minwidth}
    >
      {text}
    </MButton>
  )
}

const HeaderButtonWithNav = withRouter(HeaderButton);



export default memo(PageHeader)