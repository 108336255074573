export enum EUserActionTypes {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  CHECK_AUTH = 'CHECK_AUTH',
  CHECKED_AUTH = 'CHECKED_AUTH',
  CREATE_USER = 'CREATE_USER',
  CLEAR_USER = 'CLEAR_USER',
  CREATE_PERSONA = 'CREATE_PERSONA',
  GET_PERSONA = 'GET_PERSONA',
  GET_BRAND = 'GET_BRAND',
}

export interface IAuthConfig {
  api: Record<'auth'|'login'|'logout', string>
  loader: string
}


export interface IUserState {
  authenticated: boolean;
  checkedAuth: boolean;
  userInfo: any;
}

export enum EUserRole {
  ADMIN = 'ADMIN',
  EDIT_ONLY = 'EDIT_ONLY',
  VIEW_ONLY = 'VIEW_ONLY',
  ANY = 'ANY',
}

export interface IUser {
  id?: number,
  username: string,
  userType: EUserRole,
  email: string, 
  password: string, 
  phone_number: number,
  first_name: string,
  last_name: string,
  physician_id?: number,
  address_line1?: string,
  address_line2?: string,
  city?: string,
  zip_code?: string,
  birth_date?: string,
  profile_picture?: string,
  group?: string,
  routines?: Array<object>
}


export interface IAuthUser {
  username: string,
  password: string
}