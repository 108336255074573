import React from "react"
import { menuItems } from "config"
import { EButtonTypes } from "shared/interfaces"
import { ButtonContainer, ButtonStyle, MenuItemStyled, IconStyle,MenuItemText } from "./styles"
import { MButton } from "shared/components"
import { CancelIcon,FilterIcon,FilterSelectedIcon } from "assets"

export default ({filters, clearAllFilters, selectedFilters, selectedItem, selectItem,selectBrandOnly}) => {

  const selectMenuItem = item => {
    if (selectedItem?.id !== item.id) setTimeout(selectItem, +!!selectedItem * 400 + 10, item);
    if (selectedItem) selectItem(null);
  }

  const mapInitialFilters = (backendFilters) => (menuItem) => {
    if (!backendFilters.length) return menuItem;

    const mapBackendFilters = (filters) => ({
      incomeLevel: [filters.min_income, filters.max_income],
      ageGroup: [filters.min_age, filters.max_age],
      contractLength: [filters.min_contract_length, filters.max_contract_length],
    })

    const initialFilters = backendFilters
      .find(filter => filter.brand_id === menuItem.id)
    //no initial filters ? get global filter : get initial filter
    const options = !initialFilters
      ? backendFilters
        .find(filter => !filter.brand_id).options
      : initialFilters.options

    let parsedOptions = mapBackendFilters(options)
    return {
      ...menuItem,
      initialFilters: {
        ageGroup: parsedOptions.ageGroup,
        incomeLevel: parsedOptions.incomeLevel,
        contractLength: parsedOptions.contractLength,
        personalityType: AllPersonalityTypes
      }
    }
  }

  return (<>
    <div style={{height: 24}} />
    {menuItems
      .map(mapInitialFilters(filters))
      .map((item, i) => {
        const itemSelected = selectedItem?.id === item.id

        const filterIconProps: any = {
          key:item.id,
          style :{ float: "right", cursor: "pointer",marginRight:10 },
          onClick: () => selectMenuItem(item)
        }

        const RequiredFilterIcon:any = itemSelected?FilterSelectedIcon:FilterIcon
        let menuItemActive = false
        const brandNameFilterOnly = Object.keys(selectedFilters?.[item.id]??{})?.length===1

        if(itemSelected) {
          menuItemActive =  !brandNameFilterOnly && !!selectedFilters[item.id]
        }
        return (
          <MenuItemStyled
            key={item.id}
            active={!!selectedFilters[item.id]}
            selected={itemSelected}
            style={itemSelected && !i ? { marginTop: -28 } : {}}
            // onClick={() => selectMenuItem(item)}
          >
            <MenuItemText
              active={brandNameFilterOnly}
              selected={itemSelected}
              onClick={() => selectBrandOnly(item)}
            >
              {item.label}
            </MenuItemText>

            <RequiredFilterIcon {...filterIconProps} />
          </MenuItemStyled>
        );})}
    {!!Object.keys(selectedFilters).length && <ButtonContainer>
      <MButton
        type={EButtonTypes.ICON_WHITE}
        style={ButtonStyle}
        onClick={clearAllFilters}
      >
        Reset All Filters
        <CancelIcon style={IconStyle} />
      </MButton>
    </ButtonContainer>}
  </>)
}

const AllPersonalityTypes = [
  "ESFP",
  "ESFJ",
  "ESTP",
  "ESTJ",
  "ENFP",
  "ENFJ",
  "ENTP",
  "ENTJ",
  "ISFP",
  "ISFJ",
  "ISTP",
  "ISTJ",
  "INFP",
  "INFJ",
  "INTP",
  "INTJ"
]