export const convertTo = (date: any, dateType = 'MM/DD/YY') => {
  let converter = TIME_CONVERSIONS[dateType]
  let newDate = new Date(date);

  return converter(newDate)
}

const TIME_CONVERSIONS = {
  'MM/DD/YY': (date) => `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
  'dd DD': (date) => `${dayToName[date.getDay()]} ${date.getDate()}`
}

const dayToName = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']