import { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import { colors } from "config/uiKit";

export const cardStyle: Styles<Theme, any> = {
  root: {
    padding: 16,
    border: `1px solid ${colors.grayScale.medium}`,
    borderRadius: 26,
  },
}