

export enum ESelectorActionTypes {
  SET_GROUP = 'SET_GROUP',
  SET_FOLDER = 'SET_FOLDER',
  SET_BOTH = 'SET_BOTH',
  SET_ADDING_TO_FOLDER = 'SET_ADDING_TO_FOLDER',
  SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  CLEAR_GROUP_AND_FOLDER = 'CLEAR_GROUP_AND_FOLDER',
  TOOGLE_MESSAGE = 'TOOGLE_MESSAGE',
  CHANGE_COLOR = 'CHANGE_COLOR',
}

const initialState = {
  groupId: null,
  folderId: null,
  addingToFolder: false,
  selectedFilters: {},
}

export const setAddingToFolder = ({ active }) => ({
  type: ESelectorActionTypes.SET_ADDING_TO_FOLDER,
  payload: active
})

export const selectFolder = (payload) => ({
  type: ESelectorActionTypes.SET_FOLDER,
  payload: { folderId: payload ? payload.folderId : null }
})

export const selectGroup = (payload) => ({
  type: ESelectorActionTypes.SET_GROUP,
  payload: { groupId: !!payload ? payload.groupId : null }
})

export const selectBoth = ({ folderId, groupId }) => ({
  type: ESelectorActionTypes.SET_BOTH,
  payload: { folderId, groupId }
})

export const clearGroupsAndFolder = () => ({
  type: ESelectorActionTypes.CLEAR_GROUP_AND_FOLDER
})

export const setActiveFilters = ({ filters }) => ({
  type: ESelectorActionTypes.SET_ACTIVE_FILTERS,
  payload: { filters }
})

export const clearAllFilters = () => ({
  type: ESelectorActionTypes.CLEAR_ALL_FILTERS
})

export const toogleMessage = payload => ({
  type: ESelectorActionTypes.TOOGLE_MESSAGE,
  payload
})

export const changeColor = ({ brandId }) => ({
  type: ESelectorActionTypes.CHANGE_COLOR,
  payload: { brandId }
})

export const SelectionReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case ESelectorActionTypes.SET_FOLDER:
      return {
        ...state,
        folderId: payload.folderId
      }
    case ESelectorActionTypes.SET_GROUP:
      return {
        ...state,
        groupId: payload.groupId
      }
    case ESelectorActionTypes.SET_BOTH:
      return {
        ...state,
        groupId: payload.groupId,
        folderId: payload.folderId
      }
    case ESelectorActionTypes.SET_ADDING_TO_FOLDER:
      return {
        ...state,
        addingToFolder: payload,
      }
    case ESelectorActionTypes.SET_ACTIVE_FILTERS:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.filters.brand_id]: payload.filters,
        }
      }
    case ESelectorActionTypes.CLEAR_GROUP_AND_FOLDER:
      return {
        ...state,
        groupId: null,
        folderId: null,
        addingToFolder: false
      }
    case ESelectorActionTypes.CLEAR_ALL_FILTERS:
      return {
        ...state,
        selectedFilters: {}
      }
    case ESelectorActionTypes.TOOGLE_MESSAGE:
      return {
        ...state,
        showMessage: payload
      }
    case ESelectorActionTypes.CHANGE_COLOR:
      return {
        ...state,
        brandId: payload.brandId
      }
    default:
      return state;
  }
}


