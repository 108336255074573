import { menuItems } from "config";

export interface IPersona {
  id?: number,
  brand_id?: number,
  name: string,
  age: number,
  gender: EGender,
  occupation: string,
  contract_length: number,
  income: number,
  personality_type: EPersonalityTypes,
  profile_picture_url: string,
  audio_sample_url: string,
  about: string[],
  needs: string[],
  frustrations: string[],
  most_used_apps: any[],
  introvert: number,
  thinking: number,
  sensing: number,
  judging: number,
  created_at?: string,
  updated_at?: string
}

export enum EGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export enum ECustomerType {
  Standard = 'Standard',
  Migration = 'Migration',
  AutoRefill = 'AutoRefill'
}

export enum EPersonalityTypes {
  INFP = 'INFP',
  INFJ = 'INFJ',
  ENFJ = 'ENFJ',
  ENFP = 'ENFP',
  INTJ = 'INTJ',
}

export class Persona implements IPersona {
  id?: number;
  name: string;
  brand_id?: number;
  age: number | undefined;
  gender: EGender;
  occupation: string;
  contract_length: number;
  income: number;
  personality_type: EPersonalityTypes;
  profile_picture_url: string;
  audio_sample_url: string;
  about;
  needs;
  frustrations;
  most_used_apps;
  introvert: number;
  thinking: number;
  sensing: number;
  judging: number;
  created_at?: string;
  updated_at?: string;

  constructor(brand_id = menuItems[0].id, age = undefined, contract_length = undefined, income = undefined,
    gender = EGender.Male, personality_type = EPersonalityTypes.ENFJ,
    name = '', occupation = '', profile_picture_url = '', audio_sample_url = '',
    about = [], needs = [], frustrations = [], most_used_apps = [],
    introvert = 50, thinking = 50, sensing = 50, judging = 50) {
    this.brand_id = brand_id;
    this.name = name;
    this.age = age;
    this.gender = gender;
    this.occupation = occupation;
    this.contract_length = contract_length;
    this.income = income;
    this.personality_type = personality_type;
    this.profile_picture_url = profile_picture_url;
    this.audio_sample_url = audio_sample_url;
    this.about = about;
    this.needs = needs;
    this.frustrations = frustrations;
    this.most_used_apps = most_used_apps;
    this.introvert = introvert;
    this.thinking = thinking;
    this.sensing = sensing;
    this.judging = judging;
  }
}