import React, { useState } from "react";
import { Switch, withStyles, FormControlLabel } from "@material-ui/core";
import { colors } from "config/uiKit";

const SwitchStyled = withStyles({
  switchBase: {
    color: colors.grayScale.dark,
    '&$checked': {
      color: '#fff',
    },
    '&$checked + $track': {
      backgroundColor: colors.greenScale.light,
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

const FormControlLabelStyled = withStyles({
  root: {
    margin: '-4px 0 -4px -11px',
  },
  label: {
    fontWeight: 200,
  },
})(FormControlLabel);

export default ({onChange, ...props}) => {
  const [checked, setChecked] = useState(true);

  const handleChange = (evt, newVal) => {
    setChecked(newVal);
    onChange(evt, newVal);
  }

  return (
    <FormControlLabelStyled
      control={
        <SwitchStyled
          checked={checked}
          onChange={handleChange}
          {...props} 
        />
      }
      label={props.label}
      style={props.consecutive ? {marginLeft: props.consecutive} : {}}
    />
  )
};