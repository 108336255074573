import { EGender } from "interfaces";

export const isEmpty = (obj: Object) => {
  for(let key in obj) {
    if(obj.hasOwnProperty(key)) return false;
  }
  return true;
}


export const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

export const createRadius = (w, h, r) => {
  const steps = 5;
  const diff = deg2rad(90 / steps);
  const points = [];
  for (let i = 0 ; i< 4; i++) {
    const ini = deg2rad(180 - i * 90 || 360);
    const xX = +!!(i % 3) * (w - r * 2) + r;
    const xY = Math.floor(i / 2) * h;
    for (let j = 0; j <= steps; j ++) {
      const rad = ini - j * diff;
      const px = Math.cos(rad) * r + xX;
      const py = Math.sin(rad) * r + xY;
      points.push(`${round(px / w * 100)}% ${round(py / h * 100)}%`);
    }
  }
  return points.join(',');
}

export const deg2rad = deg => Math.PI * deg / 180;

export const rad2deg = rad => Math.round(rad / Math.PI * 180);

export const round = (num, exp = 2) => Math.round(num * Math.pow(10, exp)) / Math.pow(10, exp);

export const add0 = num => (num < 10 ? '0' : '') + num;

export const formatTime = num => `${add0(Math.floor(num / 3600))}:${add0(Math.floor(num / 60))}:${add0(num % 60)}`;

export const isDataValid = (data, msg = '', panel = null, optional = []) => {
  let d = { ...data };
  let value = true;
  let field = null;
  for (let i in d) {
    const isEmptySimple = !d[i] && (isNaN(d[i]) || d[i] === '');
    const isEmptyArray = Array.isArray(d[i]) && !d[i].length;
    const isMandatory = !(optional.indexOf(i) + 1);
    if (isMandatory && (isEmptySimple || isEmptyArray)) {
      field = i.replace(/_/g, ' ').toUpperCase();
      value = false;
      break;
    }
  }
  // console.log('isDataValid', value, field);
  return { field, value, panel, msg: `${msg} (${field})` };
}


export const covertObjToQueryString = (obj) => {
  let searchParams = new URLSearchParams();
  const convertToMinMaxObjects = (selector:string)=>obj?.[selector]?.map(([min,max])=>({min,max}))
  let filters = {
    'brand_id': obj.brand_id,
    'age': JSON.stringify(convertToMinMaxObjects('ageGroup')),
    'income': JSON.stringify(convertToMinMaxObjects('incomeLevel')),
    'contract_length': JSON.stringify(convertToMinMaxObjects('contractLength')),
    'personality_types': obj.personalityType,
    'sort_by_latest': obj.latestPersonasFirst,
    'personas_with_audio': obj.personasWithAudio,
    'migration': obj.migration,
    'auto_refill': obj.autoRefill,
    ...getGender(obj)
  }
  Object.entries(filters).forEach(([key, val]: any) => {
    if (val!=undefined) {
      searchParams.append(key, val)
    }
  })
  return searchParams.toString();
}

const getGender = (obj: any) => {
  if (obj.genderFemale && obj.genderMale) {
    return {};
  } else {
    if (obj.genderFemale && !obj.genderMale) {
      return { gender: EGender.Female }
    } else if (obj.genderMale && !obj.genderFemale) {
      return { gender: EGender.Male}
  } else {
    return { }
  }
  }
}