import styled from 'styled-components';
import { colors } from 'config/uiKit';

export const NewGroupStyle = styled.div<any>`
width: 600px;
height: 700px;
overflow: none;
.group-cover {
  width: 100%;
  height: 270px;
  background: linear-gradient(180deg, ${colors.blueScale.medium}, ${colors.blueScale.light});
  padding-top: 50px;
  cursor: pointer;
}
.image-container {
  width: 100%;
  height: 270px;
  background-position: center;
  background-size: cover;
  background-image: url('${props => props.image}');
  cursor: pointer;
}
.MuiAvatar-colorDefault {
  background-color: white;
}
.cam-avatar {
  width: 124px;
  height: 124px;
  margin: auto;
  .MuiSvgIcon-root {
    font-size: 60px;
  }
}
.close-avatar {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.MuiSvgIcon-root {
  fill: ${colors.blueScale.light};
}
h2 {
  margin-top: 30px;
}
h3 {
  font-size: 18px;
  color: white;
  text-align: center;
  margin-top: 20px;
}
.form-content {
  text-align: center;
  padding: 20px 100px;
}
.buttons {
  display: flex;
  margin-top: 44px;
}
.cancel-button {
  color: ${colors.grayScale.medium};
  background-color: white;
  border-color: ${colors.grayScale.medium};
  margin-right: 20px;
}
.save-button {
  color: white;
  background-color: ${colors.greenScale.light};
  border-color: ${colors.greenScale.light};
}
.delete-button {
  color: ${colors.redScale.medium};
  background-color: white;
  border-color: ${colors.redScale.medium};
}
.save-button:hover, .delete-button:hover, .cancel-button:hover {
    color: white;
    background-color: ${colors.grayScale.medium};
    border-color: ${colors.grayScale.medium};
}
`

export const NewFolderStyle = styled.div<any>`
width: 600px;
overflow: none;
.close-avatar {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-color: white;
  border: 2px solid black;
}
h2 {
  margin-top: 20px;
}
.MuiSvgIcon-root {
  fill: black;
}
.form-content {
  text-align: center;
  padding: 60px 100px;
}
.buttons {
  display: flex;
  margin-top: 44px;
}
.cancel-button {
  color: ${colors.grayScale.medium};
  background-color: white;
  border-color: ${colors.grayScale.medium};
  margin-right: 20px;
}
.save-button {
  color: white;
  background-color: ${colors.greenScale.light};
  border-color: ${colors.greenScale.light};
}
.save-button:hover, delete-button:hover, .cancel-button:hover {
  color: white;
  background-color: ${colors.grayScale.medium};
  border-color: ${colors.grayScale.medium};
}
.delete-button {
  color: ${colors.redScale.medium};
  background-color: white;
  border-color: ${colors.redScale.medium};
}
`

export const ErrorStyled = styled.div`
  margin-top: 20px;
  margin-bottom: -36px;
  color: red;
  font-size: 14px;
`

export const CreateUserContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  padding: 45px 95px 90px 95px;
  .MuiSvgIcon-root {
    fill: black;
  }
  .close-avatar {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: white;
    border: 2px solid black;
  }
  .no-image {
    cursor: pointer;
  }
  .image-container {
    width: 259px;
    height: 259px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-image: url('${props => props.image}');
    cursor: pointer;
  }
  .cam-avatar {
    width: 259px;
    height: 259px;
    margin: auto;
    .MuiSvgIcon-root {
      font-size: 60px;
    }
  }
  .upload-button {
    color: ${colors.grayScale.medium};
    background-color: white;
    border-color: ${colors.grayScale.medium};
  }
  .MuiTextField-root {
    width: 450px;
    margin-bottom: 20px;
  }
  .MuiTextField-root:nth-child(1) {
    margin-right: 70px;
  }
`

export const DeleteUserImage = styled.img`
  width: 155px;
  height: 155px;

`


export const DeleteUserForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 53px 107px 63px 107px; 
  h1 {
    font-size: 27px;
    margin-bottom: 29px;
    font-weight: bold;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
    font-weight: normal;
  }
  h4 {
    margin-top: 12px;
    margin-bottom: 27px;
    font-size: 18px;
    font-weight: normal;

  }
  .cancel-button {
    color: ${colors.grayScale.medium};
    background-color: white;
    margin: 0;
    width: 267px;
    border-color: ${colors.grayScale.medium};
  }
  .save-button:hover, .delete-button:hover, .cancel-button:hover {
    color: white;
    background-color: ${colors.grayScale.medium};
    border-color: ${colors.grayScale.medium};
  }
  .delete-button {
    color: ${colors.redScale.medium};
    background-color: white;
    width: 267px;
    border-color: ${colors.redScale.medium};
    margin-bottom: 23px;
  }
`

export const Header = styled.div<any>`
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 56px;
`

export const DeleteGroupStyled = styled.div<any>`
width: 615px;
height: 376px;
overflow: none;
.close-avatar {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background-color: white;
  border: 2px solid black;
}
h2 {
  margin-top: 20px;
}
.MuiSvgIcon-root {
  fill: black;
}
.form-content {
  text-align: center;
  padding: 92px 130px;
  height: 100%;
  width: 100%;
}
.buttons {
  display: flex;
  margin-top: 44px;
  font-weight: bold;
}
.cancel-button {
  color: ${colors.grayScale.medium};
  background-color: white;
  border-color: ${colors.grayScale.medium};
  margin-right: 20px;
}
.save-button {
  color: white;
  background-color: ${colors.greenScale.light};
  border-color: ${colors.greenScale.light};
}
.save-button:hover, .delete-button:hover, .cancel-button:hover {
  color: white;
  background-color: ${colors.grayScale.medium};
  border-color: ${colors.grayScale.medium};
}
.delete-button {
  color: ${colors.redScale.medium};
  background-color: white;
  border-color: ${colors.redScale.medium};
}
`