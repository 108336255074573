import styled from "@react-pdf/styled-components";
import { containers, colors } from "config/uiKit";



export const MainContainer = styled.View`
    padding: 10px;
    height: 100%;
`

export const FlexContainer = styled.View`
    display: flex;
    flex-direction: row;
    height: 50%;
    padding: 10px 0;
`

export const BrandMainContainer = styled.View`
  height: 100%;
  overflow: auto;
`

export const InfoContainer = styled.View<any>`
  width: ${props => props.width || '50%'};
  margin: 0 10px;
  padding: 15px 30px;
  background-color: ${colors.grayScale.light};
  border-radius: ${containers.borderRadius}px;
  height: 100%;
`
export const Heading = styled.Text`
  font-size: 22px;
  margin-bottom: 15px;
`

export const ListItem = styled.Text`
  margin: 0 20px 5px 0;
  font-size: 12px;
  line-height: 1.2;
`
