import { FlatButtonProps } from "material-ui";

export interface IMButton extends FlatButtonProps {
  id?: string;
  icon?: any;
  type?: EButtonTypes;
  width?: any;
  side?: EButtonIconSide;
  minwidth?: number;
  noTypography?: boolean;
  buttonType?: string;
}

export enum EButtonTypes {
  BASIC = 'Basic',
  ICON_WHITE = 'IconWhite',
  ICON_GRAY = 'IconGray',
  SELECTOR = 'Selector',
}

export enum EButtonIconSide {
  LEFT = 'left',
  RIGHT = 'right',
}