import styled from 'styled-components';
import { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import { colors } from "config/uiKit";
import { withStyles, Radio, FormControlLabel } from "@material-ui/core";


export const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`
export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`

export const FormHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
  margin-bottom: 2%;
  border-bottom: 1px solid ${colors.grayScale.light};
`

export const formStyles: Styles<Theme, any>  = {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: colors.grayScale.dark,
    justifyContent: 'left',
    padding: '0 10px',
    flexGrow: 1,
    width: '40%',
    height: 40,
    backgroundColor: 'white',
    border: `1px solid ${colors.grayScale.medium}`,
    borderRadius: 34,
    '& .MuiTypography-root': {
      fontSize: 14,
      margin: '0 10px',
      whiteSpace: 'nowrap'
    },
    '& .MuiAvatar-root': {
      width: 30, 
      height: 30,
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: colors.grayScale.light,
    },
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.greenScale.light,
      }  
    },
    '&:hover': {
      color: colors.greenScale.light,
      backgroundColor: 'white',
      borderColor: colors.greenScale.light,
      '& .MuiAvatar-colorDefault': {
        backgroundColor: colors.greenScale.light,
      },
      '& svg path': {
        fill: colors.greenScale.light,
      }
    },
  },
  labelPlacementStart: {
    margin: '2%'

  }
};




export const MRadio = withStyles(formStyles)(Radio)
export const MRadioWrapper = withStyles(formStyles)(FormControlLabel)