import styled from 'styled-components';
import { Styles } from '@material-ui/core/styles/withStyles';

import { Theme } from '@material-ui/core';
import { colors } from 'config/uiKit';

type Custom = {
  height?: string 
  padding?: string
  width?: string
  justify?: string
  align?: string
  margin?: string
  fontSize?: string
  color?: string
  fontWeight?: string
  scrollable?: boolean
  backgroundColor?: string
}

export const pageStyles = (theme: any) => ({
  root: {
    display: 'flex',
    height: '100%',
    marginBottom: 20,
    zIndex: -2,
  },
  general: {
    width: '100%',
    height: '100%',
  },
  profile: {
    width: 278,
    height: '100%',
    marginLeft: 24,
  },
  appoinmentsButton: {
    marginBottom: 15,
    '& .MuiTypography-root': {
      marginRight: 0,
    },
  },
  messagesContainer: {
    width: '100%',
    height: 300
  }
});

export const PageContainer = styled.div<Custom>`
  display: flex;
  position: unset;
  overflow: ${({ scrollable }) => scrollable ? 'scroll' : 'hidden'};
  overflow-x: hidden;
  flex-direction: column;
  height: ${({ height }) => height};
  max-height: 100%;
  width: 100%;
  padding: ${({ padding = '2%' }) => padding};
  justify-content:  ${({ justify = 'space-between' }) => justify};
  background-color: ${({ backgroundColor }) => backgroundColor};
`

export const MenuContainerStyle: Styles<Theme, any> = {
  paper: {
    background: 'transparent',
    borderRadius: '0 0 22px 22px',
    border: `1px solid ${colors.grayScale.medium}`,
  },
}

export const MenuItems = styled.div<any>`
  width: ${props => props.anchorEl?.offsetWidth - 2 || 200}px;
  background-color: white;
  outline: 0;
  .menuItem {
    height: 50px;
    cursor: pointer;
    border-top: 1px solid ${colors.grayScale.medium};
    padding: 15px;
    text-align: center;
    font-size: 14px;
  }
  .menuItem:hover {
    background-color: ${colors.grayScale.medium};
    color: white;
  }
`
