import { colors } from "config/uiKit";
import styled from "styled-components";

export const FinishButtonStyle =  styled.div`
    position: fixed;
    bottom: 0;
    left: 320px;
    width: calc(100% - 360px);
    display: flex;
    justify-content: center;
    z-index: 2;
    background-color: ${colors.greenScale.light};
    border-radius: 34px 34px 0 0;
    cursor: pointer;
    .innerButton {
        width: 248px;
        height: 44px;
        margin: 12px;
        padding: 10px;
        border: 1px solid white;
        border-radius: 22px;
    }
`