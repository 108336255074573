import React, { useState } from 'react';
import { Row, Typography } from 'components';
import { PlusIcon, GreenCheckIcon, RemoveIcon } from 'assets';

import * as Styled from './styles';

const PersonaCard = ({ persona, showIcon = false, onClick, onIconClick = () => { } }) => {
  return (
    <Styled.Container>
      { showIcon &&
        <Icon added={persona.added} onClick={onIconClick} />
      }
      <Styled.PersonaImageContainer>
        <Styled.PersonaImage
          src={persona.profile_picture_url}
        />
      </Styled.PersonaImageContainer>
      <Styled.CardContainer onClick={onClick}>
        <Styled.CardTextContainer>
          <Row justify='center'><Typography bold fontSize="24px">{persona.name}</Typography></Row>
          <Row justify='center'><Typography fontSize="18px">{persona.age} : {persona.gender} : {persona.income}K : {persona.personality_type}</Typography></Row>
          <Row justify='center'><Typography fontSize="18px" className={`customer-type-${persona.customer_type?.toLowerCase()==='standard'?'hidden':'visible'}`}>{persona.customer_type}</Typography></Row>
        </Styled.CardTextContainer>
        <Styled.BrandLabel brand={persona.brand}>
          <Typography fontSize="20px" light>{persona.brand}</Typography>
        </Styled.BrandLabel>
      </Styled.CardContainer>
    </Styled.Container>
  )
}


enum EIconStatus {
  ADDED = 'ADDED',
  REMOVE = 'REMOVE',
  ADD_ACTION = 'ADD_ACTION',
  JUST_ADDED = 'JUST_ADDED'
}


const Icon = ({ added, onClick }) => {
  const [status, changeStatus] = useState(added ? EIconStatus.ADDED : EIconStatus.ADD_ACTION);

  switch (status) {
    case EIconStatus.ADD_ACTION:
      return (
        <Styled.IconContainer onClick={onClick} >
          <PlusIcon />
        </Styled.IconContainer>
      )
    case EIconStatus.JUST_ADDED:
      return (
        <Styled.IconContainer onMouseOut={() => changeStatus(EIconStatus.ADDED)} >
          <GreenCheckIcon />
        </Styled.IconContainer>
      )
    case EIconStatus.ADDED:
      return (
        <Styled.IconContainer onMouseOver={() => changeStatus(EIconStatus.REMOVE)} onClick={onClick} >
          <GreenCheckIcon  />
        </Styled.IconContainer>
      )
    case EIconStatus.REMOVE:
      return (
        <Styled.IconContainer onMouseOut={() => changeStatus(EIconStatus.ADDED)}  style={{ transform: 'rotate(45deg)' }}  onClick={onClick}>
          <RemoveIcon />
        </Styled.IconContainer>
      )
  }
}


export default PersonaCard;