import styled from 'styled-components';
import { colors } from 'config/uiKit';

export const Row = styled.div<any>`
  display: flex;
  flex-direction: row;
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  flex-grow: ${({ flexGrow }) => flexGrow};
  width: ${({ width }) => width || '100%'};
  justify-content:   ${({ justify = 'space-between' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  overflow: ${({ overflow }) => overflow};
`

export const Column = styled.div<any>`
  display: flex;
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  flex-grow: ${({ flexGrow }) => flexGrow};
  width: ${({ width }) => width || '100%'};
  justify-content:   ${({ justify = 'space-between' }) => justify};
  align-items: ${({ align = 'center' }) => align};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: ${({ overflow }) => overflow};
`

export const Typography = styled.div<any>`
  display: flex;
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
  font-weight: ${({ fontWeight, ...args }) => fontWeight || getWeight(args)};
  font-size: ${({ fontSize, ...args }) => fontSize || getFontSize(args) };
  color: ${({ color, ...args }) => color || getColor(args)};
`

const getColor = (options: any) => {
  if (options.light) {
     return colors.font.light;
  } else {
    return colors.font.dark;
  }
}

const getWeight = (options: any) => {
  if (options.bold) {
    return 'bold'
  } else {
    return 'normal'
  }
}

const getFontSize = (options:any) => {
  if (options.sm) {
    return fontSizes.sm;
  } else if (options.lg) {
    return fontSizes.lg
  } else {
    return fontSizes.md
  }
} 
 
const fontSizes = {
  sm: '1rem',
  md: '1.5rem',
  lg: '2rem'
}

export { default as Page } from './page'