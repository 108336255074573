import { colors } from "config/uiKit";

export const loginStyle = (theme: any) => ({
  loginBg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.blueScale.medium}, ${colors.blueScale.light})`,
    // backgroundImage: "url(./imgs/MelioLogo.svg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "75% 57%",
    minHeight: "100vh",
    backgroundSize: "calc(100vh*6.7)",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "61.5% 100%",
      backgroundSize: "calc(100vh*4)",
    },
  },
  container: {
    width: 340,
  },
  logo: {
    marginBottom: 90,
  },
  loginButton: {
    margin: '40px 0 70px 0',
    height: 60,
    border: 'none',
    borderRadius: 30,
    backgroundColor: 'white',
    "& .MuiTypography-root": {
      fontSize: 22,
      color: colors.blueScale.loginTextField
    },
    "&:hover": {
      backgroundColor: colors.greenScale.light
    }
  },
  extraButtons: {
    display: "flex",
    textAlign: "center" as "center",
    "& a": {
      textDecoration: "none",
      color: 'white',
      fontSize: 17,
    },
    "& a:hover": {
      color: colors.greenScale.light
    },
  },
  textField: {
    margin: "4px 0",
    "& .MuiOutlinedInput-input": {  
      color: "white",
      fontSize: 20,
      padding: 'inherit'
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: colors.blueScale.loginTextField,
      borderRadius: 30,
      height: 59,
    },
    "& svg": {
      width: 20,
      height: 20,
      marginRight: 8,
      "& path": {
        fill: "white"
      },
    },
    "& .MuiOutlinedInput-notchedOutline, .PrivateNotchedOutline-root, .MuiOutlinedInput-input:-webkit-autofill": {
      borderRadius: 'initial',
      border: 'none'
    },
    "& .MuiInputLabel-root": {
      color: "white",
      fontSize: 20,
      padding: "2px 16px"
    },
    "& .MuiInputLabel-shrink": {
      color: colors.grayScale.medium
    },
    "& input:-webkit-autofill, & input:-internal-autofill-selected": {
      WebkitBoxShadow: `0 0 0 1000px ${colors.blueScale.loginTextField} inset`,
      color: "white !important",
      "-webkit-text-fill-color": "white",
      fontSize: 20,
      border: 'none'
    }
  }
});

//"MuiInputBase-input MuiOutlinedInput-input MTextField-input-13 MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"