import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";

import Dialog from '@material-ui/core/Dialog';
import styled from "styled-components";


const dialogStlyes: Styles<Theme, any>  = {
  paper: {
    borderRadius: '26px',
    boxShadow: 'none',
    overflow: 'hidden',
    maxWidth: 'none',
  },
}

export const BackStyle = styled.div<any>`
  background-image: url(${props => props.image});
  height: 100%;
  width: 100%;
  filter: blur(8px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
`

export const MDialog = withStyles(dialogStlyes)(Dialog);
