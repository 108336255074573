import { Reducer } from 'redux';
import { EUserActionTypes, IUserState } from './interfaces';

const initialUserState: IUserState = {
  authenticated: false,
  checkedAuth: false,
  userInfo: null
};

const userReducer: Reducer<IUserState, any> = (
  state = initialUserState,
  action
) => {
  switch (action.type) {
    case EUserActionTypes.CHECKED_AUTH: {
      return {
        ...state,
        checkedAuth: true
      }
    }
    case EUserActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        authenticated: true,
        checkedAuth: true,
        userInfo: action.payload
      };
    }
    case EUserActionTypes.LOGOUT: {
      return {
        ...initialUserState,
        checkedAuth: true
      };
    }
    default:
      return state;
  }
};

export default userReducer;