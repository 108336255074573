



import { EUserActionTypes, IAuthConfig } from './'
import { IEpicConfig } from '../containers';



const createAuthEpicConfig = ({ api, loader } : IAuthConfig): Record<string, IEpicConfig> => ({
  [EUserActionTypes.LOGIN]: {
    epic: {
      requestCreator: (action: any) => (
        {
          url: api.login,
          method: 'POST',
          withCredentials: true,
          body: {
            username: action.payload.username,
            password: action.payload.password
          }
        })
    },
    actions: {
      start: [EUserActionTypes.LOGIN],
      recieve: EUserActionTypes.LOGIN_SUCCESS,
      loader,
    },
  },
  [EUserActionTypes.LOGOUT]: {
    epic: {
      requestCreator: () => ({
        url: api.logout,
        method: 'POST',
        withCredentials: true,
      })
    },
    actions: {
      start: [EUserActionTypes.LOGOUT],
      recieve: EUserActionTypes.LOGOUT_SUCCESS,
      loader,
    }
  },
  [EUserActionTypes.CHECK_AUTH]: {
    epic: {
      requestCreator: () => ({
        url: api.auth,
        method: 'POST',
        withCredentials: true,
      }),
      errorHandle: EUserActionTypes.CHECKED_AUTH
    },
    actions: {
      start: [EUserActionTypes.CHECK_AUTH],
      recieve: EUserActionTypes.LOGIN_SUCCESS,
      loader,
    },
  }

})


export default createAuthEpicConfig;