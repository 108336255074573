import React from "react"
import { UserIcon, } from 'assets';
import { ERoutesName } from "interfaces"
import { MButton } from "shared/components"
import { EButtonTypes, EUserRole } from "shared/interfaces"
import { AccountMenuItemStyled, ButtonStyle, ButtonContainer, LogoutIconStyle} from "./styles"
import { Avatar, Typography } from "@material-ui/core";
import { matchPath } from "react-router-dom";


export default ({ history, logout, user }) => {
  const filteredMenu = menuItems.filter((m) => !m.permitted_roles || m.permitted_roles.includes(user.user_role));
  const currentPage = history.location.pathname;
  return (<>
    {filteredMenu.map((item, i) => {
      let sel = item.route === currentPage;
      if (item.subRoutes) sel = item.subRoutes.reduce((pv, path) => pv || !!matchPath(currentPage, { path }), sel);
      console.log(currentPage, item)
      return <AccountMenuItemStyled
          key={item.label}
          selected={sel}
          onClick={() => history.push(item.route)}
        >
          {item.label}
        </AccountMenuItemStyled>
      }
    )}
    <ButtonContainer>
      <MButton
        type={EButtonTypes.ICON_WHITE}
        style={ButtonStyle}
        onClick={logout}
        noTypography={true}
      >
        <Typography style={{ textAlign: 'initial' }} noWrap>
          Logout
        </Typography>
        <Avatar style={LogoutIconStyle}><UserIcon /></Avatar>
      </MButton>
    </ButtonContainer>
  </>)
}

const menuItems = [
  { label: 'Persona Boards', route: ERoutesName.DASHBOARD_BOARDS },
  { label: 'Brand Voices', route: ERoutesName.DASHBOARD_BRAND_VOICES, subRoutes:[ERoutesName.DASHBOARD_BRAND_VOICE] },
  { label: 'Manage Team', route: ERoutesName.DASHBOARD_TEAM, permitted_roles: [EUserRole.ADMIN] },
  { label: 'Account Settings', route: ERoutesName.DASHBOARD_ACCOUNT_SETTINGS },
]