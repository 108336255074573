import styled from 'styled-components';
import { colors, containers } from 'config/uiKit';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const pd = containers.containerPadding;

export const personaPanelStyle = (theme: any) => ({
  container: {
    display: 'flex',
    height: `calc(100% - 74px)`,
    overflowX: 'hidden' as 'hidden'
  },
});

export const ScrollIconStyled = styled.div<any>`
  position: absolute;
  transform: rotate(90deg);
  right: 0;
  animation: pulse 2s infinite;
  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
  @keyframes pulse {
    0% {
      color: ${props => props.color}33;
    }
    50% {
      color: ${props => props.color}ff;
    }
    100% {
      color: ${props => props.color}33;
    }
  }
`

/****************************
 ***       PROFILE        ***
*****************************/

export const ProfileStyled = styled.div<any>`
  width: 25%;
  padding: ${pd}px 2%;
  .profileEditing {
    background-color: ${colors.grayScale.profile};
    padding: 5px 0;
    border-radius: 11px;
  }
  .profileShow {
    border-radius: 11px;
    height: 100%;
    h1 {
      font-size: 40px;
      margin-top: 20px;
    }
    h2 {
      font-size: 18px;
      margin: 6px 0;
    }
  }
  .profileAditional {
    display: flex;
    height: 72px;
    border-radius: 0 0 11px 11px;
    background-color: ${props => props.color};
    padding: 11px;
  }
`

export const ProfileMediaStyled = styled.div<any>`
  position: relative;
  text-align: center;
  height: 300px;
  overflow: auto;
  cursor: pointer;
  ${props => props.image ?
    `background-size: cover;
    background-image: url('${props.image}');
    background-position: center;` : ''
  }
  .profileImageIcon {
    width: 90px;
    margin: 70px auto 0 auto;
    svg, path {
      fill: white;
    }
    .MuiSvgIcon-root {
      width: 90px;
      height: 90px;
    }
  }
  ${props => props.editing ? 
    `margin: 10px;
    border-radius: 11px;
    border: 2px solid white;` 
    : 
    `background-color: ${colors.grayScale.ultraDark};
    .profileImageIcon {
      margin-top: 110px;
    }`
  }
  .uploadText {
    width: 140px;
    font-size: 18px;
    text-align: center;
    margin: 0 auto;
  }
  .audioUploaded {
    position: absolute;
    left: calc(50% - 50px);
    top: 45px;
    svg, path {
      fill: ${colors.greenScale.light}99;
    }
    .MuiSvgIcon-root {
      width: 100px;
      height: 100px;
    }
  }
  .avatarPlay {
    position: absolute;
    margin: 53px auto 0 auto;
    width: 144px;
    height: 144px;
    z-index: 1;
    right: calc(50% - 72px);
    background-color: ${colors.grayScale.ultraDark};
    .profileImageIcon {
      margin: 0 auto 0 auto;
    }
  }
  .progress {
    position: absolute;
    width: 160px;
    height: 160px;
    right: calc(50% - 80px);
    top: 45px;
  }
  .time {
    margin-top: 230px;
    color: white;
    font-size: 24px;
    font-weight: 200;
  }
`

export const ProfileAditionalIcon = styled.div<any>`
  margin: auto;
  .avatarContainer {
    width: 50px;
    height: 50px;
    background-color: #ffffff22;
    cursor: pointer;
  }
  .avatarContainer:hover {
    background-color: #ffffff44;
  }
  .avatarContainer, .avatarContainer:hover {
    ${props => props.selected ?
    `background-color: ${colors.grayScale.dark};
      cursor: auto;` : ''
  }
  }
  .avatarOutlined {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    background-color: #00000000;
    svg, path {
      fill: white;
      width: 30px;
    }
  }
`

export const TextFieldStyled = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '15px 10px',
      padding: '3px 10px',
      width: 'calc(100% - 20px)',
      height: 40,
      borderRadius: 11,
      display: 'flex',
      position: 'relative',
    },
    input: {
      width: '100%',
      fontSize: 18,
      padding: '4px 0',
    },
    list: {
      cursor: 'pointer',
    },
    icon: {
      width: 32,
      height: 32,
      backgroundColor: colors.grayScale.profile,
      borderRadius: 9,
      position: 'absolute',
      top: 4,
      right: 4,
      padding: 2,
      '& svg': {
        fill: 'white'
      },
      '& .MuiSvgIcon-root': {
        width: 30,
        height: 30,
      }
    }
  })
)

/****************************
 ***        ABOUT         ***
*****************************/

export const AboutStyled = styled.div<any>`
  width: 40%;
  padding: ${pd}px 2% 0 2%;
`

export const MultiTextStyled = color => makeStyles(() =>
  createStyles({
    root: {
      margin: '10px 0 0 0',
      width: '100%',
      '& .MuiOutlinedInput-multiline': {
        padding: 10,
        fontSize: 18,
        marginBottom: 28,
        height: '100%',
        minHeight: '170px',
        borderRadius: 13,
        border: `1px solid ${colors.grayScale.text}`,
        color: colors.grayScale.text,
      },
      '& *': {
        border: 0
      }
    },
    input: {
      width: '100%',
      fontSize: 18,
      height: '100%',
    },
    aboutHeader: {
      display: 'flex',
      color: colors.grayScale.text,
      position: 'relative',
    },
    icon: {
      '& .MuiSvgIcon-root': {
        height: 30,
        width: 30,
        margin: '-2px 15px -2px 0',
        fill: colors.grayScale.text,
      }
    },
    simpleField : {
      fontSize: 18,
      margin: '-2px 0 28px 0',
      color: colors.grayScale.text,
      padding: '10px 0',
      lineHeight: 1.5,
    },
    divider: {
      margin: '10px 0 0 0',
      width: '100%',
      border: `1px solid ${colors.grayScale.profile}`,
    },
  })
)

/****************************
 ***     PERSONALITY      ***
*****************************/

export const PersonalityStyled = styled.div<any>`
  width: 35%;
  padding: ${pd}px 2%;
  hr {
    border: 1px solid ${colors.grayScale.profile};
    margin: 10px 0;
  }
  .apps {
  }
  .appsList {
    overflow: auto;
    margin-top: 20px;
  }
  .innerList {
    display: flex;
  }
  .personality {
    margin-top: 43px;
  }
  .gridList { 
    width: 100%;
  }
  .slider {
    margin-top: 15px;
  }
  .labels {
    display: flex;
    font-size: 16px;
  }
  .label1 {
    width: 50%;
  }
  .label2 {
    width: 50%;
    text-align: end;
  }
  .buttons {
    display: flex;
    margin-top: 14px;
    height: 46px;
  }
  .buttonsSpace {
    width: 10%;
  }
  .buttonContainer {
    width: 45%;
  }
  .buttonGreen {
    height: 44px;
    border-color: ${colors.greenScale.light};
    background-color: ${colors.greenScale.light};
  }
  .buttonGreen:hover {
    color: ${colors.greenScale.light};
    border-color: ${colors.greenScale.light};
    background-color: white;
  }
  .buttonGray {
    height: 44px;
    color: ${colors.grayScale.medium};
    border-color: ${colors.grayScale.medium};
    background-color: white;
  }
  .buttonGray:hover {
    color: white;
    background-color: ${colors.grayScale.medium};
  }
  .appsHeader {
    display: flex;
    position: relative;
  }
`

export const AppAvatarStyled = styled.div<any>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 60px;
  background-color: ${colors.grayScale.profile};
  cursor: ${props => props.editing ? 'pointer' : 'auto'};
  margin: 10px auto;
  position: relative;
  ${props => props.image ?
    `background-size: cover;
    background-image: url('${props.image}');
    background-position: center;` : ''
  }
  ${props => props.selected ?
    `box-shadow: 0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02);
    border: 4px solid white;` :
    !props.noTransparency && 'opacity: 0.5;'
  }
  .appText {
    width: 70px;
    height: 50px;
    margin: 0 auto;
    padding: calc((${props => props.size}px - 50px)/2) 0;
    font-size: 18px;
    text-align: center;
    position: relative
  }
`

export const AppsSelectorStyled = styled.div<any>`
  position: relative;
  display: flex;
  width: 60vw;
  height: 60vh;
  border-radius: 16px;
  background: white;
  padding: 60px 20px 20px 20px;
  .gridList {
    width: 100%;
    background-color: ${colors.grayScale.light};
    border: 2px solid ${colors.grayScale.profile};
    border-radius: 12px;
  }
  .title {
    position: absolute;
    left: 22px;
    top: 17px;
    font-size: 20px;
    color: ${colors.grayScale.dark};
  }
  .closeButton {
    position: absolute;
    top: 12px;
    right: 18px;
    cursor: pointer;
    background-color: white;
    border: 1px solid ${colors.grayScale.dark};
    width: 36px;
    height: 36px;
  }
  .MuiSvgIcon-root {
    fill: ${colors.grayScale.dark};
  }
`

export const SliderStyledProps = props => ({
  root: {
    height: 20,
    width: 'calc(100% - 40px)',
    margin: '0 20px',
    padding: '13px 0',
  },
  thumb: {
    height: 36,
    width: 50,
    backgroundColor: props.color,
    border: '3px solid white',
    marginTop: -8,
    marginLeft: -25,
    borderRadius: 18,
    boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 0 0 1px rgba(0,0,0,0.02)',
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)',
    },
    '&.Mui-disabled': {
      height: 36,
      width: 50,
      marginTop: -8,
      marginLeft: -25,
    }
  },
  active: {},
  track: {},
  rail: {
    color: colors.grayScale.profile,
    opacity: 1,
    height: 20,
    borderRadius: 11,
    width: 'calc(100% + 40px)',
    marginLeft: -20,
  },
})