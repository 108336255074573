import { EInputTypes } from 'shared/interfaces';

export const userForm = (currentVal: any, formType = 'EDIT', inputStyles =  {} ) => ({
  submitButtonText: formType === 'EDIT' ? 'Save Edits' : 'Add New Team Member',
  loader: 'GLOBAL',
  inputFields: [
    {
      id: 'name',
      value: currentVal['name'],
      label: 'Name',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'job_title',
      value: currentVal['job_title'],
      label: 'Job Title',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'surname',
      value: currentVal['surname'],
      label: 'Surname',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'department',
      value: currentVal['department'],
      label: 'Department',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'email',
      value: currentVal['email'],
      label: 'Email',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'phone_number',
      value: currentVal['phone_number'],
      label: 'Phone Number',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'username',
      value: currentVal['username'],
      label: 'Username',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },
    {
      id: 'password',
      value: currentVal['password'],
      label: 'Password',
      type: EInputTypes.TEXT_FIELD,
      style: inputStyles,
      required: true
    },

  ]
})