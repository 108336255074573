
import React, { useState, useEffect } from 'react';
import { useSelector, connect } from "react-redux";
import { withRouter } from 'react-router';

import { Modal, Loader } from 'shared/components';
import { EBasicActionTypes } from 'shared/containers';

import { ESelectorType, EContainerNames } from 'interfaces';
import { selectFolder, selectBoth, selectGroup, toogleMessage } from 'store/selectionContainer';

import NewGroup from '../forms/newGroup';
import NewFolder from '../forms/newFolder';
import { PageHeader, MsgPanel } from 'components';
import DropList from './dropList';
import { PageContainer } from './styles';

const Page = ({
  height = '100%',
  padding = '0 0 0 2%',
  justify = null,
  headerProps,
  children,
  user,
  loaderType,
  location,
  scrollable,
  selectedGroupId = null,
  selectedFolderId = null,
  selectFolder,
  selectGroup,
  selectBoth,
  toogleMessage,
  showMessage,
  history
}: any): any => {
  const loading = useSelector((state: any) => state['LOADER'][loaderType] || null);
  const groups = useSelector((state: any) => state[EContainerNames.GROUPS][EBasicActionTypes.LIST.toLowerCase()]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [errorMsg]: any = useState({});

  const closeDropList = () => setAnchorEl(null);

  useEffect(() => {
    if (showMessage) {
      showError(showMessage);
      toogleMessage(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMessage]);

  useEffect(() => {
    let queryParams = new URLSearchParams(location.search);
    let groupId = queryParams.get('groupId');
    let folderId = queryParams.get('folderId');
    if (groupId && folderId) {
      selectBoth({ groupId: parseInt(groupId), folderId: parseInt(folderId) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])


  const selGroup = (openFormModal, closeFormModal) => (id) => {
    setAnchorEl(null);
    if (id !== 'create') {
      selectGroup({ groupId: id });
      history.push({ search: `?groupId=${id}` })
    } else {
      openFormModal(<NewGroup closeAction={closeFormModal} />)();
    }
  }

  const selFolder = (openFormModal, closeFormModal) => (id) => {
    setAnchorEl(null);
    if (id !== 'create') {
      selectFolder({ folderId: id });
      history.push({ search: location.search + `&folderId=${id}` })

    } else {
      openFormModal(<NewFolder groupId={selectedGroupId} closeAction={closeFormModal} />)();
    }
  }

  const configErrorMsg = (openFormModal, closeFormModal) => {
    if (!errorMsg.openFormModal) {
      errorMsg.openFormModal = openFormModal;
      errorMsg.closeFormModal = closeFormModal;
    }
  }

  const showError = data => {
    errorMsg.closeFormModal();
    const openPanel = errorMsg.openFormModal(
      <MsgPanel message={data.msg} closeAction={errorMsg.closeFormModal} />,
      () => {
        if (!!data.panel) {
          const openPanel = errorMsg.openFormModal(data.panel);
          setTimeout(openPanel, 300);
        }
      }
    );
    setTimeout(openPanel, 300);
  };

  const groupSelected = selectedGroupId && groups.find(v => v.id === selectedGroupId);
  const folderSelected = groupSelected && selectedFolderId && groupSelected.folders.find(v => v.id === selectedFolderId);

  // console.log('group', groupSelected, selectedGroupId, 'folder', folderSelected, selectedFolderId)
  const dropListData =
    (anchorEl?.id === ESelectorType.GROUP && groups) ||
    (anchorEl?.id === ESelectorType.FOLDER && groups.find(v => v.id === selectedGroupId).folders) ||
    [];

  return (
    <PageContainer
      height={height}
      padding={padding}
      justify={justify}
      scrollable={scrollable}
    >
      {!!loading && <Loader contained />}
      <Modal
        render={({ handleClose, handleOpen }) => {
          configErrorMsg(handleOpen, handleClose);
          return (
            <>
              <PageHeader
                headerProps={headerProps}
                openFormModal={handleOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                groupSelected={groupSelected?.name}
                folderSelected={folderSelected?.name}
              />
              {anchorEl && <DropList
                anchorEl={anchorEl}
                user_role={user.user_role}
                handleClose={closeDropList}
                type={anchorEl?.id}
                data={dropListData}
                onClick={(anchorEl?.id === ESelectorType.GROUP ? selGroup : selFolder)(handleOpen, handleClose)}
              />}
            </>
          )
        }}
      />
      {children}
    </PageContainer>
  )
}

const mstp = (state) => ({
  selectedGroupId: state[EContainerNames.SELECTORS].groupId,
  selectedFolderId: state[EContainerNames.SELECTORS].folderId,
  showMessage: state[EContainerNames.SELECTORS].showMessage,
  user: state[EContainerNames.AUTH].userInfo
})

const mdtp = (dispatch) => ({
  selectFolder: (payload) => dispatch(selectFolder(payload)),
  selectGroup: (payload) => dispatch(selectGroup(payload)),
  selectBoth: (payload) => dispatch(selectBoth(payload)),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default withRouter(
  connect<any, any, any>(mstp, mdtp)
    (Page)
);