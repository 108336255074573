import React from 'react';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { loaderStyle } from './styles';

const ModalBackground = (props: any) => {
  const { classes, contained } = props;
  return (
    <div className={contained ? classes.container : classes.root}>
      <CircularProgress
          className={classes.loader}
          color='primary'
          variant='indeterminate'
          size="10em"
      />
    </div>
  );
}

export default withStyles(loaderStyle)(ModalBackground);