import styled from 'styled-components';
import { containers, colors } from 'config/uiKit';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow: hidden;
`
//Bubble DEPRECATED
export const Bubble = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 2% 0;
  border: 1px solid ${colors.grayScale.medium};
  background-color: white;
  border-radius: 50px;
  padding: 2% 4%;
  ${({ selected }) => selected &&
    `border: 1px solid ${colors.greenScale.light};
      color: ${colors.greenScale.light};`
  }
  &:hover {
    cursor: pointer;
  }
`

export const PersonaButton = styled.div<any>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
  border: ${props => props.noBorder ? 'none' :
    props.selected ? `2px solid ${colors.greenScale.light}` 
    : `1px solid ${colors.grayScale.medium}`};
  background-color: white;
  border-radius: 45px;
  padding: 10px;
  height: 90px;
  ${props => props.handCursor && !props.selected ? `
    cursor: pointer;
    :hover { border: 1px solid ${colors.greenScale.light}; }
    ` : ''}
`

export const BubbleImage = styled.img`
  border-radius: 50%;
  margin-right: 12px;
  height: 70px;
  width: 70px;
`

export const PersonaBoardsContent = styled.div<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  margin-top: 10px;
  .mainContainer {
    padding: 10px;
    height: 100%;
    overflow: auto;
  }
  .header {
    display: flex;
    font-size: 26px;
    font-weight: 400;
    margin: 12px 30px 16px 30px;
  }
  .title {
    flex-grow: 10;
    text-align: left;
  }
`