import { EContainerNames } from 'interfaces';


export interface IContainerConfig {
  name: EContainerNames,
  cyclesConfig: Array<ICycleConfig>
}

export interface ICompleteContainer {
  reducer: IReducerConfig
  exportedActions: any
  epic: Array<IEpicConfig>
}

export interface ICycleConfig {
  type: string
  actionSuffix?: string
  loader?: string
  options?: ICycleOptions
}

export interface ICycleOptions {
  startActions?: Array<string>
  alternateAPI?: string
  redirect?: Function
  urlModifier?: ({ url: string, action: IAction }) => string
  setterMethod?: (state: any, payload: any) => any
  getterMethod?: (state: any, payload: any) => any
  requestBodyMapper?: Function
}

export interface IReducerConfig {
  storeActions: IStoreAction
  initialState: any
}


export interface IStoreAction {
  [key: string]: Function
}

export interface IEpicConfig {
  epic: IEpicBuilder
  actions: IEpicActionConfig
}

export interface IEpicBuilder {
  requestCreator: (payload: any) => IRequest,
  redirect?: Function,
  errorHandle?: any,
  networkRequest?: true,
  actions?: IEpicActionConfig
}

export interface IEpicActionConfig {
  start: Array<string>
  recieve: string
  loader: string
}

export interface IRequest {
  url: string
  method: string
  withCredentials: boolean
  body?: object
}

export interface IAction {
  type: string
  payload: any
}

export interface IDispatchActionGenerator {
  dispatch: any
  container: EContainerNames
  actionType: EBasicActionTypes,
  actionSuffix?: string
}

export enum ERestMethods {
  GET = "GET",
  POST = "POST",
  PATCH = "PATCH",
  DELETE = "DELETE"
}

export enum EBasicActionTypes {
  DETAIL = 'DETAIL',
  LIST = 'LIST',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export interface IActionConfig {
  getterName: string
  getterMethod?: Function
  setterName: string
  setterMethod: Function
}


