import styled from 'styled-components';
import { colors, containers } from 'config/uiKit';

export const menuStyle = (theme: any) => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  logo: {
    width: 165,
    height: 25,
    margin: 24,
  },
  content: {
    display: "flex",
    flexDirection: "row" as 'row',
    margin: '10px 20px',
    width: '100%',
    zIndex: 1,
    padding: '23px',
    backgroundColor: colors.grayScale.content,
    borderRadius: containers.borderRadius,
  },

  listItem: {
    backgroundColor: 'white',
    color: colors.grayScale.dark,
    margin: '0px 0px 22px 22px',
    borderRadius: '26px 0 0 26px',
    padding: '10px 12px',
    width: 'auto',
    '& .MuiAvatar-colorDefault': {
      color: colors.grayScale.dark,
      backgroundColor: 'white',
    },
    '&:hover': {
      backgroundColor: colors.grayScale.light,
    },
    '& svg': {
      width: 24,
      height: 24
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 40,
    }
  },
  listItemSelected: {
    backgroundColor: `${colors.grayScale.light} !important`,
    '& .MuiAvatar-colorDefault': {
      color: 'white',
      backgroundColor: colors.blueScale.medium,
    }
  },
  listMenu: {
    padding: 0,
  }
});

interface Custom {
  flexDirection?: string
}

export const Content = styled.div<Custom>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row' };
  height: 100%;
  background-color: ${colors.grayScale.medium};

`;
