export const contractFilterValues = [
  {
    id: 1,
    value: "[1, 2]",
    name: "1Y+",
  },
  {
    id: 2,
    value: "[3, 4]",
    name: "3Y+",
  },
  {
    id: 3,
    value: "[5, 9]",
    name: "5Y+",
  },
  {
    id: 4,
    value: "[10]",
    name: "10Y+",
  },
];

export const ageFilterValues = [
  {
    id: 1,
    value: "[20, 29]",
    name: "20-29",
  },
  {
    id: 2,
    value: "[30, 39]",
    name: "30-39",
  },
  {
    id: 3,
    value: "[40, 49]",
    name: "40-49",
  },
  {
    id: 4,
    value: "[50]",
    name: "50+",
  },
];

export const incomeFilterValues = [
  {
    id: 1,
    value: "[10, 19]",
    name: "10K+",
  },
  {
    id: 2,
    value: "[20, 29]",
    name: "20K+",
  },
  {
    id: 3,
    value: "[30, 39]",
    name: "30K+",
  },
  {
    id: 4,
    value: "[40]",
    name: "40K+",
  },
];
