import React, { useState } from "react";
import { connect } from 'react-redux'
import { Avatar } from "@material-ui/core";
import { Close, CameraAltOutlined } from "@material-ui/icons";
import { getDispatchAction } from 'store';

import { MTextField, MButton, Loader } from "shared/components";
import { EButtonTypes } from "shared/interfaces";
import { EContainerNames, ELoaderTypes } from 'interfaces';
import { EBasicActionTypes } from 'shared/containers'

import { NewGroupStyle, ErrorStyled } from "./styles";
import { isDataValid } from "utils";
import { CommonMsg } from "config/uiKit";
import { toogleMessage } from "store/selectionContainer";

const NewGroup = props => {
  const { closeAction, createGroup, loading } = props;
  const [image, setImage] = useState(null);
  const [imageBlob, setImageBlob] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [formContent] = useState({ name: '', folder_name: '', persona_image: '' });
  let imageSelector;

  const submitHandler = () => {
    const { name, folder_name } = formContent;
    const isValid = isDataValid(formContent, CommonMsg.fillFields, <NewGroup {...props} />);
    if (!isValid.value) return setErrorMessage(isValid.msg);
    if (imageBlob) {
      createGroup({ name, folder_name: folder_name, group_avatar: imageBlob });
      closeAction();
    }
  }
  
  const updateData = id => e => formContent[id] = e.target.value;

  const openSelector = () => imageSelector.click();

  const selectImage = async event => {
    if (event.target?.files?.length) {
      let image_file = event.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      formContent.persona_image = ' ';
      setImage(image_url);
      setImageBlob(image_file);
    }
  }

  return (
    <NewGroupStyle image={image}>
      {!!loading && <Loader contained />}
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <input
        ref={ref => imageSelector = ref}
        type="file"
        onChange={selectImage}
        style={{ display: 'none' }}
        accept="image/*"
      />
      {image ?
        <div className="image-container" onClick={openSelector} /> :
        <div className="group-cover" onClick={openSelector}>
          <Avatar className="cam-avatar">
            <CameraAltOutlined />
          </Avatar>
          <h3>Add Group Cover</h3>
        </div>}
      <div className="form-content">
        <h2>Create Group Name</h2>
        <MTextField
          required
          fullWidth
          id="name"
          label="Enter name here"
          onChange={updateData('name')}
        />
        <h2>Create Folder Name</h2>
        <MTextField
          required
          fullWidth
          id="folder_name"
          label="Enter name here"
          onChange={updateData('folder_name')}
        />
        <div className="buttons">
          <MButton
            type={EButtonTypes.BASIC}
            onClick={closeAction}
            className="cancel-button"
            width="200px"
          >
            Cancel
            </MButton>
          <MButton
            type={EButtonTypes.BASIC}
            onClick={submitHandler}
            className="save-button"
            width="200px"
          >
            Save
            </MButton>
        </div>
        {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>} 
      </div>
    </NewGroupStyle>
  )
}

const mstp = (state) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_GROUP]
})

const mdtp = (dispatch) => ({
  createGroup: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.CREATE }),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default connect(mstp, mdtp)(NewGroup);