import { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
// import { colors } from "config/uiKit";

export const loaderStyle: Styles<Theme, any> = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    zIndex: 999,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.02)'
  },
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 999,
    width: '100%',
    overflow: 'hidden',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  loader: {
    zIndex: 10,
  }
}

