import React from "react";
import { Text } from '@react-pdf/renderer';
import * as Styled from './styles';
import { PersonOutlineOutlined, PanToolOutlined, FlashOn } from "@material-ui/icons";

export default ({ persona, color, }) => {
  const fields = [
    { id: 'about', icon: <PersonOutlineOutlined /> },
    { id: 'needs', icon: <PanToolOutlined /> },
    { id: 'frustrations', icon: <FlashOn /> },
  ];
  const getValue = id => persona?.[id]?.join('\n\n') || '';
  return (
    <Styled.AboutStyled>
      {fields.map(v =>
        <AboutEditField
          key={v.id}
          value={getValue(v.id)}
          textArr={persona?.[v.id] || ''}
          color={color}
          {...v}
        />)
      }
    </Styled.AboutStyled>
  )
}

const AboutEditField = ({ id, icon, color, title = '', textArr, ...props }) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      <Styled.Header>
        {/* <Styled.AboutIcon>{icon}</Styled.AboutIcon> */}
        <Text>{(title || id).toUpperCase()}</Text>
      </Styled.Header>
      <Styled.Divider />
      <Styled.AboutRoot id={title || id}>
        {textArr.map((t, i) =>
          <Styled.AboutText key={t + i}>
            {t}
          </ Styled.AboutText>
        )}
      </Styled.AboutRoot>
    </>
  )
}