import styled from 'styled-components';
import { colors } from 'config/uiKit'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 400px;
`

export const CardContainer = styled.div`
  width: 260px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  &:hover {
    cursor: pointer;
  }
`

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 22px;
  .customer-type-hidden {
    visibility: hidden;
  }
  .customer-type-visible {
    color: #6E78B1;
  }
`

//TODO: Correct Calculation
export const PersonaImageContainer = styled.div`
  position: absolute;
  border: 4px solid white;
  background-color: white;
  border-radius: 10px;
  top: 0;
  margin: 0 17px;
  width: 226px;
  height: 236px;
`

export const PersonaImage = styled.img`
  border-radius: inherit;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 10%;
  top: 3%;
  z-index: 1;
  height: 42px;
  width: 42px;
  &:hover {
    cursor: pointer;
  }
`

export const BrandLabel = styled.div<any>`
  background-color: ${({ brand }) => colors.brands[brand]};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 44px;
  font-size: 20px;
`