import styled from "styled-components";

export const MsgPanelStyled = styled.div<any>`
  width: 600px;
  overflow: none;
  .close-avatar {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: white;
    border: 2px solid black;
  }
  h2 {
    margin: 20px 0;
  }
  .MuiSvgIcon-root {
    fill: black;
  }
  .form-content {
    text-align: center;
    padding: 60px 100px;
  }
`