import React, { useEffect, useState } from "react";
import $ from 'jquery';
import { MultiTextStyled, AboutStyled, ScrollIconStyled } from "./styles";
import { TextField } from "@material-ui/core";
import { PersonOutlineOutlined, PanToolOutlined, FlashOn, DoubleArrow } from "@material-ui/icons";

export default ({ editing, changeInfo, persona, color }) => {
  const fields = [
    { id: 'about', icon: <PersonOutlineOutlined /> },
    { id: 'needs', icon: <PanToolOutlined /> },
    { id: 'frustrations', icon: <FlashOn /> },
  ];
  const getValue = id => persona?.[id]?.join('\n\n') || '';
  return (
    <AboutStyled>
      {fields.map(v =>
        <AboutEditField 
          key={v.id} 
          value={getValue(v.id)} 
          textArr={persona?.[v.id] || ''}
          onChange={changeInfo(v.id)} 
          editing={editing}
          color={color}
          {...v} 
        />)
      }
    </AboutStyled>
  )
}

const AboutEditField = ({ id, icon, editing, color, title='', textArr, ...props }) => {
  // const [showScIcon, setShowScIcon] = useState(false);
  const classes = MultiTextStyled(color)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   const scDiv = $(`#${title || id}`);
  //   if (scDiv?.[0]) {
  //     const show = scDiv[0].clientHeight < scDiv[0].scrollHeight;
  //     if (showScIcon !== show) setShowScIcon(show);
  //   }
  // })
  return (<>
    <div className={classes.aboutHeader}>
      <div className={classes.icon}>{icon}</div>
      <h2>{(title || id).toUpperCase()}</h2>
      {/* {showScIcon && <ScrollIconStyled color={color}><DoubleArrow /></ScrollIconStyled>} */}
    </div>
    {!editing && <hr className={classes.divider} />}
    {editing ?
      <TextField
        id={title || id}
        className={classes.root}
        multiline
        rows={5}
        placeholder="Type here:"
        variant="outlined"
        {...props}
      /> :
      <div id={title || id} className={`${classes.root} ${classes.simpleField}`}>
        {textArr.map((t, i) => 
          <div key={t + i}>
            {t} {i < textArr.length - 1 && <br/>} <br/>
          </ div>
        )}
      </div>
    }
  </>)
}