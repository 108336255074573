import React from 'react';
import { connect } from 'react-redux';

import { getDispatchAction } from 'store';
import { Page, PersonaCard, GridList, Typography } from 'components'
import { EContainerNames, ERoutesName } from 'interfaces';

import { EBasicActionTypes } from 'shared/containers';
import { EUserRole } from 'shared/interfaces';
import { setAddingToFolder, clearAllFilters, toogleMessage } from 'store/selectionContainer';
import { FinishButtonStyle } from './styles';


class MainBoard extends React.Component<any, any> {

  getPersonasList = (): Array<any> => {
    const { personas, activeFolder, addingToFolder, selectedFolderId, activeFilters } = this.props;
    if (!selectedFolderId || !activeFolder.personas) return personas
    if (addingToFolder && activeFolder.personas) {
      let addedPersonas = activeFolder.personas.map(f => f.id);
      if (activeFilters || !activeFolder.personas[0]?.folder_id) {
        return personas.map(p => addedPersonas.includes(p.id) ? { ...p, added: true } : p);
      }
      return activeFolder.personas.map(p => addedPersonas.includes(p.id) ? { ...p, added: true } : p);
    }
    return activeFolder ? activeFolder.personas.map(p => ({ ...p, added: true })) : personas;
  }

  componentDidUpdate(nextProps) {
    const { addingToFolder, setAddingToFolder, selectedFolderId, activeFolder, user, activeFilters } = nextProps;
    if (user.user_role !== EUserRole.VIEW_ONLY && selectedFolderId) {
      if (!addingToFolder && activeFolder.personas && !activeFolder.personas.length) {
        //If there is a selected folder and no active people in the personas, automatically go to adding
        setAddingToFolder({ active: true })
      } else if (activeFilters && !addingToFolder) {
        //If there is an active Folder, and an active filters, it should always allow the option to add to folder from the brand
        setAddingToFolder({ active: true })
      } else if (!this.props.activeFilters && this.props.addingToFolder && this.props.activeFolder.personas && this.props.activeFolder.personas[0]?.folder_id) {
        setAddingToFolder({ active: false })
      }
    }
    if (addingToFolder && !selectedFolderId) {
      //If it is adding to the folder, and there is no folder, then turn it off
      setAddingToFolder({ active: false })
    }
  }

  addPersonaToFolder = (persona) => () => {
    const { addPersonaToFolder, selectedFolderId, removePersonaFromFolder } = this.props;
    if (!selectedFolderId) {
      this.props.toogleMessage({ msg: 'Please choose a Group and Folder before proceeding' })
    } else {
      if (!persona.added) {
        addPersonaToFolder({ folder_id: selectedFolderId, persona_id: persona.id, persona });
      } else {
        removePersonaFromFolder({ folder_id: selectedFolderId, persona_id: persona.id })
      }
    }
  }
  goToPersonaPanel = (id) => () => {
    this.props.history.push(ERoutesName.PERSONA.replace(':idPersona', id))
  }

  stopAddingToFolder = () => {
    this.props.clearAllFilters();
    this.props.setAddingToFolder({ active: false })
  }

  render() {
    const { addingToFolder, uiConfig, user } = this.props;
    const ui = uiConfig(this.props);
    let personasList = this.getPersonasList();

    return (
      <Page
        headerProps={ui.headerProps}
        loaderType='GLOBAL'
      >
        <GridList
          scrollable
          gridItemList={personasList.map(
            (persona) =>
              () =>
                <PersonaCard
                  showIcon={user.user_role !== EUserRole.VIEW_ONLY}
                  onClick={this.goToPersonaPanel(persona.id)}
                  onIconClick={this.addPersonaToFolder(persona)}
                  persona={persona}
                />
          )}
        />
        {addingToFolder &&
          <FinishButtonStyle onClick={this.stopAddingToFolder}>
            <div className="innerButton">
              <Typography light fontSize="16px" justify="center">
                Back to Main Folder
              </Typography>
            </div >
          </FinishButtonStyle>
        }
      </Page>
    )
  }
}

const mstp = (state: any) => ({
  personas: state[EContainerNames.PERSONAS].list,
  activeFolder: state[EContainerNames.GROUPS].detail || {},
  addingToFolder: state[EContainerNames.SELECTORS].addingToFolder,
  selectedFolderId: state[EContainerNames.SELECTORS].folderId,
  activeFilters: Object.keys(state[EContainerNames.SELECTORS].selectedFilters).length
});

const mdtp = (dispatch: any) => ({
  addPersonaToFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.CREATE, actionSuffix: 'FOLDER_PERSONA' }),
  removePersonaFromFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.DELETE, actionSuffix: 'FOLDER_PERSONA' }),
  setAddingToFolder: (payload) => dispatch(setAddingToFolder(payload)),
  clearAllFilters: (payload) => dispatch(clearAllFilters()),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),

})

export default connect(
  mstp,
  mdtp
)(MainBoard);