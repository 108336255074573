import React from 'react';
import { TracfoneLogo } from 'assets';
import styled from 'styled-components';

const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  .text {
    text-align: justify;
    font-size: ${({ width }) => Math.ceil(width/16)}px;
    letter-spacing: ${({ width }) => Math.floor(width/30)}px;
    color: white;
    width: ${({ width }) => width}px;
    font-weight: 200;
    margin-top: 2px;
  }
  .text:after {
    content: "";
    display: inline-block;
    width: 100%;
  }
  svg {
    width: 100%;
  }
  &: hover {
    cursor: pointer;
  }
`

const Logo = ({ width = '',  height = '', onClick = () => {}}) => {
  const logoWidth = width ? Number(width.substring(0, width.length-2)) : 170;
  return (
    <Container onClick={onClick} height={height} width={logoWidth}>
      <TracfoneLogo style={{ width: `${logoWidth}px` }} />
      <div className="text" >Persona library</div>
      {/* <PersonaLogo style={{ width }} /> */}
    </Container>
  )
}

export default Logo;