
import {
  IReducerConfig,
  EBasicActionTypes
}
  from './interfaces';


export const initialStateVariables = {
  [EBasicActionTypes.DETAIL]: {
    name: 'detail',
    initialVar: null
  },
  [EBasicActionTypes.CREATE]: {
    name: 'detail',
    initialVar: null
  },
  [EBasicActionTypes.LIST]: {
    name: 'list',
    initialVar: []
  }
}


export const createReducer = ({
  initialState,
  storeActions
}: IReducerConfig) => {
  return (
    state = initialState,
    action = { type: 'NO_ACTION_FOUND', payload: {} }
  ) => {
    // console.log('STORE ACTIONS AND ACTION', storeActions, action)
    let storeModifier = storeActions[action.type];
    // console.log("STORE MODIFIER", storeModifier)
    return storeModifier ? storeModifier(state, action.payload) : state;
  };
}


