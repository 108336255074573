const initialLoadingState = {

}

const routinesReducer: any = (
  state = initialLoadingState,
  action
) => {
  if (action.type.includes('START')) {
    const loaderType = action.payload;
    return {
      ...state,
      [loaderType]: state[loaderType] ? state[loaderType] + 1 : 1
      }
  } else if (action.type.includes('STOP')) {
    const loaderType = action.payload;
    if (state[loaderType] <= 0) {
      // console.log('STOP LOADING WITH 0 IN QUEUE', action.payload)
    }
    return {
      ...state,
      [loaderType]: state[loaderType] ? state[loaderType] - 1 : 0
      }
  } else {
    return state;
  }
};

export default routinesReducer;