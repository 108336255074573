import styled from "@react-pdf/styled-components";
import { colors, containers } from 'config/uiKit';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const pd = containers.containerPadding;

export const personaPanelStyle = (theme: any) => ({
  container: {
    display: 'flex',
    height: `calc(100% - 74px)`,
  },
});

export const ScrollIconStyled = styled.View<any>`
  position: absolute;
  transform: rotate(90deg);
  right: 0;
  animation: pulse 2s infinite;
  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
  @keyframes pulse {
    0% {
      color: ${props => props.color}33;
    }
    50% {
      color: ${props => props.color}ff;
    }
    100% {
      color: ${props => props.color}33;
    }
  }
`

/****************************
 ***       PROFILE        ***
*****************************/

export const ProfileStyled = styled.View<any>`
  width: 30%;
  padding: ${pd}px 2%;
  overflow: hidden;
`

export const ProfileAdditional = styled.View<any>`
  display: flex;
  height: 72;
  border-radius: 11px;
  background-color: ${props => props.color};
  padding: 11px;
`

export const ProfileShow = styled.View<any>`
  border-radius: 11px;
  height: 100%;
  overflow: hidden;`

export const ProfileHeading = styled.Text<any>`
  font-size: ${({ fontSize = '14' }) => fontSize}px;
  line-height: 1.2;
  margin: ${({ margin = '2px 0 0 0' }) => margin};
`

export const ProfileMediaStyled = styled.Image<any>`
  position: relative;
  text-align: center;
  height: 200px;
  width: 200px;
  overflow: auto;
  cursor: pointer;
`

export const ProfileAditionalIcon = styled.View<any>`
  margin: auto;
  .avatarContainer {
    width: 50px;
    height: 50px;
    background-color: #ffffff22;
    cursor: pointer;
  }
  .avatarContainer:hover {
    background-color: #ffffff44;
  }
  .avatarContainer, .avatarContainer:hover {
    ${props => props.selected ?
    `background-color: ${colors.grayScale.dark};
      cursor: auto;` : ''
  }
  }
  .avatarOutlined {
    width: 40px;
    height: 40px;
    border: 2px solid white;
    background-color: #00000000;
    svg, path {
      fill: white;
      width: 30px;
    }
  }
`

export const TextFieldStyled = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '15px 10px',
      padding: '3px 10px',
      width: 'calc(100% - 20px)',
      height: 40,
      borderRadius: 11,
      display: 'flex',
      position: 'relative',
    },
    input: {
      width: '100%',
      fontSize: 18,
      padding: '4px 0',
    },
    list: {
      cursor: 'pointer',
    },
    icon: {
      width: 32,
      height: 32,
      backgroundColor: colors.grayScale.profile,
      borderRadius: 9,
      position: 'absolute',
      top: 4,
      right: 4,
      padding: 2,
      '& svg': {
        fill: 'white'
      },
      '& .MuiSvgIcon-root': {
        width: 30,
        height: 30,
      }
    }
  })
)

/****************************
 ***        ABOUT         ***
*****************************/

export const AboutStyled = styled.View<any>`
  width: 40%;
  padding: ${pd}px 2% 0 2%;
  max-height: 100%;
  overflow: hidden;
`

export const Header = styled.View`
  display: flex;
  color: ${colors.grayScale.text};
  position: relative;
`

export const AboutRoot = styled.View`
  margin: 10px 0 0 0;
  width: 100%;
  overflow: hidden;
  fontSize: 18;
  margin: -2px 0 28px 0;
  color: ${colors.grayScale.text};
  overflow: hidden;
  padding: 10px 0;
  lineHeight: 1.2;
  '& .MuiOutlinedInput-multiline': {
    padding: 10;
    fontSize: 18;
    marginBottom: 28;
    height: '100%';
    borderRadius: 13;
    border: 1px solid ${colors.grayScale.text};
    color: ${colors.grayScale.text};
  };
  '& *': {
    border: 0;
  }
`

export const AboutIcon = styled.View`
'& .MuiSvgIcon-root': {
  height: 30;
  width: 30;
  margin: -2px 15px -2px 0;
  fill: colors.grayScale.text;
}
`
export const Divider = styled.View`
  margin: 5px 0 0 0;
  width: 100%;
  border: 1px solid ${colors.grayScale.profile};
`
export const AboutText = styled.Text`
  font-size: 12px;
  margin-bottom: 2px;
  line-height: 1.2;
`

/*************************
 ***     PERSONALITY      ***
*****************************/

export const PersonalityApps = styled.View<any>`
  display: flex;
  width: 100%;
  min-height: 35%;
  max-height: 35%;
`

export const AppRow = styled.View`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-direction: row;
`
export const AppImage = styled.Image`
  height: 50px;
  width: 50px;
  margin-right: 10px;
`

export const Personality = styled.View<any>`
  min-height: 50%;
  max-height: 50%;
`
export const SliderContainer = styled.View<any>`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`

export const Labels = styled.View<any>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
`

export const Label1 = styled.Text<any>`
  width: 50%;
  text-align: left;
`

export const Label2 = styled.Text<any>`
  width: 50%;
  text-align: right;
`

export const Slider = styled.View`
  position: relative;
  width: 200px;
  height: 20px;
  margin-top: 8;
  border-radius: ${containers.borderRadius}px;
  background-color: ${colors.grayScale.light};
`

export const Thumb = styled.View`
  position: absolute;
  left: ${({ value }) => value * 2}px;
  top: -2.5px;
  height: 25;
  width: 40;
  background-color: ${props => props.color};
  border-radius: 18;

`

export const PersonalityStyled = styled.View<any>`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  max-height: 100%;

  padding: ${pd}px 2%;
  hr {
    border: 1px solid ${colors.grayScale.profile};
    margin: 10px 0;
  }
  .apps {
    height: calc(100% - ${props => props.height}px);
  }
  .appsList {
    overflow: auto;
    height: calc(100% - 60px);
  }
  .innerList {
    display: flex;
  }
  .personality {
    height: 406px;
  }
  .gridList { 
    width: 100%;
  }
  .buttons {
    display: flex;
    margin-top: 14px;
    height: 46px;
  }
  .buttonsSpace {
    width: 10%;
  }
  .buttonContainer {
    width: 45%;
  }
  .buttonGreen {
    height: 44px;
    border-color: ${colors.greenScale.light};
    background-color: ${colors.greenScale.light};
  }
  .buttonGreen:hover {
    color: ${colors.greenScale.light};
    border-color: ${colors.greenScale.light};
    background-color: white;
  }
  .buttonGray {
    height: 44px;
    color: ${colors.grayScale.medium};
    border-color: ${colors.grayScale.medium};
    background-color: white;
  }
  .buttonGray:hover {
    color: white;
    background-color: ${colors.grayScale.medium};
  }
  .appsHeader {
    display: flex;
    position: relative;
  }
`

export const AppAvatarStyled = styled.View<any>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 60px;
  background-color: ${colors.grayScale.profile};
  cursor: ${props => props.editing ? 'pointer' : 'auto'};
  margin: 10px auto;
  position: relative;
  ${props => props.image ?
    `background-size: cover;
    background-image: url('${props.image}');
    background-position: center;` : ''
  }
  ${props => props.selected ?
    `box-shadow: 0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02);
    border: 4px solid white;` :
    !props.noTransparency && 'opacity: 0.5;'
  }
  .appText {
    width: 70px;
    height: 50px;
    margin: 0 auto;
    padding: calc((${props => props.size}px - 50px)/2) 0;
    font-size: 18px;
    text-align: center;
    position: relative
  }
`

export const AppsSelectorStyled = styled.View<any>`
  position: relative;
  display: flex;
  width: 60vw;
  height: 60vh;
  border-radius: 16px;
  background: white;
  padding: 60px 20px 20px 20px;
  .gridList {
    background-color: ${colors.grayScale.light};
    border: 2px solid ${colors.grayScale.profile};
    border-radius: 12px;
  }
  .title {
    position: absolute;
    left: 22px;
    top: 17px;
    font-size: 20px;
    color: ${colors.grayScale.dark};
  }
  .closeButton {
    position: absolute;
    top: 12px;
    right: 18px;
    cursor: pointer;
    background-color: white;
    border: 1px solid ${colors.grayScale.dark};
    width: 36px;
    height: 36px;
  }
  .MuiSvgIcon-root {
    fill: ${colors.grayScale.dark};
  }
`
