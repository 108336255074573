import React from 'react';
import { connect } from 'react-redux';

import { colors } from 'config/uiKit';
import { Page, Typography, Column, Row, DeleteFolder, DeleteGroup, SmallHeader } from 'components'
import { PersonaFolder } from 'assets';

import { DeleteButton, MButton, Modal } from 'shared/components'
import { EButtonTypes, EButtonIconSide, EUserRole } from 'shared/interfaces';
import { EContainerNames, ERoutesName } from 'interfaces';

import * as Styled from './styles';

class MyDashboard extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: this.props.groups.length ? this.props.groups[0].id : null
    }
  }

  componentDidUpdate() {
    if (!this.state.selectedGroup && this.props.groups.length) {
      this.setState({ selectedGroup: this.props.groups[0].id })
    }
  }


  clickHandler = (groupId: any) => () => {
    this.setState({ selectedGroup: groupId })

  }

  getSelectedFolder = (): Array<any> => {
    let selectedGroup = this.props.groups.find(g => g.id === this.state.selectedGroup) || {}
    return selectedGroup.folders || [];
  }


  getFolderButtons = (folder: any, handleOpen: any, handleClose: any) => {
    const { user, history } = this.props;
    let queryParams = new URLSearchParams();
    queryParams.append('groupId', folder.group_id);
    queryParams.append('folderId', folder.id)
    const clickHandler = () => history.push(`${ERoutesName.HOME}?${queryParams.toString()}`)
    const openModal = handleOpen(<DeleteFolder folderId={folder.id} closeAction={handleClose} />)
    return (
      <Row align='center' justify='flex-end' width="230px">
        <MButton
          side={EButtonIconSide.RIGHT}
          type={EButtonTypes.ICON_WHITE}
          icon={<PersonaFolder />}
          onClick={clickHandler}
        >
          View Board
        </MButton>
        {user.user_role === EUserRole.ADMIN &&
          <DeleteButton
            key={Math.random()}
            onClick={openModal}
            style={{margin: '10px'}}
          />
        }
      </Row>
    )
  }

  render() {
    const { groups, uiConfig, user } = this.props;
    const ui = uiConfig(this.props);
    return (
      <Page headerProps={ui.headerProps}>
        <Modal
          render={({ handleOpen, handleClose }) => (
            <Styled.PersonaBoardsContent>
              <SmallHeader color={colors.blueScale.dark}>
                <div className="header">
                  <div className="title">Persona Boards</div>
                </div>
              </SmallHeader>
              <Styled.Content>
                <Column overflow='hidden' padding='46px 6% 30px 46px' width="45%" align='flex-start' justify='flex-start'>
                  <Typography fontSize="26px" bold dark>Persona Groups</Typography>
                  <Column
                    height='100%'
                    overflow='auto'
                    align='flex-start'
                    borderRadius='27px'
                    justify='flex-start'
                    margin='20px 0 0 0'
                  >
                    {groups.map(g =>
                      <Styled.PersonaButton
                        key={g.id}
                        selected={g.id === this.state.selectedGroup}
                        onClick={this.clickHandler(g.id)}
                        handCursor
                      >
                        <Row justify='flex-start' width='70%'>
                          <Styled.BubbleImage src={g.image_url} />
                          <Typography
                            color={g.id === this.state.selectedGroup ? colors.greenScale.light : ''}
                            fontSize="22px"
                          >
                            {g.name}
                          </Typography>
                        </Row>
                        {user.user_role === EUserRole.ADMIN &&
                          <DeleteButton 
                            style={{margin: '10px'}}
                            onClick={handleOpen(<DeleteGroup groupId={g.id} closeAction={handleClose} />)}
                          />
                        }
                      </Styled.PersonaButton>
                    )}
                  </Column>
                </Column>
                <Column overflow='hidden' padding='46px 30px 30px 0' width="55%" align='flex-start' justify='flex-start'>
                  <Typography fontSize="26px" bold dark>Group Folders</Typography>
                  <Column
                    height='100%'
                    overflow='auto'
                    margin='20px 0 0 0'
                    padding='5%'
                    align='flex-start'
                    borderRadius='27px'
                    justify='flex-start'
                    bgColor={colors.grayScale.light}
                  >
                    {this.state.selectedGroup &&
                      this.getSelectedFolder().map(f =>
                        <Styled.PersonaButton
                          key={f.id}
                          noBorder
                        >
                          <Row width='100%' padding='0 0 0 36px' justify='space-between'>
                            <Typography fontSize="20px" width="100%">
                              {f.name}
                            </Typography>
                            {this.getFolderButtons(f, handleOpen, handleClose)}
                          </Row>
                        </Styled.PersonaButton>
                      )
                    }
                  </Column>
                </Column>
              </Styled.Content>
            </Styled.PersonaBoardsContent>
          )}
        />
      </Page>
    )
  }
}

const mstp = (state: any) => ({
  groups: state[EContainerNames.GROUPS].list || []
});

const mdtp = (dispatch: any) => ({

})

export default connect(
  mstp,
  mdtp
)(MyDashboard);