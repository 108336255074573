import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import { cardStyle } from './styles';

const Card = (props: any) => {
  const { classes, children, height, className = '', relative = false, ...extraProps } = props;
  let style: any = { height };
  if (relative) style.position = 'relative';
  return (
    <Paper 
      style={style}
      variant="outlined" 
      {...extraProps}
      className={`${classes.root} ${className}`}
    >
      { children }
    </Paper>
  );
}

export default withStyles(cardStyle)(Card);