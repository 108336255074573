export interface IFormProps {
  formData: IFormData,
  submit: Function,
  loading: boolean
}

export enum EInputTypes {
  CHECKBOX_GROUP = 'CHECKBOX_GROUP',
  TEXT_FIELD = 'TEXT_FIELD',
  RADIO_GROUP = 'RADIO_GROUP',
  ROW = 'ROW',
  SECURE_TEXT_FIELD ='SECURE_TEXT_FIELD'
}

export interface IFormData {
  title: string,
  buttonText?: string,
  submitButtonText: string,
  submitAction: Function,
  submitFormModifier: Function,
  inputFields: Array<IFormInput>

}

export interface IFormInput {
  id: string,
  type: EInputTypes,
  label: string,
  value?: any,
  required?: boolean,
  style: any,
  inputFields?: Array<IFormInput>
}