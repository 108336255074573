export interface IAppState {
  'LOADER': any
  'AUTH': any
  'router': any
}

export interface IActionTypes {
  type: string,
  payload: any
}

export enum EEpicTypes {
  GET_ROUTINE_DETAIL = "GET_ROUTINE_DETAIL",
  GET_ROUTINES = "GET_ROUTINES",
  CREATE_ROUTINE = "CREATE_ROUTINE",
  GET_PATIENTS = "GET_PATIENTS",
  CREATE_PATIENT = 'CREATE_PATIENT',
  GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL",
  CHECK_AUTH = "CHECK_AUTH",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT"
}

