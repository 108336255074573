import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { IRoute, IAppState, ERoutesName } from 'interfaces';
import { uiConfig } from 'config';

export default (route: IRoute) => {
  const authenticated: boolean = useSelector((state: IAppState) => state['AUTH'].authenticated);
  const user: boolean = useSelector((state: IAppState) => state['AUTH'].userInfo);

  return (
    <Suspense fallback={ route.fallback || <></> }>
      <Route
        path={ route.path }
        render={ props =>
          route.redirect ? <Redirect to={ route.redirect }/> :
            !route.private || authenticated ? 
              route.component && <route.component { ...props } user={user} uiConfig={uiConfig(route.path)} routes={ route.routes } /> :
              <Redirect to={{ pathname: ERoutesName.LOGIN, state: { pathName: route.location?.pathname || route.path } }} />
        }
      />
    </Suspense>
  );
};
