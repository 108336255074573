import { colors } from 'config/uiKit';
import { Theme } from '@material-ui/core';
import { Styles } from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';

export const basicStyle: Styles<Theme, any> = {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    padding: 13,
    width: 274,
    height: 48,
    backgroundColor: colors.greenScale.light,
    border: `2px solid ${colors.greenScale.light}`,
    borderRadius: 34,
    '& .MuiTypography-root': {
      fontSize: 14,
    },
    '&:hover': {
      color: colors.greenScale.light,
      backgroundColor: 'white',
    },
  }
};

export const iconWhiteStyle: Styles<Theme, any> = {
  root: props => ({
    boxShadow: 'none',
    textTransform: 'none',
    color: colors.grayScale.headerButtons,
    justifyContent: 'left',
    padding: '0 10px',
    height: 44,
    backgroundColor: 'white',
    border: `1px solid ${colors.grayScale.medium}`,
    borderRadius: 34,
    minWidth: props.minwidth || 0,
    '& .MuiButton-endIcon, & .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      fontSize: 16,
      margin: '0 10px',
      minWidth: props.minwidth ? props.minwidth - 65 : 0,
    },
    '& .MuiAvatar-root': {
      width: 24, 
      height: 24,
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: 'white',
      border: `1px solid ${colors.grayScale.medium}`,
    },
    '& svg': {
      width: 20,
      height: 20,
      '& path': {
        fill: colors.grayScale.medium,
      }  
    },
    '&:hover': {
      color: colors.greenScale.light,
      backgroundColor: 'white',
      borderColor: colors.greenScale.light,
      '& .MuiAvatar-colorDefault': {
        borderColor: colors.greenScale.light,
      },
      '& svg path': {
        fill: colors.greenScale.light,
      }
    },
    '&.roundButton': {
      marginLeft: '2%',
      minWidth: 44,
      padding: 0,
      justifyContent: 'center',
      '& svg': {
        width: 24,
        height: 24,
      }
    }
  })
};

export const iconGrayStyle: Styles<Theme, any> = {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: 'white',
    padding: 25,
    width: 274,
    height: 55,
    backgroundColor: colors.grayScale.dark,
    border: 'none',
    borderRadius: 34,
    '& .MuiTypography-root': {
      fontSize: 18,
    },
    '& .MuiAvatar-root': {
      width: 36, 
      height: 36,
      marginLeft: -10,
      marginRight: 14,
    },
    '& .MuiAvatar-colorDefault': {
      backgroundColor: 'white',
    },
    '& svg': {
      width: 24,
      height: 24,
      '& path': {
        fill: colors.grayScale.dark,
      }  
    },
    '&:hover': {
      backgroundColor: colors.greenScale.light,
      '& svg path': {
        fill: colors.greenScale.light,
      }
    },
  }
};

export const DelIconStyle = styled.div<any>`
  .avatar {
    width: 44px;
    height: 44px;
    border: 1px solid ${colors.grayScale.dark};
    background-color: inherit;
    cursor: default;
  }
  .icon, .MuiSvgIcon-root {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
    padding: 4px;
    border: 1px solid ${colors.grayScale.dark};
    fill: ${colors.grayScale.dark};
  }
  ${ props => !props.disabled ? 
    `.avatar:hover {
      border: 1px solid ${colors.greenScale.light};
      cursor: pointer;
      .icon, .MuiSvgIcon-root {
        border: 1px solid ${colors.greenScale.light};
        fill: ${colors.greenScale.light};
      }
    }` :
    `.avatar, .icon, .MuiSvgIcon-root { 
      border: 1px solid #00000022; 
      fill: #00000022;
    }`
  }
`