import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GridList, GridListTile, withStyles, withWidth } from '@material-ui/core';
import { ERoutesName, IAppState, RoleString, EContainerNames } from 'interfaces';
import { BrandCardStyled, BrandVoicesContent, gridStyles } from './styles';
import { Page, SmallHeader } from 'components';
import { colors, assetSources  } from 'config/uiKit';

class BrandVoices extends Component<any, any> {

  getCols = (size: string): number => {
    const cols: any = { sm: 2, md: 3, lg: 4, xl: 5 };
    return cols[size] || 1;
  }

  goBrand = brand_id => () => this.props.history.push({
    pathname: ERoutesName.DASHBOARD_BRAND_VOICE.replace(':brand_id', brand_id),
    state: { from: this.props.location.pathname }
  });

  render() {
    const { brands, uiConfig, classes, width, user} = this.props;
    const ui = uiConfig(this.props);
    const cols = this.getCols(width);
    return (
      <Page
        scrollable
        headerProps={ui.headerProps}
        height='100%'
      >
        <BrandVoicesContent>
          <SmallHeader color={colors.blueScale.dark}>
            <div className="header">
              <div className="title">Brand Voices</div>
              <div className="user">
                {RoleString[user.user_role]}
                <div className="avatarContainer">
                  <div className="greenCircle" />
                </div>
              </div>
            </div>
          </SmallHeader>
          <div className="mainContainer">
          <GridList
            cellHeight={'auto'}
            spacing={10}
            className={classes.gridList}
            cols={cols}
          >
            {brands.map((brand: any, i: number) => (
              <GridListTile
                classes={{ root: classes.gridTileRoot, tile: classes.gridTile }} key={i}>
                  <BrandCard {...brand} 
                    image={assetSources.brands[brand.id]} 
                    color={colors.brands[brand.id]} 
                    onClick={this.goBrand(brand.id)}
                   />
              </GridListTile>
            ))}
          </GridList>
          </div>
        </BrandVoicesContent>
      </Page>
    )
  }
}

const BrandCard = ({ name, ...props}) => (
  <BrandCardStyled {...props}>
    <div className="brandName">{name}</div>
  </BrandCardStyled>
)


const cmpWidthProp = withWidth()(BrandVoices);
const styledComponent = withStyles(gridStyles)(cmpWidthProp);

const mstp = (state: IAppState) => ({
  loading: state['LOADER'].global,
  brands: state[EContainerNames.BRANDS].list
})

const mdtp = (dispatch: Function) => ({
})

export default connect(mstp, mdtp)(styledComponent);