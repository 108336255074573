import React, { Component } from 'react';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import PrintOutlined from '@material-ui/icons/PrintOutlined'
import { PDFDownloadLink } from '@react-pdf/renderer'

import { getDispatchAction } from 'store';

import { PersonaStyledView } from 'views/personaPanel';
import { IAppState, EContainerNames } from 'interfaces';
import { BrandMainContainer, BrandVoiceContent } from './styles';
import { EBasicActionTypes } from 'shared/interfaces';
import { Page } from 'components';
import { FolderBrandVoice } from 'components/pdfs';
import Brand from './brand';
import { CircularProgress } from '@material-ui/core';

const getFolderBrands = (folder) => {
  let brands = folder.personas.reduce((acc, p) => {
    acc[p.brand_id] = p;
    return acc;
  }, {})
  return Object.values(brands);
}

class BrandVoice extends Component<any, any> {

  componentDidMount() {
    const folderId = this.props.match.params.folderId;
    this.props.getFolderDetail({ folderId })
  }

  shouldComponentUpdate = (nextProps, nextState) => (
    (!this.props.folder && nextProps.folder) ||
    (!this.props.apps.length && nextProps.apps.length)
  )

  render() {
    const { folder, uiConfig, apps, brands } = this.props;
    const ui = uiConfig(this.props, PDFIcon, { folder, apps });
    
    return (
      <Page
        scrollable
        headerProps={ui.headerProps}
        height='100%'
      >
        <BrandMainContainer>
          {folder && getFolderBrands(folder).map((p: any, i: number) =>
            <Brand
              key={p.id}
              {...p} />
          )}
          {folder && apps.length && folder.personas.map((p: any) =>
            <BrandVoiceContent
              id={`persona_${p.id}`}
              key={p.id}
            >
              <PersonaStyledView
                persona={p}
                brand={p.brand_id && brands.length ? brands.find(v => v.id === p.brand_id) : {}}
                apps={apps}
                hideFunctionButs
              />
            </BrandVoiceContent>
          )}
        </BrandMainContainer>
      </Page >
    )
  }
}

const PDFIcon = ({ folder, apps }) => {
  return folder && apps.length ?
    <PDFDownloadLink
      document={
        <FolderBrandVoice
          personas={folder.personas}
          brands={getFolderBrands(folder)}
          apps={apps}
        />}
      fileName="brand_voice.pdf"
    >
      {({ blob, url, loading, error }) => (loading ? 
        <CircularProgress style={{marginTop: 6, width: null, height: null}} /> :
        <PrintOutlined className='no-circle-icon' style={{marginTop: 6}} />
      )}
    </PDFDownloadLink>
    : <div />
}

const cmpWidthProp = withWidth()(BrandVoice);

const mstp = (state: IAppState) => ({
  loading: state['LOADER'].global,
  folder: state[EContainerNames.GROUPS].detail,
  apps: state[EContainerNames.APPS].list,
  brands: state[EContainerNames.BRANDS].list
})

const mdtp = (dispatch: Function) => ({
  getFolderDetail: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.DETAIL }),
})

export default connect(mstp, mdtp)(cmpWidthProp);