import React from 'react';
import { menuItems } from 'config';
import { CORS_PROXY } from 'utils';
import * as Styled from './styles';

export default ({ color, persona }) => {
  const brand = menuItems.find(v => v.id === persona.brand_id);
  return (
    <Styled.ProfileStyled color={color}>
      <Styled.ProfileShow>
        <ProfileImage
          image={persona.profile_picture_url}
        />
        <>
          <Styled.ProfileHeading fontSize='30' margin='0'>{persona.name}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Age: {persona.age}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Gender: {persona.gender}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Occupation: {persona.occupation}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Income: {Math.floor(persona.income / 1000)}k</Styled.ProfileHeading>
          <Styled.ProfileHeading>Personality Type: {persona.personality_type}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Brand: {brand.label}</Styled.ProfileHeading>
          <Styled.ProfileHeading>Contract Length: {persona.contract_length}y</Styled.ProfileHeading>
        </>
      </Styled.ProfileShow>
    </Styled.ProfileStyled>
  )
}

const ProfileImage = ({ image }) => {
  return (
    <Styled.ProfileMediaStyled
      source={{
        uri: CORS_PROXY + image, 
        method: 'GET', 
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/octet-stream'
        }
      }}

    >
    </Styled.ProfileMediaStyled>
  )
}
