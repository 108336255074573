import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { Page, SmallHeader } from 'components';
import { IAppState, EContainerNames, ELoaderTypes } from 'interfaces';
import { EBasicActionTypes } from 'shared/interfaces';
import { menuItems } from 'config';
import { Modal, Loader } from 'shared/components';
import { personaPanelStyle } from './styles';
import { WideContent, CommonMsg, colors } from 'config/uiKit';
import { isDataValid } from 'utils';
import { getDispatchAction } from 'store';

import Profile from './profile';
import About from './about';
import { Persona } from 'interfaces';
import Personality from './personality';
import { toogleMessage, changeColor } from 'store/selectionContainer';

class PersonaPanel extends Component<any, any> {

  state = {
    editing: false,
    persona: new Persona()
  };

  componentDidMount() {
    const idPersona = this.props.match.params.idPersona;
    if (idPersona === 'new') {
      this.setState({
        editing: true,
      });
      this.changeInfo('brand_id')(null, menuItems[0].id)
    } else if (this.props.persona) {
      if (this.props.persona.id !== parseInt(idPersona)) {
        this.props.getPersona({ id: idPersona });
        //change to new persona real        
      } else
        this.setState({ persona: this.props.persona })
      this.props.changeColor({ brandId: this.props.persona.brand_id });
    } else {
      this.props.getPersona({ id: idPersona })
    }
  }

  componentDidUpdate(prevProps) {
    const idPersona = this.props.match.params.idPersona;
    if (idPersona !== 'new') {
      if (!prevProps.persona && this.props.persona) {
        this.setState({ persona: { ...this.state.persona, ...this.props.persona } });
        this.props.changeColor({ brandId: this.props.persona.brand_id });
      } else if (prevProps.persona && prevProps.persona.id !== this.props.persona.id) {
        this.setState({ persona: { ...this.state.persona, ...this.props.persona } });
        this.props.changeColor({ brandId: this.props.persona.brand_id });
      }
    }
    if (prevProps.match.params.idPersona !== idPersona) {
      this.props.getPersona({ id: idPersona });
    }
  }

  changeInfo = id => (e: any, v = undefined) => {
    const newLines = ['about', 'needs', 'frustrations'];
    const numFields = ['age', 'income', 'contract_length']
    let data = e?.target?.value || v;
    if ((newLines.indexOf(id) + 1) && data) data = data.split('\n\n');
    if ((numFields.indexOf(id) + 1) && data) data = data.replace(/[^0-9.]+/g, '');
    const persona = { ...this.state.persona, [id]: data };
    this.setState({ persona });
    if (id === 'brand_id') this.props.changeColor({ brandId: data })
  }

  isPersonaInFolder = () => {
    const { activeFolder, selectedFolderId } = this.props;
    if (this.state.persona.id && activeFolder.personas && selectedFolderId) {
      const isPersonaInFolder = activeFolder.personas.find(fp => fp.id === this.state.persona.id);
      return !!isPersonaInFolder
    } else {
      return false;
    }
  }

  goToNextPersona = (inc) => () => {
    const { personas, persona, activeFolder } = this.props;
    let personasList = this.isPersonaInFolder() ? activeFolder.personas : personas;
    const newIdx = personasList.map(p => p.id).indexOf(persona.id) + inc;
    const newPersona = personasList[newIdx >= 0 ? newIdx % personasList.length : personasList.length - 1]
    this.props.history.push(`${newPersona.id}`)
  }

  addPersonaToFolder = () => {
    const { addPersonaToFolder, selectedFolderId, removePersonaFromFolder, persona } = this.props;
    let isPersonaAdded = this.isPersonaInFolder();
    if (!isPersonaAdded) {
      addPersonaToFolder({ folder_id: selectedFolderId, persona_id: persona.id, persona });
    } else {
      removePersonaFromFolder({ folder_id: selectedFolderId, persona_id: persona.id })
    }
  }

  submitPersona = async () => {
    const persona: any = { ...this.state.persona };
    const optionalFields = ['id', 'created_at', 'updated_at', 'occupation', 'audio_sample_url', 'about', 'needs', 'frustrations', 'most_used_apps'];
    const isValid = isDataValid(persona, CommonMsg.fillFields, null, optionalFields);
    if (!isValid.value) return this.props.toogleMessage(isValid);
    if (persona.id) {
      if (persona.profile_picture_url !== this.props.persona.profile_picture_url) {
        persona.profile_picture = await fetch(persona.profile_picture_url).then(r => r.blob());
      }
      this.props.updatePersona(persona);
    } else {
      persona.profile_picture = await fetch(this.state.persona.profile_picture_url).then(r => r.blob());
      delete persona['id'];
      delete persona['created_at'];
      delete persona['updated_at'];
      this.props.createPersona(persona);
    }
    this.setState({ editing: false });
  }

  resetPersona = () => this.setState({ persona: new Persona() });

  setEdition = value => () => this.setState({ editing: value });

  addPersona2Folder = () => {
    let msg;
    if (!this.props.selectedFolderId)
      msg = 'Please choose a Group and Folder before proceeding'
    else if (this.isPersonaInFolder())
      msg = 'Persona removed from Folder'
    else
      msg = 'Persona added into Folder';

    this.props.toogleMessage({ msg });
    if (this.props.selectedFolderId) this.addPersonaToFolder();
  }

  render() {
    const { loading, uiConfig, hideBackBut = false, hideEditBut = false, hideFunctionButs = false, user, brands, history } = this.props;
    const { editing, persona }: any = this.state;

    const brand = persona.brand_id && brands.length ? brands.find(v => v.id === persona.brand_id) : {}

    const ui = uiConfig({ ...this.props, editing });
    return (
      <Page headerProps={ui.headerProps} scrollable height='88px'>
        {!!loading && <Loader contained />}
        <Modal
          render={({ handleClose, handleOpen }) => (
            <WideContent>
              <PersonaStyledView
                brand={brand}
                isPersonaInFolder={this.isPersonaInFolder()}
                editing={editing}
                apps={this.props.apps}
                history={history}
                persona={persona}
                setEdition={this.setEdition}
                changeInfo={this.changeInfo}
                addPersona2Folder={this.addPersona2Folder}
                submitPersona={this.submitPersona}
                handleOpen={handleOpen}
                handleClose={handleClose}
                resetPersona={this.resetPersona}
                hideBackBut={hideBackBut}
                hideEditBut={hideEditBut}
                hideFunctionButs={hideFunctionButs}
                goToNextPerson={this.goToNextPersona}
                user={user}
              />
            </WideContent>
          )}
        />
      </Page>
    )
  }
}

const duckFunction = (args: any): any => { }

const PersonaView = ({
  editing = false,
  brand,
  isPersonaInFolder,
  apps,
  persona,
  user = { user_role: null },
  classes,
  setEdition = duckFunction,
  changeInfo = duckFunction,
  addPersona2Folder = duckFunction,
  submitPersona = duckFunction,
  handleOpen = duckFunction,
  handleClose = duckFunction,
  resetPersona = duckFunction,
  goToNextPerson = duckFunction,
  hideBackBut = false,
  hideEditBut = false,
  hideFunctionButs = false,
  history
}: any) => {
  return (
    <>
      <SmallHeader
        color={colors.brands[persona.brand_id]}
        back={!hideFunctionButs && !hideBackBut && (!editing || persona.id)}
        edit={!hideFunctionButs && !hideEditBut && !editing}
        user_role={user.user_role}
        editAction={setEdition(true)}
        backText={(editing && "Back") || undefined}
        backAction={editing ? setEdition(false) : () => history.push('/')}
        nav={!hideFunctionButs && !editing && goToNextPerson}
      >
        <strong>{brand.name} |</strong> {persona.name || 'Persona'} {persona.customer_type?.toLowerCase()!=='standard'&&`| ${persona.customer_type}`}
      </SmallHeader>
      <div className={classes.container} style={{ backgroundColor: 'white' }}>
        <Profile
          changeInfo={changeInfo}
          editing={editing}
          color={colors.brands[persona.brand_id]}
          persona={persona}
          addPersona={addPersona2Folder}
          isPersonaInFolder={isPersonaInFolder}
          brand={brand}
          hideFunctionButs={hideFunctionButs}
        />
        <About
          changeInfo={changeInfo}
          editing={editing}
          color={colors.brands[persona.brand_id]}
          persona={persona}
        />
        <Personality
          changeInfo={changeInfo}
          editing={editing}
          savePersona={submitPersona}
          color={colors.brands[persona.brand_id]}
          persona={persona}
          most_used_apps={persona?.most_used_apps || []}
          modal={{ handleClose, handleOpen }}
          apps={apps}
          resetPersona={resetPersona}
        />
      </div>
    </>
  )
}

export const PersonaStyledView = withStyles(personaPanelStyle)(PersonaView);

const mstp = (state: IAppState) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_PERSONA] || state[EContainerNames.LOADER][ELoaderTypes.DETAIL_PERSONA],
  persona: state[EContainerNames.PERSONAS].detail,
  personas: state[EContainerNames.PERSONAS].list,
  activeFolder: state[EContainerNames.GROUPS].detail || {},
  selectedFolderId: state[EContainerNames.SELECTORS].folderId,
  apps: state[EContainerNames.APPS].list,
  brands: state[EContainerNames.BRANDS].list,
})

const mdtp = (dispatch: Function) => ({
  getPersona: getDispatchAction({ dispatch, container: EContainerNames.PERSONAS, actionType: EBasicActionTypes.DETAIL }),
  addPersonaToFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.CREATE, actionSuffix: 'FOLDER_PERSONA' }),
  removePersonaFromFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.DELETE, actionSuffix: 'FOLDER_PERSONA' }),
  createPersona: getDispatchAction({ dispatch, container: EContainerNames.PERSONAS, actionType: EBasicActionTypes.CREATE }),
  updatePersona: getDispatchAction({ dispatch, container: EContainerNames.PERSONAS, actionType: EBasicActionTypes.UPDATE }),
  toogleMessage: payload => dispatch(toogleMessage(payload)),
  changeColor: payload => dispatch(changeColor(payload)),
})

export default connect(mstp, mdtp)(PersonaPanel);

