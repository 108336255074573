import React from 'react';
import { Document, Font } from '@react-pdf/renderer';
import font from './poppins/Poppins-Medium.ttf'

import Brand from './brand';
import Persona from './persona';

Font.register({ family: 'Poppins', src: font, fontStyle: 'normal', fontWeight: 'normal' });



const FolderBrandVoice = ({ brands, personas, apps }) => {
  return (
    // <PDFViewer style={{ height: '1000px', position: 'absolute', width: '100vw'}}>
      <Document>
        {personas.map(p => <Persona persona={p} apps={apps} />)}
        {brands.map(b => <Brand key={b.id} brand={b} />)}
      </Document>
    // </PDFViewer>
  )

}
export default FolderBrandVoice