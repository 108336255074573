import React from "react";
import { IMButton, EButtonTypes, EButtonIconSide } from "./interfaces";
import { withStyles, Button, Avatar, Typography } from "@material-ui/core";
import { basicStyle, iconWhiteStyle, iconGrayStyle, DelIconStyle } from './styles';
import { Add as AddIcon, DeleteOutlineOutlined as DelIcon } from "@material-ui/icons";

export const Basic = withStyles(basicStyle)(Button);
export const IconWhite = withStyles(iconWhiteStyle)(Button);
export const IconGray = withStyles(iconGrayStyle)(Button);

export default (props: IMButton) => {
  const { side, width, type, children, icon, disabled, noTypography = false, buttonType = '', ...extraProps} = props;
  const ButtonType = type ? { Basic, IconWhite, IconGray }[type] : Basic;
  const content = icon ? <Avatar>{icon}</Avatar> : type === EButtonTypes.ICON_GRAY ? <Avatar><AddIcon /></Avatar> : null;
  const leftSide = type === EButtonTypes.ICON_GRAY || side === EButtonIconSide.LEFT;
  return (
    <ButtonType
      startIcon={leftSide && content}
      endIcon={!leftSide && content}
      {...extraProps}
      style={{ width, ...extraProps.style }}
      disabled={disabled}
      type={buttonType}
    >
      {noTypography ?
        children
        : <Typography style={{ textAlign: 'initial' }} noWrap>
          {children}
        </Typography>
      }
    </ButtonType>
  );
}

export const DeleteButton = ({icon = null, ...props}) => (
  <DelIconStyle {...props}>
    <Avatar className="avatar">
      {icon || <DelIcon />}
    </Avatar>
  </DelIconStyle>
)