export const findAndRemoveById = ({ list, id }: any) => {
  return list.filter(obj => obj.id !== id);
}

export const findAndUpdateById = (list: Array<any>, payload: any) => {
  return list.map(item => {
    if (payload.id === item.id) {
      return payload
    } else {
      return item;
    }
  })
}
export const addFolderToGroup = ({ groups, folder, id }: any) => {
  return groups.map(g => {
    if (g.id === id) {
      return {
        ...g,
        folders: g.folders.concat(folder)
      }
    } else {
      return g
    }
  }
  )
}



export const formMapper = (body) => {
  let form = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(val => {
        form.append(`${key}[]`, val as string)
      })
    } else {
      form.append(key, value as string)
    }

  });
  return form;
}

