import styled from 'styled-components';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { colors, containers } from 'config/uiKit';

export const accountSettingsStyle = (theme: any) => ({
  container: {
    height: `calc(100% - 74px)`,
    marginTop: 15,
    overflow: 'auto',
  },
});

export const PageContent = styled.div<any>` 
  display: flex;
  flex-direction: column;
  border-radius: ${containers.borderRadius}px;
  background-color: white;
  overflow-x: hidden;
  height: 100%;
  margin-top: 10px;
  .mainContainer {
    padding: 29px 33px;
    height: 100%;
    overflow: auto;
  }
  .header {
    display: flex;
    font-size: 26px;
    font-weight: 400;
    margin: 12px 30px 16px 30px;
  }
`


export const UserStyled = styled.div<any>`
  width: 100%;
  height: 100px;
  display: flex;
  background-color: #F7F7F7;
  border-radius: ${containers.borderRadius}px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #939CAA;
  .item {
    display: flex;
  }
  .name {
    margin: auto auto auto 20px;
  }
  .clickableField {
    &:hover {
      cursor:pointer;
    }
  }
  .image {
    width: 90px;
    height: 90px;
    border-radius: ${containers.borderRadius - 4}px;
    margin: 5px;
    background-size: cover;
    background-image: url('${props => props.image}');
    background-position: center;
  }
  .vertical { 
    border-left: 2px solid  #E3E3E3; 
    height: 100px;
  }
  .wide {
    margin: auto;
  }
  .MuiTypography-root {
    font-size: inherit;
  }
  .MuiButtonBase-root {
    color: ${colors.greenScale.light};
  }
  .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
  }
`

export const RadioCustomStyle = styled.div<any>`
  width: 30px;
  height: 30px;
  border: 1px solid ${colors.grayScale.profile};
  border-radius: 15px;
  margin-left: 10px;
  .innerCircle {
    width: 18px;
    height: 18px;
    margin: 5px;
    background-color: ${props => props.selected ? colors.greenScale.light : colors.grayScale.profile};
    border-radius: 10px;
  }
`

export const TrashIcon = styled(DeleteOutlineOutlinedIcon)<any>`
    width: 34px;
    height: 34px; 
    background-color: ${({ disabled }) => disabled && colors.grayScale.dark};
    border-radius: 50%;
    padding: 2px;
    border: 1px solid ${colors.grayScale.medium};
    margin: 0 35px;
    &:hover {
      cursor: ${({ disabled }) => !disabled && 'pointer'};
      stroke: ${colors.greenScale.buttonMenuText};
    }
`