import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import * as serviceWorker from './serviceWorker';
import { configureStore } from './store';
import App from 'views/app';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme, axiosConfig } from './config';
import './index.css';

axiosConfig();

let { store, history } = configureStore()
ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
);

serviceWorker.register();
