import React, { useState } from "react"
import { connect } from 'react-redux'
import { Avatar } from "@material-ui/core"
import { Close } from "@material-ui/icons"

import { MTextField, MButton } from "shared/components"
import { EButtonTypes } from "shared/interfaces"
import { EContainerNames, ELoaderTypes } from 'interfaces';
import { EBasicActionTypes } from 'shared/containers'

import { getDispatchAction } from 'store';

import { NewFolderStyle, ErrorStyled } from "./styles"
import { isDataValid } from "utils"
import { CommonMsg } from "config/uiKit"
import { toogleMessage } from "store/selectionContainer"

const NewFolder = (props) => {
  const { groupId, closeAction, createFolder } = props;
  const [formContent] = useState({ group_id: groupId });
  const [errorMessage, setErrorMessage] = useState('');
  
  const updateData = id => {
    formContent[id] = undefined;
    return e => formContent[id] = e.target.value;
  }

  const submitHandler = () => {
    const isValid = isDataValid(formContent, CommonMsg.fillFields, <NewFolder {...props} />);
    if (!isValid.value) return setErrorMessage(isValid.msg);
    createFolder(formContent);
    closeAction();
  }

  return (
    <NewFolderStyle>
      <Avatar className="close-avatar" onClick={closeAction}>
        <Close />
      </Avatar>
      <div className="form-content">
        <h2>Create Folder Name</h2>
        <MTextField
          required
          fullWidth
          id="name"
          label="Enter name here"
          onChange={updateData('name')}
        />
        <div className="buttons">
          <MButton
            type={EButtonTypes.BASIC}
            onClick={closeAction}
            className="cancel-button"
            width="200px"
          >
            Cancel
          </MButton>
          <MButton
            type={EButtonTypes.BASIC}
            onClick={submitHandler}
            className="save-button"
            width="200px"
          >
            Save
          </MButton>
        </div>
        {errorMessage && <ErrorStyled>{errorMessage}</ErrorStyled>} 
      </div>
    </NewFolderStyle>
  )
}

const mstp = (state) => ({
  loading: state[EContainerNames.LOADER][ELoaderTypes.CREATE_GROUP]
})

const mdtp = (dispatch) => ({
  createFolder: getDispatchAction({ dispatch, container: EContainerNames.GROUPS, actionType: EBasicActionTypes.CREATE, actionSuffix: 'FOLDER' }),
  toogleMessage: (payload) => dispatch(toogleMessage(payload)),
})

export default connect(mstp, mdtp)(NewFolder);