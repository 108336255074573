


const urls = {
  'development': process.env.REACT_APP_DEV_API,
  'staging': process.env.REACT_APP_STAGING_API,
  'production': process.env.REACT_APP_PROD_API
}

export const url = urls[process.env.REACT_APP_NODE_ENV] || process.env.REACT_APP_DEV_API
