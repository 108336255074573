import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CssBaseline, withStyles, withWidth } from '@material-ui/core';
import { matchPath } from 'react-router-dom';
import { Router } from 'router';


import { IAppState, EContainerNames, ERoutesName, ELoaderTypes } from 'interfaces';
import { SideMenu } from 'components';
import { uiConfig } from 'config';

import { Loader } from 'shared/components';
import { logout } from 'shared/auth/actions';
import { getDispatchAction } from 'store';
import { setAddingToFolder, setActiveFilters, clearAllFilters, clearGroupsAndFolder } from 'store/selectionContainer';
import { EBasicActionTypes } from 'shared/containers'

import { menuStyle, Content } from './styles';
import { colors } from 'config/uiKit';


class Navigation extends Component<any> {

  state = {
    mobileOpen: false,
    color: null
  }

  componentDidUpdate() {
    const { brands, brandId } = this.props;
    const color = brands.find(v => v.id === brandId)?.color;
    if (!brandId && !brands) this.setState({ color: null });
    else if (color !== this.state.color) this.setState({ color });
  }

  render() {
    const {
      classes, routes, history, setAddingToFolder,
      selectedFolder, setActiveFilters, loading,
      clearAllFilters, clearFolders, selectedFilters, logout, user
    } = this.props;

    const UI = uiConfig('navigation');

    const currentPage = history.location.pathname;
    const noFullMenu = [ERoutesName.PERSONA];
    const fullMenu = noFullMenu.reduce((pv, path) => pv && !matchPath(currentPage, { path }), true);
    const isDash = !!(currentPage.indexOf(ERoutesName.DASHBOARD_PREFIX) + 1);
    const color = (!fullMenu && this.state.color) || (isDash && colors.blueScale.dark);

    return (
      <Content flexDirection={UI.flexDirection}>
        <CssBaseline />
        <main className={classes.content}>
          {!!loading && <Loader contained />}
          <SideMenu
            clearFolders={clearFolders}
            selectedFilters={selectedFilters}
            clearAllFilters={clearAllFilters}
            setActiveFilters={setActiveFilters}
            history={history}
            fullMenu={fullMenu}
            color={color}
            selectedFolder={selectedFolder}
            setAddingToFolder={setAddingToFolder}
            filters={this.props.filters}
            isDash={isDash}
            logout={logout}
            user={user}
          />
          <Router routes={routes} />
        </main>
      </Content>
    );
  }
}


const cmpWidthProp = withWidth()(Navigation);
const styledComponent = withStyles(menuStyle)(cmpWidthProp);

const mstp = (state: IAppState) => ({
  user: state[EContainerNames.AUTH].userInfo,
  filters: state[EContainerNames.FILTERS].list.map(filter => ({ ...filter, options: JSON.parse(filter.options) })),
  selectedFilters: state[EContainerNames.SELECTORS].selectedFilters,
  selectedFolder: state[EContainerNames.SELECTORS].folderId,
  brandId: state[EContainerNames.SELECTORS].brandId,
  loading: state[EContainerNames.LOADER][ELoaderTypes.GLOBAL],
  brands: state[EContainerNames.BRANDS].list
})

const mdtp = (dispatch) => ({
  fetchPersonas: getDispatchAction({ dispatch, container: EContainerNames.PERSONAS, actionType: EBasicActionTypes.LIST }),
  setAddingToFolder: (payload) => dispatch(setAddingToFolder(payload)),
  setActiveFilters: (payload) => dispatch(setActiveFilters(payload)),
  clearAllFilters: () => dispatch(clearAllFilters()),
  clearFolders: () => dispatch(clearGroupsAndFolder()),
  logout: () => dispatch(logout())
})

export default connect(mstp, mdtp)(styledComponent);
