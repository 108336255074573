import React from "react";
import { withStyles, TextField, InputAdornment } from "@material-ui/core";
import { textFieldStyle } from './styles';

const MTextField = (props: any) => {
  const { classes, icon, ...extraProps } = props;
  let InputProps: any= {
    classes: { 
      root: classes.root,
      notchedOutline: classes.notchedOutline,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error,
      input: classes.input
    }
  }
  if (icon) {
    InputProps.endAdornment = (
      <InputAdornment position="end">
        { icon }
      </InputAdornment>
    )
  }
  return (
    <TextField
      variant="outlined"
      InputProps={InputProps}
      InputLabelProps={{
        classes: {
          root: classes.label
        },
      }}
      {...extraProps}
    />
  );
}

export default withStyles(textFieldStyle)(MTextField);
