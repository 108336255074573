
import { EErrorActionTypes } from './interfaces'


export const showError = (payload) => ({
  type: EErrorActionTypes.SHOW_ERROR,
  payload
})

export const hideError = () => ({
  type: EErrorActionTypes.HIDE_ERROR,
})