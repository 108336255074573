import { Styles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import { colors } from "config/uiKit";

export const textFieldStyle: Styles<Theme, any> = {
  root: {
    margin: '10px 0',
    fontSize: 14,
    padding: '0 18px 0 16px',
    color: colors.grayScale.dark,
    '& .MuiOutlinedInput-input': {
      padding: '17px 6px',
    },
    '& svg': {
      width: 16,
      height: 16,
      '& path': {
        fill: colors.blueScale.medium,
      }  
    },
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
      borderColor: colors.blueScale.medium,
    }
  },
  notchedOutline: {
    borderRadius: 34,
  },
  label: {
    margin: '8px 0',
    fontSize: 14,
    padding: '0 10px 0 10px',
    color: colors.grayScale.dark,
    transform: 'translate(14px, 19px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(8px, -4px) scale(0.75)',
    },
  },
  input: {
    "& input:-webkit-autofill, & input:-internal-autofill-selected": {
      //WebkitBoxShadow: `0 0 0 1000px #ffffff inset`,
      //"-webkit-text-fill-color": "inherit",
    }
  },
  disabled: {},
  focused: {},
  error: {},
};
