import React from 'react';
import $ from 'jquery';
import { MDialog } from './styles'

interface IModal {
  render: Function;
}

class Modal extends React.Component<IModal>{
  state = {
    modalContent: false,
    cbFunc: null,
  }

  handleClose =  () => {
    this.setState({ modalContent: false });
    if (this.state.cbFunc) this.state.cbFunc();
  }

  handleOpen = (modalContent: any, cbFunc: any = null) =>  () => this.setState({ modalContent, cbFunc });

  render() {
    const open = !!this.state.modalContent;
    $('#root').css('filter', open ? 'blur(4px)': 'none');
    return (    
      <>     
        {this.props.render(({ handleClose: this.handleClose, handleOpen: this.handleOpen }))}
        <MDialog
          open={open}
          onClose={this.handleClose}
        >
          {this.state.modalContent}
        </MDialog>
      </>
    )
  }
} 

export default Modal;