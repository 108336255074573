import { applyMiddleware, combineReducers, createStore } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware} from 'connected-react-router'

import { combineEpics } from 'redux-observable';
import { createEpicMiddleware } from 'redux-observable';

import { api, containerConfig} from 'config';

import { userReducer, createAuthEpicConfig } from 'shared/auth';

import { loaderReducer, createLoadingEpics } from 'shared/loader';
import { errorReducer } from 'shared/error';
import { EContainerNames } from 'interfaces';
import { createMultipleEpics, ApplicationGenerator, IDispatchActionGenerator } from 'shared/containers';
import { dispatchActionCreator } from 'shared/containers/actions';
import { SelectionReducer } from './selectionContainer';


let { reducers, epicConfigs, exportedActions } = ApplicationGenerator({ containerConfig, api })


const authEpicConfig = Object.values(createAuthEpicConfig({ api, loader: 'GLOBAL' }));
epicConfigs = epicConfigs.concat(authEpicConfig)


// console.log('reducers', reducers)
// console.log('epics', epicConfigs)
// console.log('exportedActions', exportedActions)

export const getDispatchAction = ({ dispatch, container, actionType, actionSuffix = '' }: IDispatchActionGenerator) => {
  const actionName = actionType + (actionSuffix ? `_${actionSuffix}` : '')
  let action = exportedActions[container][actionName];
  return dispatchActionCreator(dispatch, action.name, action.loader)
}

const rootReducer = (history) => combineReducers<any>({
  [EContainerNames.AUTH]: userReducer,
  [EContainerNames.LOADER]: loaderReducer,
  [EContainerNames.ROUTER]: connectRouter(history),
  [EContainerNames.ERROR]: errorReducer,
  [EContainerNames.SELECTORS]: SelectionReducer,
  ...reducers,
});

const rootEpic = combineEpics(
  ...createLoadingEpics(epicConfigs),
  ...createMultipleEpics(epicConfigs),

)

const epicMiddleware = createEpicMiddleware();


export const configureStore = () => {
  const history = createBrowserHistory();
  const store = createStore(
    rootReducer(history), 
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history) as any,
        epicMiddleware
      )
    )
  )


  epicMiddleware.run(rootEpic);
  return { store, history };

};